import { FC, useEffect } from 'react';
import MainLayout from "../../common/layouts/main.layout";
import { ResultsCard } from './widgets';
import { useAppDispatch } from '../../store/hooks';
import { setSchools } from '../../store/slices/schools';
import useSchools from '../../common/hooks/use-schools';

interface ExportScreenProps {
};

const ResultScreen: FC<ExportScreenProps> = () => {
  const dispatch = useAppDispatch();
  const [loadSchools, loadingSchool, schools] = useSchools();

  useEffect(() => {
    loadSchools();
  }, [loadSchools]);

  useEffect(() => {
    if (schools && !loadingSchool) {
      dispatch(setSchools(schools));
    }
  }, [loadingSchool, schools, dispatch]);

  
  return (
    <MainLayout title='Resultados'>
      <ResultsCard/>
    </MainLayout>
  );
}

export default ResultScreen;
export {
  ResultScreen,
};