import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { confirmPassword } from "../../../services/auth.service";
import { Stack, TextField, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import { DialogModal } from "../../../common/components";

type ConfirmPassFormProps = {};

const ConfirmPassForm: FC<ConfirmPassFormProps> = () => {
  const [password, setPassword] = useState("");
  const [textModal, setTextModal] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [hasParams, setParams] = useState(false);
  const location: any = useLocation();
  const history = useHistory();
  const search = location.search;
  const data:any = {};
  
  if (search.length > 0) {
    const params = search.split(/[?&]/);
    params.forEach((p:any) => {
      if (!p.length) return;
      const [key, value] = p.split('=');
      data[key] = decodeURI(value);
    })
  }

  type formValues = {
    code:string;
    email:string;
    password: string;
  }

  const form = useForm<formValues> ({
    defaultValues: {
      code: '',
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    if (data.mail) {
      form.setValue("email", data.mail);
      setParams(true);
    }
  }, [data.mail]);

  const { register, handleSubmit, formState: { errors } } = form;

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    setPassword(newValue);
  }

  const onAgree = async () => {
    if (
      textModal ===
      "¡Listo! Has cambiado tu contraseña correctamente. Por favor, inicia sesión con tu correo y nueva contraseña."
    ) {
      history.push("/auth/login");
    } else {
      setOpenDialog(false);
    }
  };

  async function resetPass(data: formValues) {
    if (data && password !== "") {
      try {
        const result = await confirmPassword(data.email.trim(), data.code.trim(), password);
        if (result) {
          setTextModal(
            "¡Listo! Has cambiado tu contraseña correctamente. Por favor, inicia sesión con tu correo y nueva contraseña."
          );
          setOpenDialog(true);
        }
      } catch (err:any) {
        console.log("err", err);
        if (err?.message) {
          if (err?.message.includes("Invalid code") || err?.message.includes("Invalid verification code") ) {
            const error: any = "Tu código es invalido, por favor verificalo";
            setTextModal(error);
          } else {
            setTextModal(err?.message);
          }
        } else {
          setTextModal("Tu contraseña no pudo ser cambiada, verifica tus datos");
        }
        setOpenDialog(true);
      }
    }
  }

  return (
    <Stack spacing={2}>
      <DialogModal
        text={textModal}
        onAgree={onAgree}
        open={openDialog}
        setOpen={setOpenDialog}
        hideCloseButton={true}
      />
    <form onSubmit={handleSubmit(resetPass)}>
      {hasParams 
        ? <Typography sx={{marginBottom: '12px', fontSize:'14px'}}>Se realizará el cambio de contraseña para el correo {form.getValues('email')}</Typography>
        : <TextField
          label="Correo"
          {...register("email", {required: "El correo no puede estar vacio"})}
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{width: '100%', marginBottom: '12px'}}
        />
      }
      <TextField
        label="Codigo"
        {...register("code", { required: "El codigo no puede estar vacio" })}
        error={!!errors.code}
        helperText={errors.code?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
          label="Nueva contraseña"
          type="text"
          {...register("password", {
            required: "La contraseña no puede estar vacia",
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
          value={password}
          onChange={onValueChange}
          sx={{ width: "100%", marginBottom: "12px" }}
        />
        <PasswordChecklist
            className="password-check-list"
            style={{ margin: "12px 6px" }}
            iconSize={11}
            rules={[
              "capital",
              "specialChar",
              "minLength",
              "lowercase",
              "number",
            ]}
            minLength={8}
            value={password}
            messages={{
              minLength: "8 caracteres.",
              specialChar: "Al menos un caracter especial.",
              number: "Al menos un número",
              capital: "Al menos una letra mayúscula.",
              lowercase: "Al menos una letra minúscula.",
            }}
          />
      <Stack>
        <Button type="submit" variant="contained" color="primary">
          Actualizar contraseña
        </Button>
      </Stack>
    </form>
  </Stack>
  );
};

export default ConfirmPassForm;
export { ConfirmPassForm };
