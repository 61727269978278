import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { resetPassword } from "../../../services/auth.service";
import { Stack, TextField, Button, Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogModal } from "../../../common/components";

type RecoverPassFormProps = {};

const RecoverPassForm: FC<RecoverPassFormProps> = () => {
  const [textModal, setTextModal] = useState("");
  const [email, setEmail] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  type formValues = {
    email: string;
  };

  const form = useForm<formValues>({
    defaultValues: {
      email: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  async function resetPass(data: formValues) {
    if (data) {
      try {
        const result = await resetPassword(data.email);
        if (result) {
          setEmail(data.email);
          setTextModal(
            `¡Listo! Se ha enviado un correo al ${data.email}, con las instrucciones para recuperar tu contraseña`
          );
          setOpenDialog(true);
          form.reset();
        }
      } catch (err:any) {
        console.log("err", err);
        setTextModal("No se pudo enviar el correo de recuperación");
        setOpenDialog(true);
      }
    }
  }

  const onAgree = async () => {
    if (
      textModal ===
      `¡Listo! Se ha enviado un correo al ${email}, con las instrucciones para recuperar tu contraseña`
    ) {
      history.push(`/auth/confirm-password?mail=${email}`);
    } else {
      setOpenDialog(false);
    }
  };

  return (
    <Stack spacing={2}>
      <DialogModal
        text={textModal}
        onAgree={onAgree}
        open={openDialog}
        setOpen={setOpenDialog}
        hideCloseButton={true}
      />
      <form onSubmit={handleSubmit(resetPass)}>
        <TextField
          label="Correo"
          {...register("email", { required: "El correo no puede estar vacio" })}
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{ width: "100%", marginBottom: "12px" }}
        />
        <Stack>
          <Button type="submit" variant="contained" color="primary">
            Recuperar contraseña
          </Button>
        </Stack>
      </form>
      <Box sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>
        <Link to="/auth/login" className="form-link">
         <Typography sx={{ fontSize: '14px', marginRight: '8px', fontStyle:'normal' }}>Regresar a inicio de sesión</Typography>
        </Link>
      </Box>
    </Stack>
  );
};

export default RecoverPassForm;
export { RecoverPassForm };
