import { FC } from "react";
import { School, Add, Upload, Download, PeopleAlt, TableChart, BarChart, LibraryBooks, Link } from "@mui/icons-material";

interface NavIconProps {
  name: string | undefined;
  margin?: boolean;
}

const NavIcon: FC<NavIconProps> = ({ name, margin }) => {
  switch (name) {
    case "school":
      return <School sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "add":
      return <Add sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "upload":
      return <Upload sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "download":
      return <Download sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "user":
      return <PeopleAlt sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "performance":
      return <TableChart sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "results":
      return <BarChart sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "experiences":
      return <LibraryBooks sx={{ marginRight: margin ? "12px" : "0" }} />;
    case "links":
      return <Link sx={{ marginRight: margin ? "12px" : "0" }} />;
    default:
      return <School sx={{ marginRight: margin ? "12px" : "0" }} />;
  }
};

export { NavIcon };
