import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { statusTypes, archiveTypes, categories } from "../../../utils/catalogs";
import {
  createExperience,
  updateExperience,
} from "../../../services/experiences.service";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Card,
  CardMedia,
  Typography,
  IconButton,
  CardHeader,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Experience } from "../../../utils/models";
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import  ToolbarPlugin from "./toolbar.plugin"
import theme from "./theme"
import { $getRoot, EditorState, LexicalEditor} from "lexical"
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Close } from "@mui/icons-material";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import AutoLinkPlugin from "./autolink.plugin";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

interface FormExperienceProps {
  actionDrawer?: any;
  experienceSelected?: Experience | undefined;
  setUpdatedExperience?: any;
  setTextModal: any;
  setOpenModal: any;
}

const FormExperience: FC<FormExperienceProps> = ({
  experienceSelected,
  actionDrawer,
  setTextModal,
  setOpenModal,
  setUpdatedExperience,
}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<any>("");
  const [description, setDescription] = useState('');
  const [archived, setArchived] = useState<any>("");
  const [projectImages, setProjectImages] = useState<any>([]);
  const [productionImages, setProductionImages] = useState<any>([]);
  const [projectImagesUploaded, setProjectImagesUploaded] = useState<any>([]);
  const [highlight, setHighlight] = useState<any>(false);
  const [category, setCategory] = useState<any>("");
  const [productionImagesUploaded, setProductionImagesUploaded] = useState<any>(
    []
  );
  const [loadText, setLoadText] = useState(false);

  type formValues = {
    projectName: string;
    projectManagers: string;
    schoolName: string;
    location: string;
    relevance: any;
  };

  const form = useForm<formValues>({
    defaultValues: {
      projectName: "",
      projectManagers: "",
      schoolName: "",
      location: "",
      relevance: 0,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    function initialValues() {
      if (experienceSelected) {
        const {
          projectName,
          schoolName,
          location,
          projectManagers,
          relevance,
          projectImages,
          description,
          productionImages,
          status,
          archived,
          highlight,
          category
        } = experienceSelected;
        form.setValue("projectName", projectName);
        form.setValue("schoolName", schoolName);
        form.setValue("projectManagers", projectManagers);
        form.setValue("location", location);
        setDescription(description);
        if (projectImages) setProjectImagesUploaded([...projectImages]);
        if (productionImages) setProductionImagesUploaded([...productionImages]);
        if (relevance) form.setValue("relevance", relevance);
        if (status) setStatus(status);
        if (category) setCategory(category);
        if (highlight) setHighlight(highlight);
        setArchived(archived);
      }
    }
    initialValues();
  }, [experienceSelected, form]);

  function LoadHtmlPlugin() {
    const [editor] = useLexicalComposerContext();
    if (experienceSelected) {
      editor.update(() => {
        if(!loadText){
          let nodes = [];
          // Parse html
          const parser = new DOMParser();
          const dom = parser.parseFromString(description, "text/html");
          nodes = $generateNodesFromDOM(editor, dom);
          // Set content
          const root = $getRoot();
          root.clear();
          root.append(...nodes);
          setTimeout(function() {
            setLoadText(true);
          }, 500);
        }
      });
    }

    return null;
  }

  function onDropdownChange(fieldName: string, newValue: any) {
    const value = newValue.target.value;
    if (fieldName === "status") setStatus(value);
    else if (fieldName === "category") setCategory(value);
    else setArchived(value);
  }

  function onRemoveImage(fieldName: string, idx: number) {
    if (fieldName === "project"){
      const res = projectImagesUploaded.length > 1 ?  [...projectImagesUploaded].splice(idx - 1, 1) : []
      setProjectImagesUploaded(res);
    }
    else {
      const res =  productionImagesUploaded.length > 1 ? [...productionImagesUploaded].splice(idx - 1, 1) : []
      setProductionImagesUploaded(res);
    }
  }

  async function onSubmit(data: formValues): Promise<void> {
    if (data) {
      if (description !== '') {
        try {
          let succes;
          let updateData:any = {};
          let project = [];
          let production = [];
          setLoading(true);

          if (projectImages.length > 0) {
            project = projectImages.map((image: any) => {return image.file})
          }
          if (productionImages.length > 0) {
            production = productionImages.map((image: any) => {return image.file})
          }
          if (experienceSelected) {
            updateData = {
              projectName: data.projectName,
              schoolName: data.schoolName,
              location: data.location,
              projectManagers: data.projectManagers,
              relevance: parseInt(data.relevance),
              projectImages: project,
              productionImages: production,
              description,
              status,
              archived:
                typeof archived == "boolean" ? archived : archived === "true",
              highlight:
                typeof highlight == "boolean" ? highlight : highlight === "true",
              category
            };
            if (projectImagesUploaded.length > 0) {
              updateData.projectImagesUploaded = projectImagesUploaded;
            }
            if (productionImagesUploaded.length > 0) {
              updateData.productionImagesUploaded = productionImagesUploaded;
            }
            const id = experienceSelected._id;
            succes = await updateExperience(id, updateData);
          } else {
            succes = await createExperience({
              projectName: data.projectName,
              schoolName: data.schoolName,
              location: data.location,
              projectManagers: data.projectManagers,
              relevance: parseInt(data.relevance),
              projectImages: project,
              productionImages: production,
              description,
              status: 'approved',
              highlight:
                typeof highlight == "boolean" ? highlight : highlight === "true",
              category
            });
          }

          if (succes) {
            if (setUpdatedExperience) {
              const updateSchool = Object.assign(
                { ...experienceSelected },
                { ...updateData }
              );
              setUpdatedExperience(updateSchool);
            }
            if (experienceSelected) {
              setTextModal(
                `La experiencia ${data.projectName} se ha actualizado correctamente`
              );
            } else {
              setTextModal(
                `La experiencia ${data.projectName} se ha creado correctamente y ya se puede visializar dentro de la pagina nutrento.org`
              );
            }
          } else {
            setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          }
          if (actionDrawer) {
            actionDrawer(false);
          }
          setLoading(false);
          form.reset();
          setStatus("");
          setOpenModal(true);
        } catch (err: any) {
          setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          setLoading(false);
          setOpenModal(true);
        }
      }
    }
  }

  function onChange(editorState: EditorState, editor: LexicalEditor) {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      setDescription(htmlString);
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHighlight(event.target.checked);
  };

  function onError(error:any) {
    console.error(error);
  }

  const initialConfig = {
    namespace: 'Editor',
    theme,
    onError,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Nombre del proyecto o programa"
        {...register("projectName", {
          required: "El nombre no puede estar vacio",
        })}
        error={!!errors.projectName}
        helperText={errors.projectName?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Nombre de escuela(s) en dónde se ejecuta el proyecto"
        {...register("schoolName", {
          required: "El nombre de la escuela no puede estar vacio",
        })}
        error={!!errors.schoolName}
        helperText={errors.schoolName?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Localidad/Municipio/Región o Estado/País"
        {...register("location", {
          required: "La localidad no puede estar vacia",
        })}
        error={!!errors.location}
        helperText={errors.location?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Nombre del Responsable(s) del proyecto o grupo de docentes"
        {...register("projectManagers", {
          required: "Los nombres no pueden estar vacios",
        })}
        multiline
        maxRows={4}
        error={!!errors.projectManagers}
        helperText={errors.projectManagers?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
       <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
              <ToolbarPlugin />
              <div className="editor-inner">
                <RichTextPlugin
                  contentEditable={<ContentEditable className="editor-input"/>}
                  placeholder={<div className="editor-placeholder">Descripción de la experiencia
                  (objetivo, qué hizo, para qué es la experiencia, resultados) Proporciona la página web y/o redes sociales del proyecto si cuenta con ellas
                  </div>}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <LoadHtmlPlugin />
                <OnChangePlugin onChange={onChange} ignoreSelectionChange/>
                <HistoryPlugin />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin />
              </div>
            </div>
          </LexicalComposer>
      <TextField
        label="Relevancia"
        {...register("relevance", {
          required: "La relevancia no puede estar vacia",
        })}
        type="number"
        error={!!errors.relevance}
        helperText={
          errors.relevance?.message
            ? errors.relevance?.message
            : "Por defecto tiene el número 0, entre mayor sea la relevancia aparecera primero en la página de nutrento"
        }
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
          select
          label="Categoria"
          value={category}
          onChange={(value) => onDropdownChange("category", value)}
          sx={{ width: "100%", marginBottom: "12px" }}
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </TextField>
      {experienceSelected && (
        <TextField
          select
          label="Estado"
          value={status}
          onChange={(value) => onDropdownChange("status", value)}
          sx={{ width: "100%", marginBottom: "12px" }}
        >
          {statusTypes.map((statusType) => (
            <MenuItem key={statusType.value} value={statusType.value}>
              {statusType.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {experienceSelected && (
        <TextField
          select
          label="Archivar"
          value={archived}
          onChange={(value) => onDropdownChange("archived", value)}
          sx={{ width: "100%", marginBottom: "12px" }}
        >
          {archiveTypes.map((archiveType) => (
            <MenuItem key={archiveType.value} value={archiveType.value}>
              {archiveType.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <FormControlLabel control={<Checkbox checked={highlight} onChange={handleChange}/>} label="Destacada"  sx={{marginBottom:'14px'}}/>
      <FilePond
        files={projectImages}
        onupdatefiles={setProjectImages}
        allowMultiple={true}
        allowProcess={false}
        allowFileTypeValidation={true}
        allowDrop={false}
        acceptedFileTypes={["image/*"]}
        credits={false}
        name="projectImages"
        labelIdle='<span class="filepond--label-action">Agregar imagenes del proyecto</span>'
      />
      <FilePond
        files={productionImages}
        onupdatefiles={setProductionImages}
        allowMultiple={true}
        allowProcess={false}
        allowFileTypeValidation={true}
        allowDrop={false}
        acceptedFileTypes={["image/*"]}
        credits={false}
        name="productionImages"
        labelIdle='<span class="filepond--label-action">Agregar fotografías de las producciones de alumnos/sugestión</span>'
      />
      {experienceSelected && (
        <Card sx={{ marginBottom: "12px" }}>
          <Typography sx={{ padding: "12px 12px 0 12px" }} variant="h6">
            Imagenes cargadas del proyecto
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "12px 0",
            }}
          >
            {projectImagesUploaded.map((image: any, idx: number) => (
              <Card sx={{ width: 210, margin: "12px" }} key={idx}>
                <CardHeader
                  action={
                    <Tooltip title="Remover" placement="top">
                      <IconButton aria-label="quitar" onClick={() => onRemoveImage("project", idx)}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  }
                  title={`Carga ${idx}`}
                />
                <Tooltip title="Descargar">
                  <a href={image} target="_blank" rel="noreferrer">
                    <CardMedia
                      component="img"
                      height="210"
                      image={image}
                      sx={{ objectFit: "contain" }}
                      alt={`image_${idx}`}
                    />
                  </a>
                </Tooltip>
              </Card>
            ))}
          </Box>
        </Card>
      )}
      {experienceSelected && (
        <Card sx={{ marginBottom: "12px" }}>
          <Typography sx={{ padding: "12px 12px 0 12px" }} variant="h6">
            Imagenes cargadas de las producciones de alumnos/sugestión
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "12px 0",
            }}
          >
            {productionImagesUploaded.map((image: any, idx: number) => (
              <Card sx={{ width: 210, margin: "12px" }} key={idx}>
                <CardHeader
                  action={
                    <Tooltip title="Remover" placement="top">
                      <IconButton aria-label="quitar" onClick={() => onRemoveImage("production", idx)}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  }
                  title={`Carga ${idx}`}
                />
                <Tooltip title="Descargar">
                  <a href={image} target="_blank" rel="noreferrer">
                    <CardMedia
                      component="img"
                      height="210"
                      image={image}
                      sx={{ objectFit: "contain" }}
                      alt={`image_${idx}`}
                    />
                  </a>
                </Tooltip>
              </Card>
            ))}
          </Box>
        </Card>
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained" color="primary">
          {loading
            ? "Procesando..."
            : experienceSelected
            ? "Actualizar experiencia"
            : "Crear experiencia"}
        </Button>
      </Box>
    </form>
  );
};

export default FormExperience;
