import { FC, useState } from 'react';
import MainLayout from "../../common/layouts/main.layout";
import { AnalyticsTable } from './widgets';
import { KpiTrend } from './widgets/kpi-trend.w';
import { Box } from '@mui/material';
import DateRangeInput from '../../common/components/date-range';
import moment from "moment";
import { DateRange } from '../../utils';
import { ReloadIcon } from '../../common/components/reload';

interface ExportScreenProps {
};

const PerformanceScreen: FC<ExportScreenProps> = () => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [dateRangeButton, setDateRangeButton] = useState<DateRange>({
    start: moment().subtract(0, "month").startOf("month").startOf("day"),
    end: moment().endOf("day"),
  });

  function reloadDates () {
    setDateRangeButton({ start: dateRange?.start, end: dateRange?.end })
  }
  
  return (
    <MainLayout title='Performace'>
      <Box sx={{ display: "flex", marginBottom: "8px", alignItems:'center' }}>
        <Box sx={{ flexGrow: 1 }} />
        <DateRangeInput onRangeChange={setDateRange} initialValue={dateRangeButton}/>
        <button
          className="reload-button"
          onClick={reloadDates}
        >
          <ReloadIcon/>
        </button>
      </Box>
      <AnalyticsTable dateRange={dateRangeButton}/>
      <KpiTrend  dateRange={dateRangeButton}/>
    </MainLayout>
  );
}

export default PerformanceScreen;
export {
  PerformanceScreen,
};