import { constants } from '../utils';

async function getResults (data: any): Promise<any> {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/analytics/results`, fetchOpts);
  const response: any = await fetchResponse.json();
  if (response?.report && response?.report.length > 0) return response.report[0];
  else return null
}

export {
  getResults,
}