import { Box } from "@mui/material";
import MainLayout from "../../common/layouts/main.layout";
import { FileUploadForm } from "./widgets";

const UploadUsersScreen = () => {
  return (
    <MainLayout title="Cargar Docentes" secondary={true}>
      <Box sx={{ padding: "32px 24px", maxWidth: "600px", margin:"0 auto", borderRadius:"12px", background:"#fff", boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }}>
        <FileUploadForm />
      </Box>
    </MainLayout>
  );
};

export default UploadUsersScreen;
