import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { MenuOption } from '../../utils/models';

// Define a type for the slice state
interface GlobalState {
  menuOption: MenuOption;
  openDrawer: boolean;
}

// Define the initial state using that type
const initialState: GlobalState = {
  menuOption: {
    icon: "performance",
    label: "Performance",
    path: "/performance"
  },
  openDrawer: true
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setMenuOption: (state, action: PayloadAction<MenuOption>) => {
      state.menuOption = action.payload;
    },
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
  },
})

export const { setMenuOption, setOpenDrawer } = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMenuOptionSlice = (state: RootState) => state.global.menuOption;
export const selectOpenDrawerSlice = (state: RootState) => state.global.openDrawer;

export default globalSlice.reducer;