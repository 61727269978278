import { Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import useUsers from "../../../common/hooks/use-users";
import { BaseTable } from "../../../common/components";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Edit, RemoveCircle } from "@mui/icons-material";
import { User } from "../../../utils";

interface UserTableProps {
  setTextModal: any;
  setOpenDialog: any;
  setEdition: any;
  updatedUser: any;
  userSelected: User | undefined;
  setUserselected: any;
  loading: boolean;
}

const AdminTable: FC<UserTableProps> = ({
  setTextModal,
  setOpenDialog,
  setEdition,
  updatedUser,
  userSelected,
  setUserselected,
  loading,
}) => {
  const [loadUsers, loadingUser, users] = useUsers({ admin: true });
  const [rows, setRows] = useState<any>([]);

  const handleEditClick = (user: User) => () => {
    setUserselected(user);
    setEdition(true);
  };

  const handleRemoveClick = (user: User) => () => {
    setUserselected(user);
    setTextModal(
      `Estas seguro de que quieres quitar el acceso al administrador ${user.name} para que ya no pueda acceder a nutrento? Una vez se quite el acceso se eliminara por completo del sistema`
    );
    setOpenDialog(true);
  };

  const getColorCell = (row: User) => {
    if (row.archived) return "archivedCell";
    return "auto";
  };

  const cols: GridColDef[] = [
    {
      field: "_id",
      type: "string",
      headerName: "Id",
      disableColumnMenu: true,
      flex: 0.65,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "email",
      type: "string",
      headerName: "Correo",
      disableColumnMenu: true,
      flex: 0.65,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <Tooltip title="Editar">
            <GridActionsCellItem
              icon={<Edit />}
              label="Editar"
              className="textPrimary"
              onClick={handleEditClick(row)}
              color="primary"
            />
          </Tooltip>,
          <Tooltip
            title={row.email === "anonimo@nutrento.org" ? "" : "Remover acceso"}
          >
            <GridActionsCellItem
              icon={
                row.email === "anonimo@nutrento.org" ? (
                  <RemoveCircle sx={{ color: "transparent" }} />
                ) : (
                  <RemoveCircle />
                )
              }
              label="Remover acceso"
              onClick={handleRemoveClick(row)}
              color="error"
              disabled={
                row.email === "anonimo@nutrento.org" || loading ? true : false
              }
            />
          </Tooltip>,
        ];
      },
    },
  ];

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    if (users) {
      setRows(users);
    }
  }, [users]);

  useEffect(() => {
    function update() {
      const idx = rows.indexOf(userSelected);
      if (idx > -1) {
        if (updatedUser === "delete")
          setRows(rows.filter((row: any) => row._id !== userSelected?._id));
        else
          setRows((prevState: any) => {
            const nextData = prevState.slice();
            nextData[idx] = updatedUser;
            return nextData;
          });
      }
    }
    update();
  }, [updatedUser]);

  return (
    <BaseTable
      rows={rows}
      columns={cols}
      loading={loadingUser}
      onReload={loadUsers}
      rowHeight={32}
      autoHeight
      downloadFileName={"Administradores"}
      pagination={true}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      pageSizeOptions={[15, 25]}
      slotProps={{
        toolbar: {
          csvOptions: {
            allColumns: true,
          },
          showQuickFilter: true,
          showFilterButton: true,
        },
      }}
      localeText={{
        toolbarFilters: "filtrar",
        toolbarExport: "",
        toolbarColumns: "Columnas",
        noRowsLabel: "No se encontraron administradores.",
        toolbarExportCSV: "Descargar CSV",
        toolbarExportLabel: "Exportar",
        toolbarFiltersTooltipShow: "Mostrar filtros",
        toolbarFiltersTooltipHide: "Ocultar filtros",
        MuiTablePagination: {
          labelRowsPerPage: "Usuarios por página:",
          labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de ${count}`,
        },
      }}
    />
  );
};

export default AdminTable;
