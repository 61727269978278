import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, TextField, Button, Typography } from "@mui/material";
import { User } from "../../../utils/models";
import { createUser, updateUser } from "../../../services/users.services";
import { useAppSelector } from "../../../store/hooks";
import { selectSchoolsApprovedOptions } from "../../../store/slices/schools";
import { MultiSelect, Option } from "react-multi-select-component";

interface FormUserProps {
  actionDrawer?: any;
  userSelected?: User | undefined;
  setUpdatedUser?: any;
  setTextModal: any;
  setOpenModal: any;
  tab?: number;
}

const FormUser: FC<FormUserProps> = ({
  userSelected,
  actionDrawer,
  setTextModal,
  setOpenModal,
  setUpdatedUser,
  tab
}) => {
  const [loading, setLoading] = useState(false);
  const [schoolsSelected, setSchoolsSelected] = useState<any>([]);
  const schools = useAppSelector((state) =>
    selectSchoolsApprovedOptions(state, "approved")
  );

  type formValues = {
    name: string;
    email?: string;
    level?: any;
  };

  const form = useForm<formValues>({
    defaultValues: {
      name: "",
      email: "",
      level: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    function initialValues() {
      if (userSelected) {
        const { name } = userSelected;
        form.setValue("name", name);
        if (userSelected.schools && userSelected.schools.length > 0) {
          setSchoolsSelected(schools.filter(item => userSelected.schools && userSelected.schools.indexOf(item.value) !== -1))
        }
      }
    }
    initialValues();
  }, [userSelected, form, schools]);

  function onDropdownChange(fieldName: string, newValue: any) {
    switch (fieldName) {
      case "schoolsSelected":
        setSchoolsSelected(newValue);
        break;
      default:
        break;
    }
  }

  async function onSubmit(formValues: formValues): Promise<void> {
    if (formValues) {
      try {
        setLoading(true);
        if (userSelected) {
          const updateData:any = {
            name: formValues.name
          };
          if (tab === 0) updateData["schools"] = schoolsSelected.map((v:any) => v.value);
          const id = userSelected._id;
          const succes = await updateUser(id, updateData);
          if (succes) {
            if (setUpdatedUser) {
              const updateSchool = Object.assign(
                { ...userSelected },
                { ...updateData }
              );
              setUpdatedUser(updateSchool);
            }
            if (userSelected) {
              setTextModal(
                `El usuario ${formValues.name} se ha actualizado correctamente`
              );
            }
          } else {
            setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          }
        } else {
          if (formValues.email) {
            let user:any = {
              name: formValues.name,
              email: formValues.email,
            } 
            const { message, data } = await createUser(
              user,
              false
            );
            if (data) {
              setTextModal(
                `El usuario ${formValues.name} se ha invitado correctamente a Nutrento`
              );
            } else {
              setTextModal(message?.es);
            }
          }
        }
        if (actionDrawer) {
          actionDrawer(false);
        }
        setLoading(false);
        form.reset();
        setOpenModal(true);
      } catch (err: any) {
        setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
        setLoading(false);
        setOpenModal(true);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Nombre"
        {...register("name", { required: "El nombre no puede estar vacio" })}
        error={!!errors.name}
        helperText={errors.name?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      {(userSelected && tab === 0) && (
        <>
        <Typography sx={{marginBottom:"4px"}}>Escuelas vinculadas</Typography>
        <MultiSelect
          className="multiselect"
          key={"schools"}
          options={schools || []}
          value={schoolsSelected || []}
          onChange={(values: Option[]) =>
            onDropdownChange("schoolsSelected", values)
          }
          labelledBy="Filtro"
          valueRenderer={(selected, allOptions) =>
            selected.length > 0 ? `${selected.length} seleccionadas` : ""
          }
          disableSearch={schools.length < 5}
          hasSelectAll={false}
          overrideStrings={{
            allItemsAreSelected: "Todos seleccionados",
            clearSearch: "Limpiar",
            noOptions: "No hay opciones",
            search: `Buscar escuela`,
            selectAll: "Seleccionar todo",
            selectAllFiltered: "Seleccionar elementos filtrados",
            selectSomeItems: `Buscar escuelas`,
          }}
        />
        </>
      )}
      {!userSelected && (
        <TextField
          label="Correo"
          {...register("email", {
            required: "Correo requerido",
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Introduce un correo valido",
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{ width: "100%", marginBottom: "12px" }}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained" color="primary">
          {loading
            ? "Procesando..."
            : userSelected
            ? "Actualizar"
            : "Invitar docente"}
        </Button>
      </Box>
    </form>
  );
};

export default FormUser;
