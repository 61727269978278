import { useState } from 'react'
import './dropdown.css'

interface DropdownOption {
  display: any,
  distinctId: string;  
}

interface DropdownProps {
  options: Array<DropdownOption>,
  selectedValue: string,
  onChange?: (option: DropdownOption) => void
  lang?: any,
}

const Dropdown = ({
  options,
  selectedValue,
  onChange= () => {},
  lang = "es",
}: DropdownProps) => {
  const [showList, setShowList] = useState(false)

  return (
    <div style={{ position: 'relative' }}>
      <span
        onClick={() => setShowList(!showList)}
        className="dropdown-button"
      >
        {options.find(o => o.distinctId === selectedValue)?.display[lang]}
      </span>
      {showList && (
        <div className="dropdown-list">
          {options.map(option => (
              <div
                key={option.distinctId}
                className="dropdown-item"
                onClick={() => {
                  onChange(option);
                  setShowList(false);
                }}>
                {option.display[lang]}
              </div>
          ))}
        </div>
      )}
    </div>
  )
}

export {
  Dropdown
}