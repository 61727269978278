import { getData } from '../services/local-storage.service';
import { constants } from '../utils';
import { ServerResponse, Experiences } from '../utils/models'

async function getAllExperiences (): Promise<Experiences> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/experience`, fetchOpts);
  const response: ServerResponse<Experiences> = await fetchResponse.json();
  return response.data;
}

async function createExperience (experience: any): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const formData = new FormData()
  Object.keys(experience).forEach((key) => {
    if (key ==='projectImages'){
      experience.projectImages.forEach((file: File) => {
        formData.append('projectImages', file);
      });
    }
    else if (key ==='productionImages'){
      experience.productionImages.forEach((file: File) => {
        formData.append('productionImages', file);
      });
    }
    else formData.append(key, experience[key]);
  });
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/experience`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

async function updateExperience (id: any, data: any): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key ==='projectImages'){
      data.projectImages.length > 0 && data.projectImages.forEach((file: File) => {
        formData.append('projectImages', file);
      });
    }
    else if (key ==='productionImages'){
      data.projectImages.length > 0 && data.productionImages.forEach((file: File) => {
        formData.append('productionImages', file);
      });
    }
    else if (key ==='projectImagesUploaded'){
      data.projectImagesUploaded.length > 0 && data.projectImagesUploaded.forEach((file: string) => {
        formData.append('projectImagesUploaded', file);
      });
    }
    else if (key ==='productionImagesUploaded'){
      data.productionImagesUploaded.length > 0 && data.productionImagesUploaded.forEach((file: string) => {
        formData.append('productionImagesUploaded', file);
      });
    }
    else formData.append(key, data[key]);
  });
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/experience/${id}`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  else return true;
}

export {
  getAllExperiences,
  createExperience,
  updateExperience
}