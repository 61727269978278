import { Container } from '@mui/material';

const StudiesEmpty = () => {
  return(
    <Container>
      <p>No existen resultados para mostrar</p>
    </Container>
  );
}

export default StudiesEmpty;