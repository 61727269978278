import { getData } from './local-storage.service';
import { constants } from '../utils';
import { ServerResponse, Users, User } from '../utils/models'

interface RegisterResult {
  message?: {
    es: string,
    default: string
  };
  data?: Object;
}

async function getAllUsers (admin: boolean): Promise<Users> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = admin ? `${constants.API_URL}/users-management?conditions={"admin":true}` : `${constants.API_URL}/users-management?conditions={"admin":{"$ne": true}}`
  const fetchResponse: Response = await fetch(url, fetchOpts);
  const response: ServerResponse<Users> = await fetchResponse.json();
  return response.data;
}

async function createUser (user: User, admin?: boolean): Promise<RegisterResult> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const url = admin ? `${constants.API_URL}/users-management/create-admin` : `${constants.API_URL}/users-management`
  const data = admin ? Object.assign({...user}, {level: "ADMIN", verified: true}) : Object.assign({...user}, {verified: true})
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  };
  const fetchResponse: Response = await fetch(url, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  return response;
}

async function updateUser (id: any, data: any): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/users-management/update/${id}`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  else return true;
}

async function unsubscribeUser (email: string): Promise<ServerResponse<any>> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({email})
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/users-management/unsubscribe`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  return response;
}

async function deleteUser (email: string): Promise<ServerResponse<any>> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({email})
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/users-management/delete-user`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  return response;
}

const resendEmail = async (email: string): Promise<any | undefined> => {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email})
  };
  let URL = `${constants.API_URL}/users-management/resend-password-email`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse.json();
  if (!response) return undefined
  return response;
}

export {
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  unsubscribeUser,
  resendEmail
}