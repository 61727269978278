import { FC } from 'react';
import { Container, Grid } from '@mui/material';

interface CenteredLayoutProps {
  title?: string;
};

const CenteredLayout: FC<CenteredLayoutProps> = ({
  title,
  children
}) => {
  return (
    <Grid>
      <Container>
        <Container>
          {title && (
            <h1>{title}</h1>
          )}
        </Container>
        <Container>
          {children}
        </Container>
      </Container>
    </Grid>
  );
}

export default CenteredLayout;
export {
  CenteredLayout
};