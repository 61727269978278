import { useCallback, useState } from 'react';
import { getAllSchools } from '../../services/schools.services';
import { Schools } from '../../utils/models';

type UseSchoolsHook = () => [
  () => Promise<any>,
  boolean,
  Schools,
];

const useSchools: UseSchoolsHook = () => {

  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState<Schools>();

  const loadAllSchools = useCallback(
    async () => {
      try {
        setSchools(undefined);
        setLoading(true);
        const schoolsList = await getAllSchools();
        if (schoolsList) {
          setSchools(schoolsList);
        }
      } catch (e) {
        console.log(e as Error)
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return [
    loadAllSchools,
    loading,
    schools,
  ]

}

export default useSchools;