import React, { FC, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectSlice } from "../../../store/slices/studies";
import { importByFile } from "../../../services/imports.service";
import { DialogModal } from "../../../common/components";
import { Box, Button, Stack, Typography } from "@mui/material";

const FileUploadForm: FC = () => {
  const [selectedFile, setSelectedFile] = useState<FileList>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const studies = useAppSelector(selectSlice);

  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    let file = files?.[0];
    if (!files || !file) {
      setSelectedFile(undefined);
      setIsFilePicked(false);
      return;
    }

    const acceptedMimeTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file.name.endsWith(".csv") || acceptedMimeTypes.includes(file.type)) {
      setSelectedFile(files);
      setIsFilePicked(true);
    } else {
      setSelectedFile(undefined);
      setIsFilePicked(false);
      e.target.value = "";
      setTextModal(
        `Por favor selecciona un archivo CSV. Archivo seleccionado: ${file.name} (${file.type})`
      );
      setOpenModal(true);
    }
  };

  const handleSubmission = async () => {
    if (selectedFile && studies.selected) {
      setLoading(true);
      try {
        await importByFile(
          studies.selected._id,
          studies.selected.importUsersDatamapId,
          selectedFile
        );
        setTextModal("El archivo se encuentra creando los docentes, una vez que termine apareceran en la pantalla de Usuarios pestaña Docentes");
        setOpenModal(true);
      } catch (error) {
        console.error("Error:", error);
        setTextModal("Ocurrió un problema al importar el archivo.");
        setOpenModal(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const message = isFilePicked
    ? `Se cargarán y procesarán los registros del archivo ${selectedFile?.[0].name}`
    : `Por favor selecciona un archivo CSV con docentes para cargar a ${studies.selected?.name}.`;

  return (
    <Stack direction="column" alignItems="left" spacing={2}>
      <DialogModal
        title="Carga realizada"
        text={textModal}
        onAgree={() => setOpenModal(false)}
        open={open}
        setOpen={setOpenModal}
        hideCloseButton={true}
      />
      <Typography>{message}</Typography>
      <Box>
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          style={{ marginBottom: 30, width: "100%" }}
        />
        <Stack direction="column" alignItems="center" spacing={2}>
          <Button
            disabled={!isFilePicked || loading}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSubmission}
            sx={{ width: "200px" }}
          >
            {loading ? `Cargando archivo...` : `Cargar archivo`}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FileUploadForm;
export { FileUploadForm };
