import { useCallback, useState } from 'react';
import { getAllLinks } from '../../services/links.services';
import { Links } from '../../utils/models';

type UseLinksHook = () => [
  () => Promise<any>,
  boolean,
  Links,
];

const useLinks: UseLinksHook = () => {

  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState<Links>();

  const loadAllSchools = useCallback(
    async () => {
      try {
        setLinks(undefined);
        setLoading(true);
        const linksList = await getAllLinks();
        if (linksList) {
          setLinks(linksList);
        }
      } catch (e) {
        console.log(e as Error)
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return [
    loadAllSchools,
    loading,
    links,
  ]

}

export default useLinks;