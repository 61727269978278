import MainLayout from "../../common/layouts/main.layout";
import { Box } from "@mui/material";
import { useState } from "react";
import { FormSchool } from "./widgets";
import { DialogModal } from "../../common/components";

const CreateSchoolScreen = () => {
  const [open, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");

  return (
    <MainLayout title="Crear Escuela" secondary={true}>
      <DialogModal
        text={textModal}
        onAgree={() => setOpenModal(false)}
        open={open}
        setOpen={setOpenModal}
        hideCloseButton={true}
      />
      <Box sx={{ padding: "32px 24px", maxWidth: "600px", margin:"0 auto", borderRadius:"12px", background:"#fff", boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" }}>
        <FormSchool setOpenModal={setOpenModal} setTextModal={setTextModal} />
      </Box>
    </MainLayout>
  );
};

export default CreateSchoolScreen;
