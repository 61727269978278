import { FC, useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import moment, { Moment } from "moment";
import { MomentRange } from "../../utils";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./date-range.css";
import 'moment/locale/es'

type MomentDate = Moment | null;

interface DateChangeShape {
  startDate: MomentDate;
  endDate: MomentDate;
}

interface DateRangeInputProps {
  initialValue?: MomentRange;
  onRangeChange: any;
  disabled?: boolean;
}

const DateRangeInput: FC<DateRangeInputProps> = ({
  initialValue,
  onRangeChange,
  disabled,
}) => {
  const [startDate, setStartDate] = useState<MomentDate>(
    initialValue?.start || null
  );
  const [endDate, setEndDate] = useState<MomentDate>(initialValue?.end || null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  moment.locale('es-en');

  function isDayBlocked(day: Moment) {
    const today = moment().startOf("day");
    const diff = today.diff(day.startOf("day"));
    if (diff >= 0) {
      return false;
    }
    return true;
  }

  function onDatesChange(change: DateChangeShape) {
    const { startDate: start, endDate: end } = change;
    setStartDate(moment(start?.startOf("day")));
    setEndDate(end);
    if (start && end) {
      onRangeChange({ start, end });
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <DateRangePicker
        startDateId="start"
        endDateId="end"
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        startDatePlaceholderText="Inicio"
        endDatePlaceholderText="Fin"
        firstDayOfWeek={1}
        displayFormat="DD/MM/YYYY"
        isDayBlocked={isDayBlocked}
        minimumNights={0}
        isOutsideRange={() => false}
        initialVisibleMonth={null}
        noBorder
        disabled={disabled}
        small
      />
    </div>
  );
};

export default DateRangeInput;
