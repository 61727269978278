export const shedules = [
  {
    value: "matutino",
    label: "Matutino",
  },
  {
    value: "vespertino",
    label: "Vespertino",
  },
  {
    value: "tiempo_completo",
    label: "Tiempo completo",
  },
  {
    value: "otro",
    label: "Otro",
  },
];

export const shedulesLabels: any = {
  matutino: "Matutino",
  vespertino: "Vespertino",
  tiempo_completo: "Tiempo completo",
  otro: "Otro"
}

export const scopes = [
  {
    value: "rural",
    label: "Rural",
  },
  {
    value: "urbano",
    label: "Urbano",
  },
];

export const archiveTypes = [
  {
    value: "false",
    label: "Falso",
  },
  {
    value: "true",
    label: "Verdadero",
  },
];

export const categories = [
  {
    value: "Docentes en aulas y/o escuelas",
    label: "Docentes en aulas y/o escuelas",
  },
  {
    value: "Proyectos de investigaciones",
    label: "Proyectos de investigaciones",
  },
  {
    value: "Instituciones de gobierno y civiles",
    label: "Instituciones de gobierno y civiles",
  },
];

export const scopesLabels:any = {
  rural: "Rural",
  urbano: "Urbano"
}

export const statusTypes = [
  {
    value: "review",
    label: "Revisión",
  },
  {
    value: "approved",
    label: "Aprobado",
  },
];

export const consentTypes = [
  {
    value: false,
    label: "No aceptado",
  },
  {
    value: true,
    label: "Aceptado",
  },
]

export const levels = [
  {
    value: "USER",
    label: "Docente",
  },
  {
    value: "ADMIN",
    label: "Administrador",
  },
];

export const genders = [
  {
    value: "mujer",
    label: "Mujer",
  },
  {
    value: "hombre",
    label: "Hombre",
  },
];

export const maritalStatusCatalog = [
  {
    value: "casado",
    label:  "Casad@",
  },
  {
    value: "soltero",
    label: "Solter@",
  },
  {
    value: "divorciado",
    label: "Divorciad@",
  },
  {
    value: "union_libre",
    label: "Unión Libre",
  },
]

export const degrees = [
  {
    value: "normalista",
    label: "Normalista",
  },
  {
    value: "licenciatura_institucion_publica",
    label:"Licenciatura en educación en institución pública",
  },
  {
    value: "licenciatura_institucion_privada",
    label: "Licenciatura en educación en institución privaba",
  },
  {
    value: "psicologia",
    label: "Psicología",
  },
  {
    value: "otra",
    label: "Otra",
  },
]

export const educationalLevels = [
  {
    value: "nivel_tecnico",
    label: "Nivel técnico",
  },
  {
    value: "licenciatura",
    label: "Licenciatura",
  },
  {
    value: "especialidad",
    label: "Especialidad",
  },
  {
    value: "maestria",
    label:  "Maestría",
  },
  {
    value: "doctorado",
    label:  "Doctorado",
  },
]

export const contracts = [
  {
    value: "fijo",
    label: "Base o contrato fijo",
  },
  {
    value: "temporal",
    label:"Contrato temporal",
  },
]

export const lastDegree = [
  {
    value: true,
    label: "Sí",
  },
  {
    value: false,
    label: "No",
  },
]

export const statusTypesLabels:any = {
  review: "Revisión",
  approved: "Aprobado"
}

export const countries = [
  {
    label: "Chile",
    value: "Chile",
    regions: [
      {
        label: "Arica y Parinacota",
        value: "Arica-y-Parinacota",
        subregions: [
          { label: "Arica", value: "arica" },
          { label: "Camarones", value: "camarones" },
          { label: "General Lagos", value: "general-lagos" },
          { label: "Putre", value: "putre" },
        ],
      },
      {
        label: "Tarapacá",
        value: "Tarapaca",
        subregions: [
          { label: "Alto Hospicio", value: "alto-hospicio" },
          { label: "Camiña", value: "camina" },
          { label: "Colchane", value: "colchane" },
          { label: "Huara", value: "huara" },
          { label: "Iquique", value: "iquique" },
          { label: "Pica", value: "pica" },
          { label: "Pozo Almonte", value: "pozo-almonte" },
        ],
      },
      {
        label: "Antofagasta",
        value: "Antofagasta",
        subregions: [
          { label: "Antofagasta", value: "antofagasta" },
          { label: "Calama", value: "calama" },
          { label: "María Elena", value: "maria-elena" },
          { label: "Mejillones", value: "mejillones" },
          { label: "Ollagüe", value: "ollague" },
          { label: "San Pedro de Atacama", value: "san-pedro-de-atacama" },
          { label: "Sierra Gorda", value: "sierra-gorda" },
          { label: "Taltal", value: "taltal" },
          { label: "Tocopilla", value: "tocopilla" },
        ],
      },
      {
        label: "Atacama",
        value: "Atacama",
        subregions: [
          { label: "Alto del Carmen", value: "alto-del-carmen" },
          { label: "Caldera", value: "caldera" },
          { label: "Chañaral", value: "chanaral" },
          { label: "Copiapó", value: "copiapo" },
          { label: "Diego de Almagro", value: "diego-de-almagro" },
          { label: "Freirina", value: "freirina" },
          { label: "Huasco", value: "huasco" },
          { label: "Tierra Amarilla", value: "tierra-amarilla" },
          { label: "Vallenar", value: "vallenar" },
        ],
      },
      {
        label: "Coquimbo",
        value: "Coquimbo",
        subregions: [
          { label: "Andacollo", value: "andacollo" },
          { label: "Canela", value: "canela" },
          { label: "Combarbalá", value: "combarbala" },
          { label: "Coquimbo", value: "coquimbo" },
          { label: "Illapel", value: "illapel" },
          { label: "La Higuera", value: "la-higuera" },
          { label: "La Serena", value: "la-serena" },
          { label: "Los Vilos", value: "los-vilos" },
          { label: "Monte Patria", value: "monte-patria" },
          { label: "Ovalle", value: "ovalle" },
          { label: "Paiguano", value: "paiguano" },
          { label: "Punitaqui", value: "punitaqui" },
          { label: "Río Hurtado", value: "rio-hurtado" },
          { label: "Salamanca", value: "salamanca" },
          { label: "Vicuña", value: "vicuna" },
        ],
      },
      {
        label: "Valparaíso",
        value: "Valparaiso",
        subregions: [
          { label: "Algarrobo", value: "algarrobo" },
          { label: "Cabildo", value: "cabildo" },
          { label: "Calera", value: "calera" },
          { label: "Calle Larga", value: "calle-larga" },
          { label: "Cartagena", value: "cartagena" },
          { label: "Casablanca", value: "casablanca" },
          { label: "Catemu", value: "catemu" },
          { label: "Concón", value: "concon" },
          { label: "El Quisco", value: "el-quisco" },
          { label: "El Tabo", value: "el-tabo" },
          { label: "Hijuelas", value: "hijuelas" },
          { label: "Isla de Pascua", value: "isla-de-pascua" },
          { label: "Juan Fernández", value: "juan-fernandez" },
          { label: "La Cruz", value: "la-cruz" },
          { label: "La Ligua", value: "la-ligua" },
          { label: "Limache", value: "limache" },
          { label: "Llaillay", value: "llaillay" },
          { label: "Los Andes", value: "los-andes" },
          { label: "Nogales", value: "nogales" },
          { label: "Olmué", value: "olmue" },
          { label: "Panquehue", value: "panquehue" },
          { label: "Papudo", value: "papudo" },
          { label: "Petorca", value: "petorca" },
          { label: "Puchuncaví", value: "puchuncavi" },
          { label: "Putaendo", value: "putaendo" },
          { label: "Quillota", value: "quillota" },
          { label: "Quilpué", value: "quilpue" },
          { label: "Quintero", value: "quintero" },
          { label: "Rinconada", value: "rinconada" },
          { label: "San Antonio", value: "san-antonio" },
          { label: "San Esteban", value: "san-esteban" },
          { label: "San Felipe", value: "san-felipe" },
          { label: "Santa María", value: "santa-maria" },
          { label: "Santo Domingo", value: "santo-domingo" },
          { label: "Valparaíso", value: "valparaiso" },
          { label: "Villa Alemana", value: "villa-alemana" },
          { label: "Viña del Mar", value: "vina-del-mar" },
          { label: "Zapallar", value: "zapallar" },
        ],
      },
      {
        label: "Metropolitana de Santiago",
        value: "Metropolitana-de-Santiago",
        subregions: [
          { label: "Alhué", value: "alhue" },
          { label: "Buin", value: "buin" },
          { label: "Calera de Tango", value: "calera-de-tango" },
          { label: "Cerrillos", value: "cerrillos" },
          { label: "Cerro Navia", value: "cerro-navia" },
          { label: "Colina", value: "colina" },
          { label: "Conchalí", value: "conchali" },
          { label: "Curacaví", value: "curacavi" },
          { label: "El Bosque", value: "el-bosque" },
          { label: "El Monte", value: "el-monte" },
          { label: "Estación Central", value: "estacion-central" },
          { label: "Huechuraba", value: "huechuraba" },
          { label: "Independencia", value: "independencia" },
          { label: "Isla de Maipo", value: "isla-de-maipo" },
          { label: "La Cisterna", value: "la-cisterna" },
          { label: "La Florida", value: "la-florida" },
          { label: "La Granja", value: "la-granja" },
          { label: "La Pintana", value: "la-pintana" },
          { label: "La Reina", value: "la-reina" },
          { label: "Lampa", value: "lampa" },
          { label: "Las Condes", value: "las-condes" },
          { label: "Lo Barnechea", value: "lo-barnechea" },
          { label: "Lo Espejo", value: "lo-espejo" },
          { label: "Lo Prado", value: "lo-prado" },
          { label: "Macul", value: "macul" },
          { label: "Maipú", value: "maipu" },
          { label: "María Pinto", value: "maria-pinto" },
          { label: "Melipilla", value: "melipilla" },
          { label: "Ñuñoa", value: "nunoa" },
          { label: "Padre Hurtado", value: "padre-hurtado" },
          { label: "Paine", value: "paine" },
          { label: "Pedro Aguirre Cerda", value: "pedro-aguirre-cerda" },
          { label: "Peñaflor", value: "penaflor" },
          { label: "Peñalolén", value: "penalolen" },
          { label: "Pirque", value: "pirque" },
          { label: "Providencia", value: "providencia" },
          { label: "Pudahuel", value: "pudahuel" },
          { label: "Puente Alto", value: "puente-alto" },
          { label: "Quilicura", value: "quilicura" },
          { label: "Quinta Normal", value: "quinta-normal" },
          { label: "Recoleta", value: "recoleta" },
          { label: "Renca", value: "renca" },
          { label: "San Bernardo", value: "san-bernardo" },
          { label: "San Joaquín", value: "san-joaquin" },
          { label: "San José de Maipo", value: "san-jose-de-maipo" },
          { label: "San Miguel", value: "san-miguel" },
          { label: "San Pedro", value: "san-pedro" },
          { label: "San Ramón", value: "san-ramon" },
          { label: "Santiago", value: "santiago" },
          { label: "Talagante", value: "talagante" },
          { label: "Tiltil", value: "tiltil" },
          { label: "Vitacura", value: "vitacura" },
        ],
      },
      {
        label: "Libertador Gral. Bernardo O’Higgins",
        value: "Libertador-Gral.-Bernardo-OHiggins",
        subregions: [
          { label: "Chimbarongo", value: "chimbarongo" },
          { label: "Chépica", value: "chepica" },
          { label: "Codegua", value: "codegua" },
          { label: "Coinco", value: "coinco" },
          { label: "Coltauco", value: "coltauco" },
          { label: "Doñihue", value: "donihue" },
          { label: "Graneros", value: "graneros" },
          { label: "La Estrella", value: "la-estrella" },
          { label: "Las Cabras", value: "las-cabras" },
          { label: "Litueche", value: "litueche" },
          { label: "Lolol", value: "lolol" },
          { label: "Machalí", value: "machali" },
          { label: "Malloa", value: "malloa" },
          { label: "Marchihue", value: "marchihue" },
          { label: "Nancagua", value: "nancagua" },
          { label: "Navidad", value: "navidad" },
          { label: "Olivar", value: "olivar" },
          { label: "Mostazal", value: "mostazal" },
          { label: "Palmilla", value: "palmilla" },
          { label: "Paredones", value: "paredones" },
          { label: "Peralillo", value: "peralillo" },
          { label: "Peumo", value: "peumo" },
          { label: "Pichidegua", value: "pichidegua" },
          { label: "Pichilemu", value: "pichilemu" },
          { label: "Placilla", value: "placilla" },
          { label: "Pumanque", value: "pumanque" },
          { label: "Quinta de Tilcoco", value: "quinta-de-tilcoco" },
          { label: "Rancagua", value: "rancagua" },
          { label: "Rengo", value: "rengo" },
          { label: "Requínoa", value: "requinoa" },
          { label: "San Fernando", value: "san-fernando" },
          { label: "San Vicente", value: "san-vicente" },
          { label: "Santa Cruz", value: "santa-cruz" },
        ],
      },
      {
        label: "Maule",
        value: "Maule",
        subregions: [
          { label: "Cauquenes", value: "cauquenes" },
          { label: "Chanco", value: "chanco" },
          { label: "Colbún", value: "colbun" },
          { label: "Constitución", value: "constitucion" },
          { label: "Curepto", value: "curepto" },
          { label: "Curicó", value: "curico" },
          { label: "Empedrado", value: "empedrado" },
          { label: "Hualañé", value: "hualane" },
          { label: "Licantén", value: "licanten" },
          { label: "Linares", value: "linares" },
          { label: "Longaví", value: "longavi" },
          { label: "Maule", value: "maule" },
          { label: "Molina", value: "molina" },
          { label: "Parral", value: "parral" },
          { label: "Pelarco", value: "pelarco" },
          { label: "Pelluhue", value: "pelluhue" },
          { label: "Pencahue", value: "pencahue" },
          { label: "Rauco", value: "rauco" },
          { label: "Retiro", value: "retiro" },
          { label: "Romeral", value: "romeral" },
          { label: "Río Claro", value: "rio-claro" },
          { label: "Sagrada Familia", value: "sagrada-familia" },
          { label: "San Clemente", value: "san-clemente" },
          {
            label: "San Javier de Loncomilla",
            value: "san-javier-de-loncomilla",
          },
          { label: "San Rafael", value: "san-rafael" },
          { label: "Talca", value: "talca" },
          { label: "Teno", value: "teno" },
          { label: "Vichuquén", value: "vichuquen" },
          { label: "Villa Alegre", value: "villa-alegre" },
          { label: "Yerbas Buenas", value: "yerbas-buenas" },
        ],
      },
      {
        label: "Ñuble",
        value: "Ñuble",
        subregions: [
          { label: "Bulnes", value: "bulnes" },
          { label: "Chillán Viejo", value: "chillan-viejo" },
          { label: "Chillán", value: "chillan" },
          { label: "Cobquecura", value: "cobquecura" },
          { label: "Coelemu", value: "coelemu" },
          { label: "Coihueco", value: "coihueco" },
          { label: "El Carmen", value: "el-carmen" },
          { label: "Ninhue", value: "ninhue" },
          { label: "Ñiquén", value: "niquen" },
          { label: "Pemuco", value: "pemuco" },
          { label: "Pinto", value: "pinto" },
          { label: "Portezuelo", value: "portezuelo" },
          { label: "Quillón", value: "quillon" },
          { label: "Quirihue", value: "quirihue" },
          { label: "Ránquil", value: "ranquil" },
          { label: "San Carlos", value: "san-carlos" },
          { label: "San Fabián", value: "san-fabian" },
          { label: "San Ignacio", value: "san-ignacio" },
          { label: "San Nicolás", value: "san-nicolas" },
          { label: "Treguaco", value: "treguaco" },
          { label: "Yungay", value: "yungay" },
        ],
      },
      {
        label: "Biobío",
        value: "Biobio",
        subregions: [
          { label: "Alto Biobío", value: "alto-biobio" },
          { label: "Antuco", value: "antuco" },
          { label: "Arauco", value: "arauco" },
          { label: "Cabrero", value: "cabrero" },
          { label: "Cañete", value: "canete" },
          { label: "Chiguayante", value: "chiguayante" },
          { label: "Concepción", value: "concepcion" },
          { label: "Contulmo", value: "contulmo" },
          { label: "Coronel", value: "coronel" },
          { label: "Curanilahue", value: "curanilahue" },
          { label: "Florida", value: "florida" },
          { label: "Hualpén", value: "hualpen" },
          { label: "Hualqui", value: "hualqui" },
          { label: "Laja", value: "laja" },
          { label: "Lebu", value: "lebu" },
          { label: "Los Álamos", value: "los-alamos" },
          { label: "Los Ángeles", value: "los-angeles" },
          { label: "Lota", value: "lota" },
          { label: "Mulchén", value: "mulchen" },
          { label: "Nacimiento", value: "nacimiento" },
          { label: "Negrete", value: "negrete" },
          { label: "Penco", value: "penco" },
          { label: "Quilaco", value: "quilaco" },
          { label: "Quilleco", value: "quilleco" },
          { label: "San Pedro de la Paz", value: "san-pedro-de-la-paz" },
          { label: "San Rosendo", value: "san-rosendo" },
          { label: "Santa Bárbara", value: "santa-barbara" },
          { label: "Santa Juana", value: "santa-juana" },
          { label: "Talcahuano", value: "talcahuano" },
          { label: "Tirúa", value: "tirua" },
          { label: "Tomé", value: "tome" },
          { label: "Tucapel", value: "tucapel" },
          { label: "Yumbel", value: "yumbel" },
        ],
      },
      {
        label: "Araucanía",
        value: "Araucania",
        subregions: [
          { label: "Angol", value: "angol" },
          { label: "Carahue", value: "carahue" },
          { label: "Cholchol", value: "cholchol" },
          { label: "Collipulli", value: "collipulli" },
          { label: "Cunco", value: "cunco" },
          { label: "Curacautín", value: "curacautin" },
          { label: "Curarrehue", value: "curarrehue" },
          { label: "Ercilla", value: "ercilla" },
          { label: "Freire", value: "freire" },
          { label: "Galvarino", value: "galvarino" },
          { label: "Gorbea", value: "gorbea" },
          { label: "Lautaro", value: "lautaro" },
          { label: "Loncoche", value: "loncoche" },
          { label: "Lonquimay", value: "lonquimay" },
          { label: "Los Sauces", value: "los-sauces" },
          { label: "Lumaco", value: "lumaco" },
          { label: "Melipeuco", value: "melipeuco" },
          { label: "Nueva Imperial", value: "nueva-imperial" },
          { label: "Padre las Casas", value: "padre-las-casas" },
          { label: "Perquenco", value: "perquenco" },
          { label: "Pitrufquén", value: "pitrufquen" },
          { label: "Pucón", value: "pucon" },
          { label: "Purén", value: "puren" },
          { label: "Renaico", value: "renaico" },
          { label: "Saavedra", value: "saavedra" },
          { label: "Temuco", value: "temuco" },
          { label: "Teodoro Schmidt", value: "teodoro-schmidt" },
          { label: "Toltén", value: "tolten" },
          { label: "Traiguén", value: "traiguen" },
          { label: "Victoria", value: "victoria" },
          { label: "Vilcún", value: "vilcun" },
          { label: "Villarrica", value: "villarrica" },
        ],
      },
      {
        label: "Los Ríos",
        value: "Los-Rios",
        subregions: [
          { label: "Corral", value: "corral" },
          { label: "Futrono", value: "futrono" },
          { label: "La Unión", value: "la-union" },
          { label: "Lago Ranco", value: "lago-ranco" },
          { label: "Lanco", value: "lanco" },
          { label: "Los Lagos", value: "los-lagos" },
          { label: "Mariquina", value: "mariquina" },
          { label: "Máfil", value: "mafil" },
          { label: "Paillaco", value: "paillaco" },
          { label: "Panguipulli", value: "panguipulli" },
          { label: "Río Bueno", value: "rio-bueno" },
          { label: "Valdivia", value: "valdivia" },
        ],
      },
      {
        label: "Los Lagos",
        value: "Los-Lagos",
        subregions: [
          { label: "Ancud", value: "ancud" },
          { label: "Calbuco", value: "calbuco" },
          { label: "Castro", value: "castro" },
          { label: "Chaitén", value: "chaiten" },
          { label: "Chonchi", value: "chonchi" },
          { label: "Cochamó", value: "cochamo" },
          { label: "Curaco de Vélez", value: "curaco-de-velez" },
          { label: "Dalcahue", value: "dalcahue" },
          { label: "Fresia", value: "fresia" },
          { label: "Frutillar", value: "frutillar" },
          { label: "Futaleufú", value: "futaleufu" },
          { label: "Hualaihué", value: "hualaihue" },
          { label: "Llanquihue", value: "llanquihue" },
          { label: "Los Muermos", value: "los-muermos" },
          { label: "Maullín", value: "maullin" },
          { label: "Osorno", value: "osorno" },
          { label: "Palena", value: "palena" },
          { label: "Puerto Montt", value: "puerto-montt" },
          { label: "Puerto Octay", value: "puerto-octay" },
          { label: "Puerto Varas", value: "puerto-varas" },
          { label: "Puqueldón", value: "puqueldon" },
          { label: "Purranque", value: "purranque" },
          { label: "Puyehue", value: "puyehue" },
          { label: "Queilén", value: "queilen" },
          { label: "Quellón", value: "quellon" },
          { label: "Quemchi", value: "quemchi" },
          { label: "Quinchao", value: "quinchao" },
          { label: "Río Negro", value: "rio-negro" },
          { label: "San Juan de la Costa", value: "san-juan-de-la-costa" },
          { label: "San Pablo", value: "san-pablo" },
        ],
      },
      {
        label: "Aisén del Gral. Carlos Ibáñez del Campo",
        value: "Aisen-del-Gral.-Carlos-Ibañez-del-Campo",
        subregions: [
          { label: "Aysén", value: "aysen" },
          { label: "Chile Chico", value: "chile-chico" },
          { label: "Cisnes", value: "cisnes" },
          { label: "Cochrane", value: "cochrane" },
          { label: "Coyhaique", value: "coyhaique" },
          { label: "Guaitecas", value: "guaitecas" },
          { label: "Lago Verde", value: "lago-verde" },
          { label: "O’Higgins", value: "ohiggins" },
          { label: "Río Ibáñez", value: "rio-ibanez" },
          { label: "Tortel", value: "tortel" },
        ],
      },
      {
        label: "Magallanes y de la Antártica Chilena",
        value: "Magallanes-y-de-la-Antartica-Chilena",
        subregions: [
          { label: "Antártica", value: "antartica" },
          { label: "Cabo de Hornos", value: "cabo-de-hornos" },
          { label: "Laguna Blanca", value: "laguna-blanca" },
          { label: "Natales", value: "natales" },
          { label: "Porvenir", value: "porvenir" },
          { label: "Primavera", value: "primavera" },
          { label: "Punta Arenas", value: "punta-arenas" },
          { label: "Río Verde", value: "rio-verde" },
          { label: "San Gregorio", value: "san-gregorio" },
          { label: "Timaukel", value: "timaukel" },
          { label: "Torres del Paine", value: "torres-del-paine" },
        ],
      },
    ],
  },
  {
    label: "México",
    value: "Mexico",
    regions: [
      {
        label: "Aguascalientes",
        value: "Aguascalientes",
        subregions: [
          { label: "Aguascalientes", value: "Aguascalientes" },
          { label: "Asientos", value: "Asientos" },
          { label: "Calvillo", value: "Calvillo" },
          { label: "Cosio", value: "Cosio" },
          { label: "El Llano", value: "El-Llano" },
          { label: "Jesus Maria", value: "Jesus-Maria" },
          { label: "Pabellon de Arteaga", value: "Pabellon-de-Arteaga" },
          { label: "Rincon de Romos", value: "Rincon-de-Romos" },
          {
            label: "San Francisco de los Romo",
            value: "San-Francisco-de-los-Romo",
          },
          { label: "San Jose de Gracia", value: "San-Jose-de-Gracia" },
          { label: "Tepezala", value: "Tepezala" },
        ],
      },
      {
        label: "Baja California",
        value: "Baja-California",
        subregions: [
          { label: "Ensenada", value: "Ensenada" },
          { label: "Mexicali", value: "Mexicali" },
          { label: "Playas de Rosarito", value: "Playas-de-Rosarito" },
          { label: "Tecate", value: "Tecate" },
          { label: "Tijuana", value: "Tijuana" },
        ],
      },
      {
        label: "Baja California Sur",
        value: "Baja-California-Sur",
        subregions: [
          { label: "Comondu", value: "Comondu" },
          { label: "La Paz", value: "La-Paz" },
          { label: "Loreto", value: "Loreto" },
          { label: "Los Cabos", value: "Los-Cabos" },
          { label: "Mulege", value: "Mulege" },
        ],
      },
      {
        label: "Campeche",
        value: "Campeche",
        subregions: [
          { label: "Calakmul", value: "Calakmul" },
          { label: "Calkini", value: "Calkini" },
          { label: "Campeche", value: "Campeche" },
          { label: "Candelaria", value: "Candelaria" },
          { label: "Carmen", value: "Carmen" },
          { label: "Champoton", value: "Champoton" },
          { label: "Escarcega", value: "Escarcega" },
          { label: "Hecelchakan", value: "Hecelchakan" },
          { label: "Hopelchen", value: "Hopelchen" },
          { label: "Palizada", value: "Palizada" },
          { label: "Tenabo", value: "Tenabo" },
        ],
      },
      {
        label: "Coahuila",
        value: "Coahuila",
        subregions: [
          { label: "Abasolo", value: "Abasolo" },
          { label: "Acuna", value: "Acuna" },
          { label: "Allende", value: "Allende" },
          { label: "Arteaga", value: "Arteaga" },
          { label: "Candela", value: "Candela" },
          { label: "Castanos", value: "Castanos" },
          { label: "Cuatro Cienegas", value: "Cuatro-Cienegas" },
          { label: "Francisco I. Madero", value: "Francisco-I.-Madero" },
          { label: "Frontera", value: "Frontera" },
          { label: "General Cepeda", value: "General Cepeda" },
          { label: "Guerrero", value: "Guerrero" },
          { label: "Hidalgo", value: "Hidalgo" },
          { label: "Jimenez", value: "Jimenez" },
          { label: "Juarez", value: "Juarez" },
          { label: "Lamadrid", value: "Lamadrid" },
          { label: "Matamoros", value: "Matamoros" },
          { label: "Monclova", value: "Monclova" },
          { label: "Morelos", value: "Morelos" },
          { label: "Muzquiz", value: "Muzquiz" },
          { label: "Nadadores", value: "Nadadores" },
          { label: "Nava", value: "Nava" },
          { label: "Ocampo", value: "Ocampo" },
          { label: "Parras", value: "Parras" },
          { label: "Piedras Negras", value: "Piedras-Negras" },
          { label: "Progreso", value: "Progreso" },
          { label: "Ramos Arizpe", value: "Ramos-Arizpe" },
          { label: "Sabinas", value: "Sabinas" },
          { label: "Sacramento", value: "Sacramento" },
          { label: "Saltillo", value: "Saltillo" },
          { label: "San Buenaventura", value: "San-Buenaventura" },
          { label: "San Juan de Sabinas", value: "San-Juan-de-Sabinas" },
          { label: "San Pedro", value: "San-Pedro" },
          { label: "Sierra Mojada", value: "Sierra-Mojada" },
          { label: "Torreon", value: "Torreon" },
          { label: "Viesca", value: "Viesca" },
          { label: "Villa Union", value: "Villa-Union" },
          { label: "Zaragoza", value: "Zaragoza" },
        ],
      },
      {
        label: "Colima",
        value: "Colima",
        subregions: [
          { label: "Armeria", value: "Armeria" },
          { label: "Colima", value: "Colima" },
          { label: "Comala", value: "Comala" },
          { label: "Coquimatlan", value: "Coquimatlan" },
          { label: "Cuauhtemoc", value: "Cuauhtemoc" },
          { label: "Ixtlahuacan", value: "Ixtlahuacan" },
          { label: "Manzanillo", value: "Manzanillo" },
          { label: "Minatitlan", value: "Minatitlan" },
          { label: "Tecoman", value: "Tecoman" },
          { label: "Villa de Alvarez", value: "Villa-de-Alvarez" },
        ],
      },
      {
        label: "Chiapas",
        value: "Chiapas",
        subregions: [
          { label: "Acacoyagua", value: "Acacoyagua" },
          { label: "Acala", value: "Acala" },
          { label: "Acapetahua", value: "Acapetahua" },
          { label: "Aldama", value: "Aldama" },
          { label: "Altamirano", value: "Altamirano" },
          {
            label: "Amatenango de la Frontera",
            value: "Amatenango-de-la-Frontera",
          },
          { label: "Amatenango del Valle", value: "Amatenango-del-Valle" },
          { label: "Amatan", value: "Amatan" },
          { label: "Angel Albino Corzo", value: "Angel-Albino-Corzo" },
          { label: "Arriaga", value: "Arriaga" },
          { label: "Bejucal de Ocampo", value: "Bejucal-de-Ocampo" },
          { label: "Bella Vista", value: "Bella-Vista" },
          {
            label: "Benemerito de las Americas",
            value: "Benemerito-de-las-Americas",
          },
          { label: "Berriozabal", value: "Berriozabal" },
          { label: "Bochil", value: "Bochil" },
          { label: "Cacahoatan", value: "Cacahoatan" },
          {
            label: "Capitan Luis Angel Vidal",
            value: "Capitan-Luis-Angel-Vidal",
          },
          { label: "Catazaja", value: "Catazaja" },
          { label: "Chalchihuitan", value: "Chalchihuitan" },
          { label: "Chamula", value: "Chamula" },
          { label: "Chanal", value: "Chanal" },
          { label: "Chapultenango", value: "Chapultenango" },
          { label: "Chenalho", value: "Chenalho" },
          { label: "Chiapa de Corzo", value: "Chiapa de Corzo" },
          { label: "Chiapilla", value: "Chiapilla" },
          { label: "Chicoasen", value: "Chicoasen" },
          { label: "Chicomuselo", value: "Chicomuselo" },
          { label: "Chilon", value: "Chilon" },
          { label: "Cintalapa", value: "Cintalapa" },
          { label: "Coapilla", value: "Coapilla" },
          { label: "Comitan de Dominguez", value: "Comitan-de-Dominguez" },
          { label: "Copainala", value: "Copainala" },
          { label: "El Bosque", value: "El-Bosque" },
          { label: "El Parral", value: "El-Parral" },
          { label: "El Porvenir", value: "El-Porvenir" },
          { label: "Emiliano Zapata", value: "Emiliano-Zapata" },
          { label: "Escuintla", value: "Escuintla" },
          { label: "Francisco Leon", value: "Francisco-Leon" },
          { label: "Frontera Comalapa", value: "Frontera-Comalapa" },
          { label: "Frontera Hidalgo", value: "Frontera-Hidalgo" },
          { label: "Huehuetan", value: "Huehuetan" },
          { label: "Huitiupan", value: "Huitiupan" },
          { label: "Huixtla", value: "Huixtla" },
          { label: "Huixtan", value: "Huixtan" },
          { label: "Ixhuatan", value: "Ixhuatan" },
          { label: "Ixtacomitan", value: "Ixtacomitan" },
          { label: "Ixtapa", value: "Ixtapa" },
          { label: "Ixtapangajoya", value: "Ixtapangajoya" },
          { label: "Jiquipilas", value: "Jiquipilas" },
          { label: "Jitotol", value: "Jitotol" },
          { label: "Juarez", value: "Juarez" },
          { label: "La Concordia", value: "La-Concordia" },
          { label: "La Grandeza", value: "La-Grandeza" },
          { label: "La Independencia", value: "La-Independencia" },
          { label: "La Libertad", value: "La-Libertad" },
          { label: "La Trinitaria", value: "La-Trinitaria" },
          { label: "Larrainzar", value: "Larrainzar" },
          { label: "Las Margaritas", value: "Las-Margaritas" },
          { label: "Las Rosas", value: "Las-Rosas" },
          { label: "Mapastepec", value: "Mapastepec" },
          { label: "Maravilla Tenejapa", value: "Maravilla-Tenejapa" },
          { label: "Marques de Comillas", value: "Marques-de-Comillas" },
          { label: "Mazapa de Madero", value: "Mazapa-de-Madero" },
          { label: "Mazatan", value: "Mazatan" },
          { label: "Metapa", value: "Metapa" },
          { label: "Mezcalapa", value: "Mezcalapa" },
          { label: "Mitontic", value: "Mitontic" },
          {
            label: "Montecristo de Guerrero",
            value: "Montecristo-de-Guerrero",
          },
          { label: "Motozintla", value: "Motozintla" },
          { label: "Nicolas Ruiz", value: "Nicolas-Ruiz" },
          { label: "Ocosingo", value: "Ocosingo" },
          { label: "Ocotepec", value: "Ocotepec" },
          {
            label: "Ocozocoautla de Espinosa",
            value: "Ocozocoautla-de-Espinosa",
          },
          { label: "Ostuacan", value: "Ostuacan" },
          { label: "Osumacinta", value: "Osumacinta" },
          { label: "Oxchuc", value: "Oxchuc" },
          { label: "Palenque", value: "Palenque" },
          { label: "Pantelho", value: "Pantelho" },
          { label: "Pantepec", value: "Pantepec" },
          { label: "Pichucalco", value: "Pichucalco" },
          { label: "Pijijiapan", value: "Pijijiapan" },
          {
            label: "Pueblo Nuevo Solistahuacan",
            value: "Pueblo-Nuevo-Solistahuacan",
          },
          { label: "Rayon", value: "Rayon" },
          { label: "Reforma", value: "Reforma" },
          {
            label: "Rincon Chamula San Pedro",
            value: "Rincon-Chamula-San-Pedro",
          },
          { label: "Sabanilla", value: "Sabanilla" },
          { label: "Salto de Agua", value: "Salto-de-Agua" },
          { label: "San Andres Duraznal", value: "San-Andres-Duraznal" },
          {
            label: "San Cristobal de las Casas",
            value: "San-Cristobal-de-las-Casas",
          },
          { label: "San Fernando", value: "San-Fernando" },
          { label: "San Juan Cancuc", value: "San-Juan-Cancuc" },
          { label: "San Lucas", value: "San-Lucas" },
          { label: "Santiago el Pinar", value: "Santiago-el-Pinar" },
          { label: "Siltepec", value: "Siltepec" },
          { label: "Simojovel", value: "Simojovel" },
          { label: "Sitala", value: "Sitala" },
          { label: "Socoltenango", value: "Socoltenango" },
          { label: "Solosuchiapa", value: "Solosuchiapa" },
          { label: "Soyalo", value: "Soyalo" },
          { label: "Suchiapa", value: "Suchiapa" },
          { label: "Suchiate", value: "Suchiate" },
          { label: "Sunuapa", value: "Sunuapa" },
          { label: "Tapachula", value: "Tapachula" },
          { label: "Tapalapa", value: "Tapalapa" },
          { label: "Tapilula", value: "Tapilula" },
          { label: "Tecpatan", value: "Tecpatan" },
          { label: "Tenejapa", value: "Tenejapa" },
          { label: "Teopisca", value: "Teopisca" },
          { label: "Tila", value: "Tila" },
          { label: "Tonala", value: "Tonala" },
          { label: "Totolapa", value: "Totolapa" },
          { label: "Tumbala", value: "Tumbala" },
          { label: "Tuxtla Chico", value: "Tuxtla-Chico" },
          { label: "Tuxtla Gutierrez", value: "Tuxtla Gutierrez" },
          { label: "Tuzantan", value: "Tuzantan" },
          { label: "Tzimol", value: "Tzimol" },
          { label: "Union Juarez", value: "Union-Juarez" },
          { label: "Venustiano Carranza", value: "Venustiano-Carranza" },
          { label: "Villa Comaltitlan", value: "Villa-Comaltitlan" },
          { label: "Villa Corzo", value: "Villa-Corzo" },
          { label: "Villaflores", value: "Villaflores" },
          { label: "Yajalon", value: "Yajalon" },
          { label: "Zinacantan", value: "Zinacantan" },
        ],
      },
      {
        label: "Chihuahua",
        value: "Chihuahua",
        subregions: [
          { label: "Ahumada", value: "Ahumada" },
          { label: "Aldama", value: "Aldama" },
          { label: "Allende", value: "Allende" },
          { label: "Aquiles Serdan", value: "Aquiles Serdan" },
          { label: "Ascension", value: "Ascension" },
          { label: "Bachiniva", value: "Bachiniva" },
          { label: "Balleza", value: "Balleza" },
          {
            label: "Batopilas de Manuel Gomez Morin",
            value: "Batopilas-de-Manuel-Gomez-Morin",
          },
          { label: "Bocoyna", value: "Bocoyna" },
          { label: "Buenaventura", value: "Buenaventura" },
          { label: "Camargo", value: "Camargo" },
          { label: "Carichi", value: "Carichi" },
          { label: "Casas Grandes", value: "Casas-Grandes" },
          { label: "Chihuahua", value: "Chihuahua" },
          { label: "Chinipas", value: "Chinipas" },
          { label: "Coronado", value: "Coronado" },
          { label: "Coyame del Sotol", value: "Coyame-del-Sotol" },
          { label: "Cuauhtemoc", value: "Cuauhtemoc" },
          { label: "Cusihuiriachi", value: "Cusihuiriachi" },
          { label: "Delicias", value: "Delicias" },
          {
            label: "Dr. Belisario Dominguez",
            value: "Dr.-Belisario-Dominguez",
          },
          { label: "El Tule", value: "El-Tule" },
          { label: "Galeana", value: "Galeana" },
          { label: "Gran Morelos", value: "Gran-Morelos" },
          { label: "Guachochi", value: "Guachochi" },
          { label: "Guadalupe y Calvo", value: "Guadalupe-y-Calvo" },
          { label: "Guadalupe", value: "Guadalupe" },
          { label: "Guazapares", value: "Guazapares" },
          { label: "Guerrero", value: "Guerrero" },
          { label: "Gomez Farias", value: "Gomez-Farias" },
          { label: "Hidalgo del Parral", value: "Hidalgo-del-Parral" },
          { label: "Huejotitan", value: "Huejotitan" },
          { label: "Ignacio Zaragoza", value: "Ignacio-Zaragoza" },
          { label: "Janos", value: "Janos" },
          { label: "Jimenez", value: "Jimenez" },
          { label: "Julimes", value: "Julimes" },
          { label: "Juarez", value: "Juarez" },
          { label: "La Cruz", value: "La-Cruz" },
          { label: "Lopez", value: "Lopez" },
          { label: "Madera", value: "Madera" },
          { label: "Maguarichi", value: "Maguarichi" },
          { label: "Manuel Benavides", value: "Manuel-Benavides" },
          { label: "Matachi", value: "Matachi" },
          { label: "Matamoros", value: "Matamoros" },
          { label: "Meoqui", value: "Meoqui" },
          { label: "Morelos", value: "Morelos" },
          { label: "Moris", value: "Moris" },
          { label: "Namiquipa", value: "Namiquipa" },
          { label: "Nonoava", value: "Nonoava" },
          { label: "Nuevo Casas Grandes", value: "Nuevo-Casas-Grandes" },
          { label: "Ocampo", value: "Ocampo" },
          { label: "Ojinaga", value: "Ojinaga" },
          { label: "Praxedis G. Guerrero", value: "Praxedis-G.-Guerrero" },
          { label: "Riva Palacio", value: "Riva Palacio" },
          { label: "Rosales", value: "Rosales" },
          { label: "Rosario", value: "Rosario" },
          {
            label: "San Francisco de Borja",
            value: "San-Francisco-de-Borja",
          },
          {
            label: "San Francisco de Conchos",
            value: "San-Francisco-de-Conchos",
          },
          {
            label: "San Francisco del Oro",
            value: "San-Francisco-del-Oro",
          },
          { label: "Santa Barbara", value: "Santa-Barbara" },
          { label: "Santa Isabel", value: "Santa-Isabel" },
          { label: "Satevo", value: "Satevo" },
          { label: "Saucillo", value: "Saucillo" },
          { label: "Temosachic", value: "Temosachic" },
          { label: "Urique", value: "Urique" },
          { label: "Uruachi", value: "Uruachi" },
          { label: "Valle de Zaragoza", value: "Valle-de-Zaragoza" },
        ],
      },
      {
        label: "Ciudad de México",
        value: "Ciudad-de-Mexico",
        subregions: [
          { label: "Alvaro Obregon", value: "Alvaro-Obregon" },
          { label: "Azcapotzalco", value: "Azcapotzalco" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          { label: "Coyoacan", value: "Coyoacan" },
          {
            label: "Cuajimalpa de Morelos",
            value: "Cuajimalpa-de-Morelos",
          },
          { label: "Cuauhtemoc", value: "Cuauhtemoc" },
          { label: "Gustavo A. Madero", value: "Gustavo-A.-Madero" },
          { label: "Iztacalco", value: "Iztacalco" },
          { label: "Iztapalapa", value: "Iztapalapa" },
          {
            label: "La Magdalena Contreras",
            value: "La-Magdalena-Contreras",
          },
          { label: "Miguel Hidalgo", value: "Miguel-Hidalgo" },
          { label: "Milpa Alta", value: "Milpa-Alta" },
          { label: "Tlalpan", value: "Tlalpan" },
          { label: "Tlahuac", value: "Tlahuac" },
          { label: "Venustiano Carranza", value: "Venustiano-Carranza" },
          { label: "Xochimilco", value: "Xochimilco" },
        ],
      },
      {
        label: "Durango",
        value: "Durango",
        subregions: [
          { label: "Canatlan", value: "Canatlan" },
          { label: "Canelas", value: "Canelas" },
          { label: "Coneto de Comonfort", value: "Coneto-de-Comonfort" },
          { label: "Cuencame", value: "Cuencame" },
          { label: "Durango", value: "Durango" },
          { label: "El Oro", value: "El-Oro" },
          {
            label: "General Simon Bolivar",
            value: "General-Simon-Bolivar",
          },
          { label: "Gomez Palacio", value: "Gomez-Palacio" },
          { label: "Guadalupe Victoria", value: "Guadalupe-Victoria" },
          { label: "Guanacevi", value: "Guanacevi" },
          { label: "Hidalgo", value: "Hidalgo" },
          { label: "Inde", value: "Inde" },
          { label: "Lerdo", value: "Lerdo" },
          { label: "Mapimi", value: "Mapimi" },
          { label: "Mezquital", value: "Mezquital" },
          { label: "Nazas", value: "Nazas" },
          { label: "Nombre de Dios", value: "Nombre-de-Dios" },
          { label: "Nuevo Ideal", value: "Nuevo-Ideal" },
          { label: "Ocampo", value: "Ocampo" },
          { label: "Otaez", value: "Otaez" },
          { label: "Panuco de Coronado", value: "Panuco-de-Coronado" },
          { label: "Penon Blanco", value: "Penon-Blanco" },
          { label: "Poanas", value: "Poanas" },
          { label: "Pueblo Nuevo", value: "Pueblo-Nuevo" },
          { label: "Rodeo", value: "Rodeo" },
          { label: "San Bernardo", value: "San-Bernardo" },
          { label: "San Dimas", value: "San-Dimas" },
          {
            label: "San Juan de Guadalupe",
            value: "San-Juan-de-Guadalupe",
          },
          { label: "San Juan del Rio", value: "San-Juan-del-Rio" },
          { label: "San Luis del Cordero", value: "San-Luis-del-Cordero" },
          { label: "San Pedro del Gallo", value: "San-Pedro-del-Gallo" },
          { label: "Santa Clara", value: "Santa-Clara" },
          { label: "Santiago Papasquiaro", value: "Santiago-Papasquiaro" },
          { label: "Suchil", value: "Suchil" },
          { label: "Tamazula", value: "Tamazula" },
          { label: "Tepehuanes", value: "Tepehuanes" },
          { label: "Tlahualilo", value: "Tlahualilo" },
          { label: "Topia", value: "Topia" },
          { label: "Vicente Guerrero", value: "Vicente Guerrero" },
        ],
      },
      {
        label: "Guanajuato",
        value: "Guanajuato",
        subregions: [
          { label: "Abasolo", value: "Abasolo" },
          { label: "Acambaro", value: "Acambaro" },
          { label: "Apaseo el Alto", value: "Apaseo-el-Alto" },
          { label: "Apaseo el Grande", value: "Apaseo-el-Grande" },
          { label: "Atarjea", value: "Atarjea" },
          { label: "Celaya", value: "Celaya" },
          { label: "Comonfort", value: "Comonfort" },
          { label: "Coroneo", value: "Coroneo" },
          { label: "Cortazar", value: "Cortazar" },
          { label: "Cueramaro", value: "Cueramaro" },
          { label: "Doctor Mora", value: "Doctor-Mora" },
          {
            label: "Dolores Hidalgo Cuna de la Independencia Nacional",
            value: "Dolores-Hidalgo-Cuna-de-la-Independencia-Nacional",
          },
          { label: "Guanajuato", value: "Guanajuato" },
          { label: "Huanimaro", value: "Huanimaro" },
          { label: "Irapuato", value: "Irapuato" },
          { label: "Jaral del Progreso", value: "Jaral-del-Progreso" },
          { label: "Jerecuaro", value: "Jerecuaro" },
          { label: "Leon", value: "Leon" },
          { label: "Manuel Doblado", value: "Manuel-Doblado" },
          { label: "Moroleon", value: "Moroleon" },
          { label: "Penjamo", value: "Penjamo" },
          { label: "Pueblo Nuevo", value: "Pueblo-Nuevo" },
          { label: "Purisima del Rincon", value: "Purisima-del-Rincon" },
          { label: "Romita", value: "Romita" },
          { label: "Salamanca", value: "Salamanca" },
          { label: "Salvatierra", value: "Salvatierra" },
          {
            label: "San Diego de la Union",
            value: "San-Diego-de-la-Union",
          },
          { label: "San Felipe", value: "San-Felipe" },
          {
            label: "San Francisco del Rincon",
            value: "San-Francisco-del-Rincon",
          },
          { label: "San Jose Iturbide", value: "San-Jose-Iturbide" },
          { label: "San Luis de la Paz", value: "San-Luis-de-la-Paz" },
          {
            label: "San Miguel de Allende",
            value: "San-Miguel-de-Allende",
          },
          { label: "Santa Catarina", value: "Santa-Catarina" },
          {
            label: "Santa Cruz de Juventino Rosas",
            value: "Santa-Cruz-de-Juventino-Rosas",
          },
          { label: "Santiago Maravatio", value: "Santiago-Maravatio" },
          { label: "Silao de la Victoria", value: "Silao-de-la-Victoria" },
          { label: "Tarandacuao", value: "Tarandacuao" },
          { label: "Tarimoro", value: "Tarimoro" },
          { label: "Tierra Blanca", value: "Tierra-Blanca" },
          { label: "Uriangato", value: "Uriangato" },
          { label: "Valle de Santiago", value: "Valle-de-Santiago" },
          { label: "Victoria", value: "Victoria" },
          { label: "Villagran", value: "Villagran" },
          { label: "Xichu", value: "Xichu" },
          { label: "ddsss", value: "Yuriria" },
        ],
      },
      {
        label: "Guerrero",
        value: "Guerrero",
        subregions: [
          { label: "Acapulco de Juarez", value: "Acapulco-de-Juarez" },
          { label: "Acatepec", value: "Acatepec" },
          { label: "Ahuacuotzingo", value: "Ahuacuotzingo" },
          {
            label: "Ajuchitlan del Progreso",
            value: "Ajuchitlan-del-Progreso",
          },
          {
            label: "Alcozauca de Guerrero",
            value: "Alcozauca-de-Guerrero",
          },
          { label: "Alpoyeca", value: "Alpoyeca" },
          { label: "Apaxtla", value: "Apaxtla" },
          { label: "Arcelia", value: "Arcelia" },
          { label: "Atenango del Rio", value: "Atenango-del-Rio" },
          {
            label: "Atlamajalcingo del Monte",
            value: "Atlamajalcingo-del-Monte",
          },
          { label: "Atlixtac", value: "Atlixtac" },
          { label: "Atoyac de Alvarez", value: "Atoyac-de-Alvarez" },
          { label: "Ayutla de los Libres", value: "Ayutla-de-los-Libres" },
          { label: "Azoyu", value: "Azoyu" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          {
            label: "Buenavista de Cuellar",
            value: "Buenavista-de-Cuellar",
          },
          { label: "Chilapa de Alvarez", value: "Chilapa-de-Alvarez" },
          {
            label: "Chilpancingo de los Bravo",
            value: "Chilpancingo-de-los-Bravo",
          },
          {
            label: "Coahuayutla de Jose Maria Izazaga",
            value: "Coahuayutla-de-Jose-Maria-Izazaga",
          },
          { label: "Cochoapa el Grande", value: "Cochoapa-el-Grande" },
          { label: "Cocula", value: "Cocula" },
          { label: "Copala", value: "Copala" },
          { label: "Copalillo", value: "Copalillo" },
          { label: "Copanatoyac", value: "Copanatoyac" },
          { label: "Coyuca de Benitez", value: "Coyuca-de-Benitez" },
          { label: "Coyuca de Catalan", value: "Coyuca-de-Catalan" },
          { label: "Cuajinicuilapa", value: "Cuajinicuilapa" },
          { label: "Cualac", value: "Cualac" },
          { label: "Cuautepec", value: "Cuautepec" },
          {
            label: "Cuetzala del Progreso",
            value: "Cuetzala-del-Progreso",
          },
          { label: "Cutzamala de Pinzon", value: "Cutzamala-de-Pinzon" },
          { label: "Eduardo Neri", value: "Eduardo-Neri" },
          { label: "Florencio Villarreal", value: "Florencio-Villarreal" },
          {
            label: "General Canuto A. Neri",
            value: "General-Canuto-A.-Neri",
          },
          {
            label: "General Heliodoro Castillo",
            value: "General-Heliodoro-Castillo",
          },
          { label: "Huamuxtitlan", value: "Huamuxtitlan" },
          {
            label: "Huitzuco de los Figueroa",
            value: "Huitzuco-de-los-Figueroa",
          },
          {
            label: "Iguala de la Independencia",
            value: "Iguala-de-la-Independencia",
          },
          { label: "Igualapa", value: "Igualapa" },
          { label: "Iliatenco", value: "Iliatenco" },
          {
            label: "Ixcateopan de Cuauhtemoc",
            value: "Ixcateopan-de-Cuauhtemoc",
          },
          {
            label: "Jose Joaquin de Herrera",
            value: "Jose-Joaquin-de-Herrera",
          },
          { label: "Juan R. Escudero", value: "Juan-R.-Escudero" },
          { label: "Juchitan", value: "Juchitan" },
          { label: "Leonardo Bravo", value: "Leonardo-Bravo" },
          { label: "Malinaltepec", value: "Malinaltepec" },
          { label: "Marquelia", value: "Marquelia" },
          { label: "Martir de Cuilapan", value: "Martir de Cuilapan" },
          { label: "Metlatonoc", value: "Metlatonoc" },
          { label: "Mochitlan", value: "Mochitlan" },
          { label: "Olinala", value: "Olinala" },
          { label: "Ometepec", value: "Ometepec" },
          {
            label: "Pedro Ascencio Alquisiras",
            value: "Pedro-Ascencio-Alquisiras",
          },
          { label: "Petatlan", value: "Petatlan" },
          { label: "Pilcaya", value: "Pilcaya" },
          { label: "Pungarabato", value: "Pungarabato" },
          { label: "Quechultenango", value: "Quechultenango" },
          { label: "San Luis Acatlan", value: "San-Luis-Acatlan" },
          { label: "San Marcos", value: "San-Marcos" },
          { label: "San Miguel Totolapan", value: "San-Miguel-Totolapan" },
          { label: "Taxco de Alarcon", value: "Taxco-de-Alarcon" },
          { label: "Tecoanapa", value: "Tecoanapa" },
          { label: "Tecpan de Galeana", value: "Tecpan-de-Galeana" },
          { label: "Teloloapan", value: "Teloloapan" },
          {
            label: "Tepecoacuilco de Trujano",
            value: "Tepecoacuilco-de-Trujano",
          },
          { label: "Tetipac", value: "Tetipac" },
          { label: "Tixtla de Guerrero", value: "Tixtla-de-Guerrero" },
          { label: "Tlacoachistlahuaca", value: "Tlacoachistlahuaca" },
          { label: "Tlacoapa", value: "Tlacoapa" },
          { label: "Tlalchapa", value: "Tlalchapa" },
          {
            label: "Tlalixtaquilla de Maldonado",
            value: "Tlalixtaquilla-de-Maldonado",
          },
          { label: "Tlapa de Comonfort", value: "Tlapa-de-Comonfort" },
          { label: "Tlapehuala", value: "Tlapehuala" },
          { label: "Xalpatlahuac", value: "Xalpatlahuac" },
          { label: "Xochihuehuetlan", value: "Xochihuehuetlan" },
          { label: "Xochistlahuaca", value: "Xochistlahuaca" },
          { label: "Zapotitlan Tablas", value: "Zapotitlan-Tablas" },
          {
            label: "Zihuatanejo de Azueta",
            value: "Zihuatanejo-de-Azueta",
          },
          { label: "Zirandaro", value: "Zirandaro" },
          { label: "Zitlala", value: "Zitlala" },
        ],
      },
      {
        label: "Hidalgo",
        value: "Hidalgo",
        subregions: [
          { label: "Acatlan", value: "Acatlan" },
          { label: "Acaxochitlan", value: "Acaxochitlan" },
          { label: "Actopan", value: "Actopan" },
          {
            label: "Agua Blanca de Iturbide",
            value: "Agua-Blanca-de-Iturbide",
          },
          { label: "Ajacuba", value: "Ajacuba" },
          { label: "Alfajayucan", value: "Alfajayucan" },
          { label: "Almoloya", value: "Almoloya" },
          { label: "Apan", value: "Apan" },
          { label: "Atitalaquia", value: "Atitalaquia" },
          { label: "Atlapexco", value: "Atlapexco" },
          { label: "Atotonilco de Tula", value: "Atotonilco-de-Tula" },
          { label: "Atotonilco el Grande", value: "Atotonilco-el-Grande" },
          { label: "Calnali", value: "Calnali" },
          { label: "Cardonal", value: "Cardonal" },
          { label: "Chapantongo", value: "Chapantongo" },
          { label: "Chapulhuacan", value: "Chapulhuacan" },
          { label: "Chilcuautla", value: "Chilcuautla" },
          {
            label: "Cuautepec de Hinojosa",
            value: "Cuautepec-de-Hinojosa",
          },
          { label: "El Arenal", value: "El-Arenal" },
          { label: "Eloxochitlan", value: "Eloxochitlan" },
          { label: "Emiliano Zapata", value: "Emiliano-Zapata" },
          { label: "Epazoyucan", value: "Epazoyucan" },
          { label: "Francisco I. Madero", value: "Francisco-I.-Madero" },
          { label: "Huasca de Ocampo", value: "Huasca-de-Ocampo" },
          { label: "Huautla", value: "Huautla" },
          { label: "Huazalingo", value: "Huazalingo" },
          { label: "Huehuetla", value: "Huehuetla" },
          { label: "Huejutla de Reyes", value: "Huejutla-de-Reyes" },
          { label: "Huichapan", value: "Huichapan" },
          { label: "Ixmiquilpan", value: "Ixmiquilpan" },
          { label: "Jacala de Ledezma", value: "Jacala-de-Ledezma" },
          { label: "Jaltocan", value: "Jaltocan" },
          { label: "Juarez Hidalgo", value: "Juarez-Hidalgo" },
          { label: "La Mision", value: "La-Mision" },
          { label: "Lolotla", value: "Lolotla" },
          { label: "Metepec", value: "Metepec" },
          { label: "Metztitlan", value: "Metztitlan" },
          {
            label: "Mineral de la Reforma",
            value: "Mineral-de-la-Reforma",
          },
          { label: "Mineral del Chico", value: "Mineral-del-Chico" },
          { label: "Mineral del Monte", value: "Mineral-del-Monte" },
          {
            label: "Mixquiahuala de Juarez",
            value: "Mixquiahuala-de-Juarez",
          },
          { label: "Molango de Escamilla", value: "Molango-de-Escamilla" },
          { label: "Nicolas Flores", value: "Nicolas-Flores" },
          { label: "Nopala de Villagran", value: "Nopala-de-Villagran" },
          { label: "Omitlan de Juarez", value: "Omitlan-de-Juarez" },
          { label: "Pachuca de Soto", value: "Pachuca-de-Soto" },
          { label: "Pacula", value: "Pacula" },
          { label: "Pisaflores", value: "Pisaflores" },
          { label: "Progreso de Obregon", value: "Progreso-de-Obregon" },
          {
            label: "San Agustin Metzquititlan",
            value: "San-Agustin-Metzquititlan",
          },
          { label: "San Agustin Tlaxiaca", value: "San-Agustin-Tlaxiaca" },
          {
            label: "San Bartolo Tutotepec",
            value: "San-Bartolo-Tutotepec",
          },
          { label: "San Felipe Orizatlan", value: "San-Felipe-Orizatlan" },
          { label: "San Salvador", value: "San-Salvador" },
          {
            label: "Santiago Tulantepec de Lugo Guerrero",
            value: "Santiago-Tulantepec-de-Lugo-Guerrero",
          },
          { label: "Santiago de Anaya", value: "Santiago-de-Anaya" },
          { label: "Singuilucan", value: "Singuilucan" },
          { label: "Tasquillo", value: "Tasquillo" },
          { label: "Tecozautla", value: "Tecozautla" },
          { label: "Tenango de Doria", value: "Tenango-de-Doria" },
          { label: "Tepeapulco", value: "Tepeapulco" },
          {
            label: "Tepehuacan de Guerrero",
            value: "Tepehuacan-de-Guerrero",
          },
          {
            label: "Tepeji del Rio de Ocampo",
            value: "Tepeji-del-Rio-de-Ocampo",
          },
          { label: "Tepetitlan", value: "Tepetitlan" },
          { label: "Tetepango", value: "Tetepango" },
          { label: "Tezontepec de Aldama", value: "Tezontepec-de-Aldama" },
          { label: "Tianguistengo", value: "Tianguistengo" },
          { label: "Tizayuca", value: "Tizayuca" },
          { label: "Tlahuelilpan", value: "Tlahuelilpan" },
          { label: "Tlahuiltepa", value: "Tlahuiltepa" },
          { label: "Tlanalapa", value: "Tlanalapa" },
          { label: "Tlanchinol", value: "Tlanchinol" },
          { label: "Tlaxcoapan", value: "Tlaxcoapan" },
          { label: "Tolcayuca", value: "Tolcayuca" },
          { label: "Tula de Allende", value: "Tula-de-Allende" },
          { label: "Tulancingo de Bravo", value: "Tulancingo-de-Bravo" },
          { label: "Villa de Tezontepec", value: "Villa-de-Tezontepec" },
          { label: "Xochiatipan", value: "Xochiatipan" },
          { label: "Xochicoatlan", value: "Xochicoatlan" },
          { label: "Yahualica", value: "Yahualica" },
          {
            label: "Zacualtipan de Angeles",
            value: "Zacualtipan-de-Angeles",
          },
          { label: "Zapotlan de Juarez", value: "Zapotlan-de-Juarez" },
          { label: "Zempoala", value: "Zempoala" },
          { label: "Zimapan", value: "Zimapan" },
        ],
      },
      {
        label: "Jalisco",
        value: "Jalisco",
        subregions: [
          { label: "Acatic", value: "Acatic" },
          { label: "Acatlan de Juarez", value: "Acatlan-de-Juarez" },
          { label: "Ahualulco de Mercado", value: "Ahualulco-de-Mercado" },
          { label: "Amacueca", value: "Amacueca" },
          { label: "Amatitan", value: "Amatitan" },
          { label: "Ameca", value: "Ameca" },
          { label: "Arandas", value: "Arandas" },
          { label: "Atemajac de Brizuela", value: "Atemajac-de-Brizuela" },
          { label: "Atenguillo", value: "Atenguillo" },
          { label: "Atotonilco el Alto", value: "Atotonilco-el-Alto" },
          { label: "Atoyac", value: "Atoyac" },
          { label: "Autlan de Navarro", value: "Autlan-de-Navarro" },
          { label: "Ayotlan", value: "Ayotlan" },
          { label: "Ayutla", value: "Ayutla" },
          { label: "Bolanos", value: "Bolanos" },
          { label: "Cabo Corrientes", value: "Cabo-Corrientes" },
          { label: "Canadas de Obregon", value: "Canadas-de-Obregon" },
          { label: "Casimiro Castillo", value: "Casimiro-Castillo" },
          { label: "Chapala", value: "Chapala" },
          { label: "Chimaltitan", value: "Chimaltitan" },
          { label: "Chiquilistlan", value: "Chiquilistlan" },
          { label: "Cihuatlan", value: "Cihuatlan" },
          { label: "Cocula", value: "Cocula" },
          { label: "Colotlan", value: "Colotlan" },
          {
            label: "Concepcion de Buenos Aires",
            value: "Concepcion-de-Buenos-Aires",
          },
          {
            label: "Cuautitlan de Garcia Barragan",
            value: "Cuautitlan-de-Garcia-Barragan",
          },
          { label: "Cuautla", value: "Cuautla" },
          { label: "Cuquio", value: "Cuquio" },
          { label: "Degollado", value: "Degollado" },
          { label: "Ejutla", value: "Ejutla" },
          { label: "El Arenal", value: "El-Arenal" },
          { label: "El Grullo", value: "El-Grullo" },
          { label: "El Limon", value: "El-Limon" },
          { label: "El Salto", value: "El-Salto" },
          { label: "Encarnacion de Diaz", value: "Encarnacion-de-Diaz" },
          { label: "Etzatlan", value: "Etzatlan" },
          { label: "Gomez Farias", value: "Gomez-Farias" },
          { label: "Guachinango", value: "Guachinango" },
          { label: "Guadalajara", value: "Guadalajara" },
          { label: "Hostotipaquillo", value: "Hostotipaquillo" },
          { label: "Huejucar", value: "Huejucar" },
          { label: "Huejuquilla el Alto", value: "Huejuquilla-el-Alto" },
          {
            label: "Ixtlahuacan de los Membrillos",
            value: "Ixtlahuacan-de-los-Membrillos",
          },
          { label: "Ixtlahuacan del Rio", value: "Ixtlahuacan-del-Rio" },
          { label: "Jalostotitlan", value: "Jalostotitlan" },
          { label: "Jamay", value: "Jamay" },
          { label: "Jesus Maria", value: "Jesus-Maria" },
          {
            label: "Jilotlan de los Dolores",
            value: "Jilotlan-de-los-Dolores",
          },
          { label: "Jocotepec", value: "Jocotepec" },
          { label: "Juanacatlan", value: "Juanacatlan" },
          { label: "Juchitlan", value: "Juchitlan" },
          { label: "La Barca", value: "La-Barca" },
          { label: "La Huerta", value: "La-Huerta" },
          {
            label: "La Manzanilla de la Paz",
            value: "La Manzanilla-de-la-Paz",
          },
          { label: "Lagos de Moreno", value: "Lagos-de-Moreno" },
          { label: "Magdalena", value: "Magdalena" },
          { label: "Mascota", value: "Mascota" },
          { label: "Mazamitla", value: "Mazamitla" },
          { label: "Mexticacan", value: "Mexticacan" },
          { label: "Mezquitic", value: "Mezquitic" },
          { label: "Mixtlan", value: "Mixtlan" },
          { label: "Ocotlan", value: "Ocotlan" },
          { label: "Ojuelos de Jalisco", value: "Ojuelos-de-Jalisco" },
          { label: "Pihuamo", value: "Pihuamo" },
          { label: "Poncitlan", value: "Poncitlan" },
          { label: "Puerto Vallarta", value: "Puerto-Vallarta" },
          { label: "Quitupan", value: "Quitupan" },
          {
            label: "San Cristobal de la Barranca",
            value: "San-Cristobal-de-la-Barranca",
          },
          {
            label: "San Diego de Alejandria",
            value: "San-Diego-de-Alejandria",
          },
          { label: "San Gabriel", value: "San-Gabriel" },
          {
            label: "San Ignacio Cerro Gordo",
            value: "San-Ignacio-Cerro-Gordo",
          },
          {
            label: "San Juan de los Lagos",
            value: "San-Juan-de-los-Lagos",
          },
          {
            label: "San Juanito de Escobedo",
            value: "San-Juanito-de-Escobedo",
          },
          { label: "San Julian", value: "San-Julian" },
          { label: "San Marcos", value: "San-Marcos" },
          { label: "San Martin Hidalgo", value: "San-Martin-Hidalgo" },
          {
            label: "San Martin de Bolanos",
            value: "San-Martin-de-Bolanos",
          },
          { label: "San Miguel el Alto", value: "San-Miguel-el-Alto" },
          {
            label: "San Pedro Tlaquepaque",
            value: "San-Pedro-Tlaquepaque",
          },
          {
            label: "San Sebastian del Oeste",
            value: "San-Sebastian-del-Oeste",
          },
          {
            label: "Santa Maria de los Angeles",
            value: "Santa-Maria-de-los-Angeles",
          },
          { label: "Santa Maria del Oro", value: "Santa-Maria-del-Oro" },
          { label: "Sayula", value: "Sayula" },
          { label: "Tala", value: "Tala" },
          { label: "Talpa de Allende", value: "Talpa-de-Allende" },
          { label: "Tamazula de Gordiano", value: "Tamazula-de-Gordiano" },
          { label: "Tapalpa", value: "Tapalpa" },
          { label: "Tecalitlan", value: "Tecalitlan" },
          {
            label: "Techaluta de Montenegro",
            value: "Techaluta-de-Montenegro",
          },
          { label: "Tecolotlan", value: "Tecolotlan" },
          { label: "Tenamaxtlan", value: "Tenamaxtlan" },
          { label: "Teocaltiche", value: "Teocaltiche" },
          {
            label: "Teocuitatlan de Corona",
            value: "Teocuitatlan-de-Corona",
          },
          {
            label: "Tepatitlan de Morelos",
            value: "Tepatitlan-de-Morelos",
          },
          { label: "Tequila", value: "Tequila" },
          { label: "Teuchitlan", value: "Teuchitlan" },
          { label: "Tizapan el Alto", value: "Tizapan-el-Alto" },
          { label: "Tlajomulco de Zuniga", value: "Tlajomulco-de-Zuniga" },
          { label: "Toliman", value: "Toliman" },
          { label: "Tomatlan", value: "Tomatlan" },
          { label: "Tonala", value: "Tonala" },
          { label: "Tonaya", value: "Tonaya" },
          { label: "Tonila", value: "Tonila" },
          { label: "Totatiche", value: "Totatiche" },
          { label: "Tototlan", value: "Tototlan" },
          { label: "Tuxcacuesco", value: "Tuxcacuesco" },
          { label: "Tuxcueca", value: "Tuxcueca" },
          { label: "Tuxpan", value: "Tuxpan" },
          { label: "Union de San Antonio", value: "Union-de-San-Antonio" },
          { label: "Union de Tula", value: "Union-de-Tula" },
          { label: "Valle de Guadalupe", value: "Valle-de-Guadalupe" },
          { label: "Valle de Juarez", value: "Valle-de-Juarez" },
          { label: "Villa Corona", value: "Villa-Corona" },
          { label: "Villa Guerrero", value: "Villa-Guerrero" },
          { label: "Villa Hidalgo", value: "Villa-Hidalgo" },
          { label: "Villa Purificacion", value: "Villa-Purificacion" },
          {
            label: "Yahualica de Gonzalez Gallo",
            value: "Yahualica-de-Gonzalez-Gallo",
          },
          { label: "Zacoalco de Torres", value: "Zacoalco-de-Torres" },
          { label: "Zapopan", value: "Zapopan" },
          { label: "Zapotiltic", value: "Zapotiltic" },
          {
            label: "Zapotitlan de Vadillo",
            value: "Zapotitlan-de-Vadillo",
          },
          { label: "Zapotlan del Rey", value: "Zapotlan-del-Rey" },
          { label: "Zapotlan el Grande", value: "Zapotlan-el-Grande" },
          { label: "Zapotlanejo", value: "Zapotlanejo" },
        ],
      },
      {
        label: "Estado de México",
        value: "Estado-de-Mexico",
        subregions: [
          {
            label: "Acambay de Ruiz Castaneda",
            value: "Acambay-de-Ruiz-Castaneda",
          },
          { label: "Acolman", value: "Acolman" },
          { label: "Aculco", value: "Aculco" },
          {
            label: "Almoloya de Alquisiras",
            value: "Almoloya-de-Alquisiras",
          },
          { label: "Almoloya de Juarez", value: "Almoloya-de-Juarez" },
          { label: "Almoloya del Rio", value: "Almoloya-del-Rio" },
          { label: "Amanalco", value: "Amanalco" },
          { label: "Amatepec", value: "Amatepec" },
          { label: "Amecameca", value: "Amecameca" },
          { label: "Apaxco", value: "Apaxco" },
          { label: "Atenco", value: "Atenco" },
          { label: "Atizapan de Zaragoza", value: "Atizapan-de-Zaragoza" },
          { label: "Atizapan", value: "Atizapan" },
          { label: "Atlacomulco", value: "Atlacomulco" },
          { label: "Atlautla", value: "Atlautla" },
          { label: "Axapusco", value: "Axapusco" },
          { label: "Ayapango", value: "Ayapango" },
          { label: "Calimaya", value: "Calimaya" },
          { label: "Capulhuac", value: "Capulhuac" },
          { label: "Chalco", value: "Chalco" },
          { label: "Chapa de Mota", value: "Chapa-de-Mota" },
          { label: "Chapultepec", value: "Chapultepec" },
          { label: "Chiautla", value: "Chiautla" },
          { label: "Chicoloapan", value: "Chicoloapan" },
          { label: "Chiconcuac", value: "Chiconcuac" },
          { label: "Chimalhuacan", value: "Chimalhuacan" },
          {
            label: "Coacalco de Berriozabal",
            value: "Coacalco-de-Berriozabal",
          },
          { label: "Coatepec Harinas", value: "Coatepec-Harinas" },
          { label: "Cocotitlan", value: "Cocotitlan" },
          { label: "Coyotepec", value: "Coyotepec" },
          { label: "Cuautitlan Izcalli", value: "Cuautitlan-Izcalli" },
          { label: "Cuautitlan", value: "Cuautitlan" },
          { label: "Donato Guerra", value: "Donato-Guerra" },
          { label: "Ecatepec de Morelos", value: "Ecatepec-de-Morelos" },
          { label: "Ecatzingo", value: "Ecatzingo" },
          { label: "El Oro", value: "El-Oro" },
          { label: "Huehuetoca", value: "Huehuetoca" },
          { label: "Hueypoxtla", value: "Hueypoxtla" },
          { label: "Huixquilucan", value: "Huixquilucan" },
          { label: "Isidro Fabela", value: "Isidro-Fabela" },
          { label: "Ixtapaluca", value: "Ixtapaluca" },
          { label: "Ixtapan de la Sal", value: "Ixtapan-de-la-Sal" },
          { label: "Ixtapan del Oro", value: "Ixtapan-del-Oro" },
          { label: "Ixtlahuaca", value: "Ixtlahuaca" },
          { label: "Jaltenco", value: "Jaltenco" },
          { label: "Jilotepec", value: "Jilotepec" },
          { label: "Jilotzingo", value: "Jilotzingo" },
          { label: "Jiquipilco", value: "Jiquipilco" },
          { label: "Jocotitlan", value: "Jocotitlan" },
          { label: "Joquicingo", value: "Joquicingo" },
          { label: "Juchitepec", value: "Juchitepec" },
          { label: "La Paz", value: "La Paz" },
          { label: "Lerma", value: "Lerma" },
          { label: "Luvianos", value: "Luvianos" },
          { label: "Malinalco", value: "Malinalco" },
          { label: "Melchor Ocampo", value: "Melchor-Ocampo" },
          { label: "Metepec", value: "Metepec" },
          { label: "Mexicaltzingo", value: "Mexicaltzingo" },
          { label: "Morelos", value: "Morelos" },
          { label: "Naucalpan de Juarez", value: "Naucalpan-de-Juarez" },
          { label: "Nextlalpan", value: "Nextlalpan" },
          { label: "Nezahualcoyotl", value: "Nezahualcoyotl" },
          { label: "Nicolas Romero", value: "Nicolas Romero" },
          { label: "Nopaltepec", value: "Nopaltepec" },
          { label: "Ocoyoacac", value: "Ocoyoacac" },
          { label: "Ocuilan", value: "Ocuilan" },
          { label: "Otumba", value: "Otumba" },
          { label: "Otzoloapan", value: "Otzoloapan" },
          { label: "Otzolotepec", value: "Otzolotepec" },
          { label: "Ozumba", value: "Ozumba" },
          { label: "Papalotla", value: "Papalotla" },
          { label: "Polotitlan", value: "Polotitlan" },
          { label: "Rayon", value: "Rayon" },
          { label: "San Antonio la Isla", value: "San-Antonio-la-Isla" },
          {
            label: "San Felipe del Progreso",
            value: "San-Felipe-del-Progreso",
          },
          { label: "San Jose del Rincon", value: "San-Jose-del-Rincon" },
          {
            label: "San Martin de las Piramides",
            value: "San-Martin-de-las-Piramides",
          },
          { label: "San Mateo Atenco", value: "San-Mateo-Atenco" },
          {
            label: "San Simon de Guerrero",
            value: "San-Simon-de-Guerrero",
          },
          { label: "Santo Tomas", value: "Santo-Tomas" },
          {
            label: "Soyaniquilpan de Juarez",
            value: "Soyaniquilpan-de-Juarez",
          },
          { label: "Sultepec", value: "Sultepec" },
          { label: "Tecamac", value: "Tecamac" },
          { label: "Tejupilco", value: "Tejupilco" },
          { label: "Temamatla", value: "Temamatla" },
          { label: "Temascalapa", value: "Temascalapa" },
          { label: "Temascalcingo", value: "Temascalcingo" },
          { label: "Temascaltepec", value: "Temascaltepec" },
          { label: "Temoaya", value: "Temoaya" },
          { label: "Tenancingo", value: "Tenancingo" },
          { label: "Tenango del Aire", value: "Tenango-del-Aire" },
          { label: "Tenango del Valle", value: "Tenango-del-Valle" },
          { label: "Teoloyucan", value: "Teoloyucan" },
          { label: "Teotihuacan", value: "Teotihuacan" },
          { label: "Tepetlaoxtoc", value: "Tepetlaoxtoc" },
          { label: "Tepetlixpa", value: "Tepetlixpa" },
          { label: "Tepotzotlan", value: "Tepotzotlan" },
          { label: "Tequixquiac", value: "Tequixquiac" },
          { label: "Texcaltitlan", value: "Texcaltitlan" },
          { label: "Texcalyacac", value: "Texcalyacac" },
          { label: "Texcoco", value: "Texcoco" },
          { label: "Tezoyuca", value: "Tezoyuca" },
          { label: "Tianguistenco", value: "Tianguistenco" },
          { label: "Timilpan", value: "Timilpan" },
          { label: "Tlalmanalco", value: "Tlalmanalco" },
          { label: "Tlalnepantla de Baz", value: "Tlalnepantla-de-Baz" },
          { label: "Tlatlaya", value: "Tlatlaya" },
          { label: "Toluca", value: "Toluca" },
          { label: "Tonanitla", value: "Tonanitla" },
          { label: "Tonatico", value: "Tonatico" },
          { label: "Tultepec", value: "Tultepec" },
          { label: "Tultitlan", value: "Tultitlan" },
          { label: "Valle de Bravo", value: "Valle-de-Bravo" },
          {
            label: "Valle de Chalco Solidaridad",
            value: "Valle-de-Chalco-Solidaridad",
          },
          { label: "Villa Guerrero", value: "Villa-Guerrero" },
          { label: "Villa Victoria", value: "Villa-Victoria" },
          { label: "Villa de Allende", value: "Villa-de-Allende" },
          { label: "Villa del Carbon", value: "Villa-del-Carbon" },
          { label: "Xalatlaco", value: "Xalatlaco" },
          { label: "Xonacatlan", value: "Xonacatlan" },
          { label: "Zacazonapan", value: "Zacazonapan" },
          { label: "Zacualpan", value: "Zacualpan" },
          { label: "Zinacantepec", value: "Zinacantepec" },
          { label: "Zumpahuacan", value: "Zumpahuacan" },
          { label: "Zumpango", value: "Zumpango" },
        ],
      },
      {
        label: "Michoacan",
        value: "Michoacan",
        subregions: [
          { label: "Acuitzio", value: "Acuitzio" },
          { label: "Aguililla", value: "Aguililla" },
          { label: "Alvaro Obregon", value: "Alvaro-Obregon" },
          { label: "Angamacutiro", value: "Angamacutiro" },
          { label: "Angangueo", value: "Angangueo" },
          { label: "Apatzingan", value: "Apatzingan" },
          { label: "Aporo", value: "Aporo" },
          { label: "Aquila", value: "Aquila" },
          { label: "Ario", value: "Ario" },
          { label: "Arteaga", value: "Arteaga" },
          { label: "Brisenas", value: "Brisenas" },
          { label: "Buenavista", value: "Buenavista" },
          { label: "Caracuaro", value: "Caracuaro" },
          { label: "Charapan", value: "Charapan" },
          { label: "Charo", value: "Charo" },
          { label: "Chavinda", value: "Chavinda" },
          { label: "Cheran", value: "Cheran" },
          { label: "Chilchota", value: "Chilchota" },
          { label: "Chinicuila", value: "Chinicuila" },
          { label: "Chucandiro", value: "Chucandiro" },
          { label: "Churintzio", value: "Churintzio" },
          { label: "Churumuco", value: "Churumuco" },
          { label: "Coahuayana", value: "Coahuayana" },
          {
            label: "Coalcoman de Vazquez Pallares",
            value: "Coalcoman-de Vazquez Pallares",
          },
          { label: "Coeneo", value: "Coeneo" },
          {
            label: "Cojumatlan de Regules",
            value: "Cojumatlan-de Regules",
          },
          { label: "Contepec", value: "Contepec" },
          { label: "Copandaro", value: "Copandaro" },
          { label: "Cotija", value: "Cotija" },
          { label: "Cuitzeo", value: "Cuitzeo" },
          { label: "Ecuandureo", value: "Ecuandureo" },
          { label: "Epitacio Huerta", value: "Epitacio-Huerta" },
          { label: "Erongaricuaro", value: "Erongaricuaro" },
          { label: "Gabriel Zamora", value: "Gabriel-Zamora" },
          { label: "Hidalgo", value: "Hidalgo" },
          { label: "Huandacareo", value: "Huandacareo" },
          { label: "Huaniqueo", value: "Huaniqueo" },
          { label: "Huetamo", value: "Huetamo" },
          { label: "Huiramba", value: "Huiramba" },
          { label: "Indaparapeo", value: "Indaparapeo" },
          { label: "Irimbo", value: "Irimbo" },
          { label: "Ixtlan", value: "Ixtlan" },
          { label: "Jacona", value: "Jacona" },
          { label: "Jimenez", value: "Jimenez" },
          { label: "Jiquilpan", value: "Jiquilpan" },
          { label: "Jose Sixto Verduzco", value: "Jose-Sixto Verduzco" },
          { label: "Juarez", value: "Juarez" },
          { label: "Jungapeo", value: "Jungapeo" },
          { label: "La Huacana", value: "La-Huacana" },
          { label: "La Piedad", value: "La-Piedad" },
          { label: "Lagunillas", value: "Lagunillas" },
          { label: "Lazaro Cardenas", value: "Lazaro-Cardenas" },
          { label: "Los Reyes", value: "Los-Reyes" },
          { label: "Madero", value: "Madero" },
          { label: "Maravatio", value: "Maravatio" },
          { label: "Marcos Castellanos", value: "Marcos-Castellanos" },
          { label: "Morelia", value: "Morelia" },
          { label: "Morelos", value: "Morelos" },
          { label: "Mugica", value: "Mugica" },
          { label: "Nahuatzen", value: "Nahuatzen" },
          { label: "Nocupetaro", value: "Nocupetaro" },
          {
            label: "Nuevo Parangaricutiro",
            value: "Nuevo-Parangaricutiro",
          },
          { label: "Nuevo Urecho", value: "Nuevo-Urecho" },
          { label: "Numaran", value: "Numaran" },
          { label: "Ocampo", value: "Ocampo" },
          { label: "Pajacuaran", value: "Pajacuaran" },
          { label: "Panindicuaro", value: "Panindicuaro" },
          { label: "Paracho", value: "Paracho" },
          { label: "Paracuaro", value: "Paracuaro" },
          { label: "Patzcuaro", value: "Patzcuaro" },
          { label: "Penjamillo", value: "Penjamillo" },
          { label: "Periban", value: "Periban" },
          { label: "Purepero", value: "Purepero" },
          { label: "Puruandiro", value: "Puruandiro" },
          { label: "Querendaro", value: "Querendaro" },
          { label: "Quiroga", value: "Quiroga" },
          { label: "Sahuayo", value: "Sahuayo" },
          { label: "Salvador Escalante", value: "Salvador-Escalante" },
          { label: "San Lucas", value: "San-Lucas" },
          { label: "Santa Ana Maya", value: "Santa-Ana Maya" },
          { label: "Senguio", value: "Senguio" },
          { label: "Susupuato", value: "Susupuato" },
          { label: "Tacambaro", value: "Tacambaro" },
          { label: "Tancitaro", value: "Tancitaro" },
          { label: "Tangamandapio", value: "Tangamandapio" },
          { label: "Tangancicuaro", value: "Tangancicuaro" },
          { label: "Tanhuato", value: "Tanhuato" },
          { label: "Taretan", value: "Taretan" },
          { label: "Tarimbaro", value: "Tarimbaro" },
          { label: "Tepalcatepec", value: "Tepalcatepec" },
          { label: "Tingambato", value: "Tingambato" },
          { label: "Tinguindin", value: "Tinguindin" },
          {
            label: "Tiquicheo de Nicolas Romero",
            value: "Tiquicheo-de Nicolas Romero",
          },
          { label: "Tlalpujahua", value: "Tlalpujahua" },
          { label: "Tlazazalca", value: "Tlazazalca" },
          { label: "Tocumbo", value: "Tocumbo" },
          { label: "Tumbiscatio", value: "Tumbiscatio" },
          { label: "Turicato", value: "Turicato" },
          { label: "Tuxpan", value: "Tuxpan" },
          { label: "Tuzantla", value: "Tuzantla" },
          { label: "Tzintzuntzan", value: "Tzintzuntzan" },
          { label: "Tzitzio", value: "Tzitzio" },
          { label: "Uruapan", value: "Uruapan" },
          { label: "Venustiano Carranza", value: "Venustiano-Carranza" },
          { label: "Villamar", value: "Villamar" },
          { label: "Vista Hermosa", value: "Vista-Hermosa" },
          { label: "Yurecuaro", value: "Yurecuaro" },
          { label: "Zacapu", value: "Zacapu" },
          { label: "Zamora", value: "Zamora" },
          { label: "Zinaparo", value: "Zinaparo" },
          { label: "Zinapecuaro", value: "Zinapecuaro" },
          { label: "Ziracuaretiro", value: "Ziracuaretiro" },
          { label: "Zitacuaro", value: "Zitacuaro" },
        ],
      },
      {
        label: "Morelos",
        value: "Morelos",
        subregions: [
          { label: "Amacuzac", value: "Amacuzac" },
          { label: "Atlatlahucan", value: "Atlatlahucan" },
          { label: "Axochiapan", value: "Axochiapan" },
          { label: "Ayala", value: "Ayala" },
          { label: "Coatlan del Rio", value: "Coatlan-del-Rio" },
          { label: "Cuautla", value: "Cuautla" },
          { label: "Cuernavaca", value: "Cuernavaca" },
          { label: "Emiliano Zapata", value: "Emiliano-Zapata" },
          { label: "Huitzilac", value: "Huitzilac" },
          { label: "Jantetelco", value: "Jantetelco" },
          { label: "Jiutepec", value: "Jiutepec" },
          { label: "Jojutla", value: "Jojutla" },
          {
            label: "Jonacatepec de Leandro Valle",
            value: "Jonacatepec-de Leandro Valle",
          },
          { label: "Mazatepec", value: "Mazatepec" },
          { label: "Miacatlan", value: "Miacatlan" },
          { label: "Ocuituco", value: "Ocuituco" },
          { label: "Puente de Ixtla", value: "Puente-de-Ixtla" },
          { label: "Temixco", value: "Temixco" },
          { label: "Temoac", value: "Temoac" },
          { label: "Tepalcingo", value: "Tepalcingo" },
          { label: "Tepoztlan", value: "Tepoztlan" },
          { label: "Tetecala", value: "Tetecala" },
          { label: "Tetela del Volcan", value: "Tetela-del-Volcan" },
          { label: "Tlalnepantla", value: "Tlalnepantla" },
          {
            label: "Tlaltizapan de Zapata",
            value: "Tlaltizapan-de Zapata",
          },
          { label: "Tlaquiltenango", value: "Tlaquiltenango" },
          { label: "Tlayacapan", value: "Tlayacapan" },
          { label: "Totolapan", value: "Totolapan" },
          { label: "Xochitepec", value: "Xochitepec" },
          { label: "Yautepec", value: "Yautepec" },
          { label: "Yecapixtla", value: "Yecapixtla" },
          { label: "Zacatepec", value: "Zacatepec" },
          { label: "Zacualpan de Amilpas", value: "Zacualpan-de-Amilpas" },
        ],
      },
      {
        label: "Nayarit",
        value: "Nayarit",
        subregions: [
          { label: "Acaponeta", value: "Acaponeta" },
          { label: "Ahuacatlan", value: "Ahuacatlan" },
          { label: "Amatlan de Canas", value: "Amatlan-de-Canas" },
          { label: "Bahia de Banderas", value: "Bahia-de-Banderas" },
          { label: "Compostela", value: "Compostela" },
          { label: "Del Nayar", value: "Del-Nayar" },
          { label: "Huajicori", value: "Huajicori" },
          { label: "Ixtlan del Rio", value: "Ixtlan-del-Rio" },
          { label: "Jala", value: "Jala" },
          { label: "La Yesca", value: "La-Yesca" },
          { label: "Rosamorada", value: "Rosamorada" },
          { label: "Ruiz", value: "Ruiz" },
          { label: "San Blas", value: "San-Blas" },
          { label: "San Pedro Lagunillas", value: "San-Pedro-Lagunillas" },
          { label: "Santa Maria del Oro", value: "Santa-Maria-del-Oro" },
          { label: "Santiago Ixcuintla", value: "Santiago-Ixcuintla" },
          { label: "Tecuala", value: "Tecuala" },
          { label: "Tepic", value: "Tepic" },
          { label: "Tuxpan", value: "Tuxpan" },
          { label: "Xalisco", value: "Xalisco" },
        ],
      },
      {
        label: "Nuevo León",
        value: "Nuevo Leon",
        subregions: [
          { label: "Abasolo", value: "Abasolo" },
          { label: "Agualeguas", value: "Agualeguas" },
          { label: "Allende", value: "Allende" },
          { label: "Anahuac", value: "Anahuac" },
          { label: "Apodaca", value: "Apodaca" },
          { label: "Aramberri", value: "Aramberri" },
          { label: "Bustamante", value: "Bustamante" },
          { label: "Cadereyta Jimenez", value: "Cadereyta-Jimenez" },
          { label: "Cerralvo", value: "Cerralvo" },
          { label: "China", value: "China" },
          { label: "Cienega de Flores", value: "Cienega-de-Flores" },
          { label: "Doctor Arroyo", value: "Doctor-Arroyo" },
          { label: "Doctor Coss", value: "Doctor-Coss" },
          { label: "Doctor Gonzalez", value: "Doctor-Gonzalez" },
          { label: "El Carmen", value: "El-Carmen" },
          { label: "Galeana", value: "Galeana" },
          { label: "Garcia", value: "Garcia" },
          { label: "General Bravo", value: "General-Bravo" },
          { label: "General Escobedo", value: "General-Escobedo" },
          { label: "General Teran", value: "General-Teran" },
          { label: "General Trevino", value: "General-Trevino" },
          { label: "General Zaragoza", value: "General-Zaragoza" },
          { label: "General Zuazua", value: "General-Zuazua" },
          { label: "Guadalupe", value: "Guadalupe" },
          { label: "Hidalgo", value: "Hidalgo" },
          { label: "Higueras", value: "Higueras" },
          { label: "Hualahuises", value: "Hualahuises" },
          { label: "Iturbide", value: "Iturbide" },
          { label: "Juarez", value: "Juarez" },
          { label: "Lampazos de Naranjo", value: "Lampazos-de-Naranjo" },
          { label: "Linares", value: "Linares" },
          { label: "Los Aldamas", value: "Los-Aldamas" },
          { label: "Los Herreras", value: "Los-Herreras" },
          { label: "Los Ramones", value: "Los-Ramones" },
          { label: "Marin", value: "Marin" },
          { label: "Melchor Ocampo", value: "Melchor-Ocampo" },
          { label: "Mier y Noriega", value: "Mier-y-Noriega" },
          { label: "Mina", value: "Mina" },
          { label: "Montemorelos", value: "Montemorelos" },
          { label: "Monterrey", value: "Monterrey" },
          { label: "Paras", value: "Paras" },
          { label: "Pesqueria", value: "Pesqueria" },
          { label: "Rayones", value: "Rayones" },
          { label: "Sabinas Hidalgo", value: "Sabinas-Hidalgo" },
          { label: "Salinas Victoria", value: "Salinas-Victoria" },
          {
            label: "San Nicolas de los Garza",
            value: "San-Nicolas-de-los-Garza",
          },
          {
            label: "San Pedro Garza Garcia",
            value: "San-Pedro-Garza-Garcia",
          },
          { label: "Santa Catarina", value: "Santa-Catarina" },
          { label: "Santiago", value: "Santiago" },
          { label: "Vallecillo", value: "Vallecillo" },
          { label: "Villaldama", value: "Villaldama" },
        ],
      },
      {
        label: "Oaxaca",
        value: "Oaxaca",
        subregions: [
          { label: "Abejones", value: "Abejones" },
          {
            label: "Acatlan de Perez Figueroa",
            value: "Acatlan-de-Perez-Figueroa",
          },
          { label: "Animas Trujano", value: "Animas-Trujano" },
          { label: "Asuncion Cacalotepec", value: "Asuncion-Cacalotepec" },
          { label: "Asuncion Cuyotepeji", value: "Asuncion-Cuyotepeji" },
          { label: "Asuncion Ixtaltepec", value: "Asuncion-Ixtaltepec" },
          { label: "Asuncion Nochixtlan", value: "Asuncion-Nochixtlan" },
          { label: "Asuncion Ocotlan", value: "Asuncion-Ocotlan" },
          { label: "Asuncion Tlacolulita", value: "Asuncion-Tlacolulita" },
          { label: "Ayoquezco de Aldama", value: "Ayoquezco-de-Aldama" },
          { label: "Ayotzintepec", value: "Ayotzintepec" },
          { label: "Calihuala", value: "Calihuala" },
          { label: "Candelaria Loxicha", value: "Candelaria-Loxicha" },
          { label: "Capulalpam de Mendez", value: "Capulalpam-de-Mendez" },
          { label: "Chahuites", value: "Chahuites" },
          {
            label: "Chalcatongo de Hidalgo",
            value: "Chalcatongo-de-Hidalgo",
          },
          {
            label: "Chiquihuitlan de Benito Juarez",
            value: "Chiquihuitlan-de-Benito-Juarez",
          },
          { label: "Cienega de Zimatlan", value: "Cienega-de-Zimatlan" },
          { label: "Ciudad Ixtepec", value: "Ciudad-Ixtepec" },
          { label: "Coatecas Altas", value: "Coatecas-Altas" },
          {
            label: "Coicoyan de las Flores",
            value: "Coicoyan-de-las-Flores",
          },
          {
            label: "Concepcion Buenavista",
            value: "Concepcion-Buenavista",
          },
          { label: "Concepcion Papalo", value: "Concepcion-Papalo" },
          {
            label: "Constancia del Rosario",
            value: "Constancia-del-Rosario",
          },
          { label: "Cosolapa", value: "Cosolapa" },
          { label: "Cosoltepec", value: "Cosoltepec" },
          { label: "Cuilapam de Guerrero", value: "Cuilapam-de-Guerrero" },
          {
            label: "Cuna de la Independencia de Oaxaca",
            value: "Cuna-de-la-Independencia-de-Oaxaca",
          },
          {
            label: "Cuyamecalco Villa de Zaragoza",
            value: "Cuyamecalco-Villa-de-Zaragoza",
          },
          {
            label: "El Barrio de la Soledad",
            value: "El-Barrio-de-la-Soledad",
          },
          { label: "El Espinal", value: "El-Espinal" },
          {
            label: "Eloxochitlan de Flores Magon",
            value: "Eloxochitlan-de-Flores-Magon",
          },
          { label: "Fresnillo de Trujano", value: "Fresnillo-de-Trujano" },
          { label: "Guadalupe Etla", value: "Guadalupe-Etla" },
          { label: "Guadalupe de Ramirez", value: "Guadalupe-de-Ramirez" },
          { label: "Guelatao de Juarez", value: "Guelatao-de-Juarez" },
          { label: "Guevea de Humboldt", value: "Guevea-de-Humboldt" },
          {
            label: "Heroica Ciudad de Ejutla de Crespo",
            value: "Heroica-Ciudad-de-Ejutla-de-Crespo",
          },
          {
            label: "Heroica Ciudad de Huajuapan de Leon",
            value: "Heroica-Ciudad-de-Huajuapan-de-Leon",
          },
          {
            label: "Heroica Ciudad de Juchitan de Zaragoza",
            value: "Heroica-Ciudad-de-Juchitan-de-Zaragoza",
          },
          {
            label: "Heroica Ciudad de Tlaxiaco",
            value: "Heroica-Ciudad-de-Tlaxiaco",
          },
          {
            label: "Heroica Villa Tezoatlan de Segura y Luna",
            value: "Heroica-Villa-Tezoatlan-de-Segura-y-Luna",
          },
          { label: "Huautepec", value: "Huautepec" },
          { label: "Huautla de Jimenez", value: "Huautla-de-Jimenez" },
          { label: "Ixpantepec Nieves", value: "Ixpantepec-Nieves" },
          { label: "Ixtlan de Juarez", value: "Ixtlan-de-Juarez" },
          { label: "La Compania", value: "La-Compania" },
          { label: "La Pe", value: "La-Pe" },
          { label: "La Reforma", value: "La-Reforma" },
          {
            label: "La Trinidad Vista Hermosa",
            value: "La-Trinidad-Vista-Hermosa",
          },
          { label: "Loma Bonita", value: "Loma-Bonita" },
          { label: "Magdalena Apasco", value: "Magdalena-Apasco" },
          { label: "Magdalena Jaltepec", value: "Magdalena-Jaltepec" },
          { label: "Magdalena Mixtepec", value: "Magdalena-Mixtepec" },
          { label: "Magdalena Ocotlan", value: "Magdalena-Ocotlan" },
          { label: "Magdalena Penasco", value: "Magdalena-Penasco" },
          { label: "Magdalena Teitipac", value: "Magdalena-Teitipac" },
          {
            label: "Magdalena Tequisistlan",
            value: "Magdalena-Tequisistlan",
          },
          { label: "Magdalena Tlacotepec", value: "Magdalena-Tlacotepec" },
          {
            label: "Magdalena Yodocono de Porfirio Diaz",
            value: "Magdalena-Yodocono-de-Porfirio-Diaz",
          },
          { label: "Magdalena Zahuatlan", value: "Magdalena-Zahuatlan" },
          { label: "Mariscala de Juarez", value: "Mariscala-de-Juarez" },
          { label: "Martires de Tacubaya", value: "Martires-de-Tacubaya" },
          {
            label: "Matias Romero Avendano",
            value: "Matias-Romero-Avendano",
          },
          {
            label: "Mazatlan Villa de Flores",
            value: "Mazatlan-Villa-de-Flores",
          },
          { label: "Mesones Hidalgo", value: "Mesones-Hidalgo" },
          {
            label: "Miahuatlan de Porfirio Diaz",
            value: "Miahuatlan-de-Porfirio-Diaz",
          },
          {
            label: "Mixistlan de la Reforma",
            value: "Mixistlan-de-la-Reforma",
          },
          { label: "Monjas", value: "Monjas" },
          { label: "Natividad", value: "Natividad" },
          { label: "Nazareno Etla", value: "Nazareno-Etla" },
          { label: "Nejapa de Madero", value: "Nejapa-de-Madero" },
          { label: "Nuevo Zoquiapam", value: "Nuevo-Zoquiapam" },
          { label: "Oaxaca de Juarez", value: "Oaxaca-de-Juarez" },
          { label: "Ocotlan de Morelos", value: "Ocotlan-de-Morelos" },
          { label: "Pinotepa de Don Luis", value: "Pinotepa-de-Don-Luis" },
          { label: "Pluma Hidalgo", value: "Pluma-Hidalgo" },
          {
            label: "Putla Villa de Guerrero",
            value: "Putla-Villa-de-Guerrero",
          },
          { label: "Reforma de Pineda", value: "Reforma-de-Pineda" },
          { label: "Reyes Etla", value: "Reyes-Etla" },
          { label: "Rojas de Cuauhtemoc", value: "Rojas-de-Cuauhtemoc" },
          { label: "Salina Cruz", value: "Salina-Cruz" },
          { label: "San Agustin Amatengo", value: "San-Agustin-Amatengo" },
          { label: "San Agustin Atenango", value: "San-Agustin-Atenango" },
          { label: "San Agustin Chayuco", value: "San-Agustin-Chayuco" },
          { label: "San Agustin Etla", value: "San-Agustin-Etla" },
          { label: "San Agustin Loxicha", value: "San-Agustin-Loxicha" },
          {
            label: "San Agustin Tlacotepec",
            value: "San-Agustin-Tlacotepec",
          },
          { label: "San Agustin Yatareni", value: "San-Agustin-Yatareni" },
          {
            label: "San Agustin de las Juntas",
            value: "San-Agustin-de-las-Juntas",
          },
          {
            label: "San Andres Cabecera Nueva",
            value: "San-Andres-Cabecera-Nueva",
          },
          { label: "San Andres Dinicuiti", value: "San-Andres-Dinicuiti" },
          {
            label: "San Andres Huaxpaltepec",
            value: "San-Andres-Huaxpaltepec",
          },
          { label: "San Andres Huayapam", value: "San-Andres-Huayapam" },
          {
            label: "San Andres Ixtlahuaca",
            value: "San-Andres-Ixtlahuaca",
          },
          { label: "San Andres Lagunas", value: "San-Andres-Lagunas" },
          { label: "San Andres Nuxino", value: "San-Andres-Nuxino" },
          { label: "San Andres Paxtlan", value: "San-Andres-Paxtlan" },
          { label: "San Andres Sinaxtla", value: "San-Andres-Sinaxtla" },
          { label: "San Andres Solaga", value: "San-Andres-Solaga" },
          {
            label: "San Andres Teotilalpam",
            value: "San-Andres-Teotilalpam",
          },
          { label: "San Andres Tepetlapa", value: "San-Andres-Tepetlapa" },
          { label: "San Andres Yaa", value: "San-Andres-Yaa" },
          { label: "San Andres Zabache", value: "San-Andres-Zabache" },
          { label: "San Andres Zautla", value: "San-Andres-Zautla" },
          {
            label: "San Antonino Castillo Velasco",
            value: "San-Antonino-Castillo-Velasco",
          },
          {
            label: "San Antonino Monte Verde",
            value: "San-Antonino-Monte-Verde",
          },
          { label: "San Antonino el Alto", value: "San-Antonino-el-Alto" },
          { label: "San Antonio Acutla", value: "San-Antonio-Acutla" },
          { label: "San Antonio Huitepec", value: "San-Antonio-Huitepec" },
          {
            label: "San Antonio Nanahuatipam",
            value: "San-Antonio-Nanahuatipam",
          },
          {
            label: "San Antonio Sinicahua",
            value: "San-Antonio-Sinicahua",
          },
          {
            label: "San Antonio Tepetlapa",
            value: "San-Antonio-Tepetlapa",
          },
          {
            label: "San Antonio de la Cal",
            value: "San-Antonio-de-la-Cal",
          },
          {
            label: "San Baltazar Chichicapam",
            value: "San-Baltazar-Chichicapam",
          },
          { label: "San Baltazar Loxicha", value: "San-Baltazar-Loxicha" },
          {
            label: "San Baltazar Yatzachi el Bajo",
            value: "San-Baltazar-Yatzachi-el-Bajo",
          },
          {
            label: "San Bartolo Coyotepec",
            value: "San-Bartolo-Coyotepec",
          },
          {
            label: "San Bartolo Soyaltepec",
            value: "San-Bartolo-Soyaltepec",
          },
          { label: "San Bartolo Yautepec", value: "San-Bartolo-Yautepec" },
          {
            label: "San Bartolome Ayautla",
            value: "San-Bartolome-Ayautla",
          },
          {
            label: "San Bartolome Loxicha",
            value: "San-Bartolome-Loxicha",
          },
          {
            label: "San Bartolome Quialana",
            value: "San-Bartolome-Quialana",
          },
          {
            label: "San Bartolome Yucuane",
            value: "San-Bartolome-Yucuane",
          },
          {
            label: "San Bartolome Zoogocho",
            value: "San-Bartolome-Zoogocho",
          },
          {
            label: "San Bernardo Mixtepec",
            value: "San-Bernardo-Mixtepec",
          },
          { label: "San Blas Atempa", value: "San-Blas-Atempa" },
          { label: "San Carlos Yautepec", value: "San-Carlos-Yautepec" },
          {
            label: "San Cristobal Amatlan",
            value: "San-Cristobal-Amatlan",
          },
          {
            label: "San Cristobal Amoltepec",
            value: "San-Cristobal-Amoltepec",
          },
          {
            label: "San Cristobal Lachirioag",
            value: "San-Cristobal-Lachirioag",
          },
          {
            label: "San Cristobal Suchixtlahuaca",
            value: "San-Cristobal-Suchixtlahuaca",
          },
          {
            label: "San Dionisio Ocotepec",
            value: "San-Dionisio-Ocotepec",
          },
          { label: "San Dionisio Ocotlan", value: "San-Dionisio-Ocotlan" },
          { label: "San Dionisio del Mar", value: "San-Dionisio-del-Mar" },
          {
            label: "San Esteban Atatlahuca",
            value: "San-Esteban-Atatlahuca",
          },
          {
            label: "San Felipe Jalapa de Diaz",
            value: "San-Felipe-Jalapa-de-Diaz",
          },
          { label: "San Felipe Tejalapam", value: "San-Felipe-Tejalapam" },
          { label: "San Felipe Usila", value: "San-Felipe-Usila" },
          {
            label: "San Francisco Cahuacua",
            value: "San-Francisco-Cahuacua",
          },
          {
            label: "San Francisco Cajonos",
            value: "San-Francisco-Cajonos",
          },
          {
            label: "San Francisco Chapulapa",
            value: "San-Francisco-Chapulapa",
          },
          {
            label: "San Francisco Chindua",
            value: "San-Francisco-Chindua",
          },
          {
            label: "San Francisco Huehuetlan",
            value: "San-Francisco-Huehuetlan",
          },
          {
            label: "San Francisco Ixhuatan",
            value: "San-Francisco-Ixhuatan",
          },
          {
            label: "San Francisco Jaltepetongo",
            value: "San-Francisco-Jaltepetongo",
          },
          {
            label: "San Francisco Lachigolo",
            value: "San-Francisco-Lachigolo",
          },
          {
            label: "San Francisco Logueche",
            value: "San-Francisco-Logueche",
          },
          { label: "San Francisco Nuxano", value: "San-Francisco-Nuxano" },
          {
            label: "San Francisco Ozolotepec",
            value: "San-Francisco-Ozolotepec",
          },
          { label: "San Francisco Sola", value: "San-Francisco-Sola" },
          {
            label: "San Francisco Telixtlahuaca",
            value: "San-Francisco-Telixtlahuaca",
          },
          { label: "San Francisco Teopan", value: "San-Francisco-Teopan" },
          {
            label: "San Francisco Tlapancingo",
            value: "San-Francisco-Tlapancingo",
          },
          {
            label: "San Francisco del Mar",
            value: "San-Francisco-del-Mar",
          },
          { label: "San Gabriel Mixtepec", value: "San-Gabriel-Mixtepec" },
          {
            label: "San Ildefonso Amatlan",
            value: "San-Ildefonso-Amatlan",
          },
          { label: "San Ildefonso Sola", value: "San-Ildefonso-Sola" },
          {
            label: "San Ildefonso Villa Alta",
            value: "San-Ildefonso-Villa-Alta",
          },
          { label: "San Jacinto Amilpas", value: "San-Jacinto-Amilpas" },
          {
            label: "San Jacinto Tlacotepec",
            value: "San-Jacinto-Tlacotepec",
          },
          { label: "San Jeronimo Coatlan", value: "San-Jeronimo-Coatlan" },
          {
            label: "San Jeronimo Silacayoapilla",
            value: "San-Jeronimo-Silacayoapilla",
          },
          { label: "San Jeronimo Sosola", value: "San-Jeronimo-Sosola" },
          { label: "San Jeronimo Taviche", value: "San-Jeronimo-Taviche" },
          { label: "San Jeronimo Tecoatl", value: "San-Jeronimo-Tecoatl" },
          {
            label: "San Jeronimo Tlacochahuaya",
            value: "San-Jeronimo-Tlacochahuaya",
          },
          { label: "San Jorge Nuchita", value: "San-Jorge-Nuchita" },
          { label: "San Jose Ayuquila", value: "San-Jose-Ayuquila" },
          { label: "San Jose Chiltepec", value: "San-Jose-Chiltepec" },
          {
            label: "San Jose Estancia Grande",
            value: "San-Jose-Estancia-Grande",
          },
          {
            label: "San Jose Independencia",
            value: "San-Jose-Independencia",
          },
          { label: "San Jose Lachiguiri", value: "San-Jose-Lachiguiri" },
          { label: "San Jose Tenango", value: "San-Jose-Tenango" },
          { label: "San Jose del Penasco", value: "San-Jose-del-Penasco" },
          {
            label: "San Jose del Progreso",
            value: "San-Jose-del-Progreso",
          },
          { label: "San Juan Achiutla", value: "San-Juan-Achiutla" },
          { label: "San Juan Atepec", value: "San-Juan-Atepec" },
          {
            label: "San Juan Bautista Atatlahuca",
            value: "San-Juan-Bautista-Atatlahuca",
          },
          {
            label: "San Juan Bautista Coixtlahuaca",
            value: "San-Juan-Bautista-Coixtlahuaca",
          },
          {
            label: "San Juan Bautista Cuicatlan",
            value: "San-Juan-Bautista-Cuicatlan",
          },
          {
            label: "San Juan Bautista Guelache",
            value: "San-Juan-Bautista-Guelache",
          },
          {
            label: "San Juan Bautista Jayacatlan",
            value: "San-Juan-Bautista-Jayacatlan",
          },
          {
            label: "San Juan Bautista Lo de Soto",
            value: "San-Juan-Bautista-Lo-de-Soto",
          },
          {
            label: "San Juan Bautista Suchitepec",
            value: "San-Juan-Bautista-Suchitepec",
          },
          {
            label: "San Juan Bautista Tlachichilco",
            value: "San-Juan-Bautista-Tlachichilco",
          },
          {
            label: "San Juan Bautista Tlacoatzintepec",
            value: "San-Juan-Bautista-Tlacoatzintepec",
          },
          {
            label: "San Juan Bautista Tuxtepec",
            value: "San-Juan-Bautista-Tuxtepec",
          },
          {
            label: "San Juan Bautista Valle Nacional",
            value: "San-Juan-Bautista-Valle-Nacional",
          },
          {
            label: "San Juan Cacahuatepec",
            value: "San-Juan-Cacahuatepec",
          },
          {
            label: "San Juan Chicomezuchil",
            value: "San-Juan-Chicomezuchil",
          },
          { label: "San Juan Chilateca", value: "San-Juan-Chilateca" },
          { label: "San Juan Cieneguilla", value: "San-Juan-Cieneguilla" },
          { label: "San Juan Coatzospam", value: "San-Juan-Coatzospam" },
          { label: "San Juan Colorado", value: "San-Juan-Colorado" },
          { label: "San Juan Comaltepec", value: "San-Juan-Comaltepec" },
          { label: "San Juan Cotzocon", value: "San-Juan-Cotzocon" },
          { label: "San Juan Diuxi", value: "San-Juan-Diuxi" },
          {
            label: "San Juan Evangelista Analco",
            value: "San-Juan-Evangelista-Analco",
          },
          { label: "San Juan Guelavia", value: "San-Juan-Guelavia" },
          { label: "San Juan Guichicovi", value: "San-Juan-Guichicovi" },
          { label: "San Juan Ihualtepec", value: "San-Juan-Ihualtepec" },
          {
            label: "San Juan Juquila Mixes",
            value: "San-Juan-Juquila-Mixes",
          },
          {
            label: "San Juan Juquila Vijanos",
            value: "San-Juan-Juquila-Vijanos",
          },
          { label: "San Juan Lachao", value: "San-Juan-Lachao" },
          { label: "San Juan Lachigalla", value: "San-Juan-Lachigalla" },
          { label: "San Juan Lajarcia", value: "San-Juan-Lajarcia" },
          { label: "San Juan Lalana", value: "San-Juan-Lalana" },
          { label: "San Juan Mazatlan", value: "San-Juan-Mazatlan" },
          { label: "San Juan Mixtepec", value: "San-Juan-Mixtepec" },
          { label: "San Juan Numi", value: "San-Juan-Numi" },
          { label: "San Juan Ozolotepec", value: "San-Juan-Ozolotepec" },
          { label: "San Juan Petlapa", value: "San-Juan-Petlapa" },
          { label: "San Juan Quiahije", value: "San-Juan-Quiahije" },
          { label: "San Juan Quiotepec", value: "San-Juan-Quiotepec" },
          { label: "San Juan Sayultepec", value: "San-Juan-Sayultepec" },
          { label: "San Juan Tabaa", value: "San-Juan-Tabaa" },
          { label: "San Juan Tamazola", value: "San-Juan-Tamazola" },
          { label: "San Juan Teita", value: "San-Juan-Teita" },
          { label: "San Juan Teitipac", value: "San-Juan-Teitipac" },
          { label: "San Juan Tepeuxila", value: "San-Juan-Tepeuxila" },
          { label: "San Juan Teposcolula", value: "San-Juan-Teposcolula" },
          { label: "San Juan Yaee", value: "San-Juan-Yaee" },
          { label: "San Juan Yatzona", value: "San-Juan-Yatzona" },
          { label: "San Juan Yucuita", value: "San-Juan-Yucuita" },
          { label: "San Juan de los Cues", value: "San-Juan-de-los-Cues" },
          { label: "San Juan del Estado", value: "San-Juan-del-Estado" },
          { label: "San Juan del Rio", value: "San-Juan-del-Rio" },
          {
            label: "San Lorenzo Albarradas",
            value: "San-Lorenzo-Albarradas",
          },
          {
            label: "San Lorenzo Cacaotepec",
            value: "San-Lorenzo-Cacaotepec",
          },
          {
            label: "San Lorenzo Cuaunecuiltitla",
            value: "San-Lorenzo-Cuaunecuiltitla",
          },
          {
            label: "San Lorenzo Texmelucan",
            value: "San-Lorenzo-Texmelucan",
          },
          { label: "San Lorenzo Victoria", value: "San-Lorenzo-Victoria" },
          { label: "San Lorenzo", value: "San-Lorenzo" },
          { label: "San Lucas Camotlan", value: "San-Lucas-Camotlan" },
          { label: "San Lucas Ojitlan", value: "San-Lucas-Ojitlan" },
          { label: "San Lucas Quiavini", value: "San-Lucas-Quiavini" },
          { label: "San Lucas Zoquiapam", value: "San-Lucas-Zoquiapam" },
          { label: "San Luis Amatlan", value: "San-Luis-Amatlan" },
          {
            label: "San Marcial Ozolotepec",
            value: "San-Marcial-Ozolotepec",
          },
          { label: "San Marcos Arteaga", value: "San-Marcos-Arteaga" },
          {
            label: "San Martin Huamelulpam",
            value: "San-Martin-Huamelulpam",
          },
          { label: "San Martin Itunyoso", value: "San-Martin-Itunyoso" },
          { label: "San Martin Lachila", value: "San-Martin-Lachila" },
          { label: "San Martin Peras", value: "San-Martin-Peras" },
          { label: "San Martin Tilcajete", value: "San-Martin-Tilcajete" },
          { label: "San Martin Toxpalan", value: "San-Martin-Toxpalan" },
          { label: "San Martin Zacatepec", value: "San-Martin-Zacatepec" },
          {
            label: "San Martin de los Cansecos",
            value: "San-Martin-de-los-Cansecos",
          },
          { label: "San Mateo Cajonos", value: "San-Mateo-Cajonos" },
          { label: "San Mateo Etlatongo", value: "San-Mateo-Etlatongo" },
          { label: "San Mateo Nejapam", value: "San-Mateo-Nejapam" },
          { label: "San Mateo Penasco", value: "San-Mateo-Penasco" },
          { label: "San Mateo Pinas", value: "San-Mateo-Pinas" },
          { label: "San Mateo Rio Hondo", value: "San-Mateo-Rio-Hondo" },
          { label: "San Mateo Sindihui", value: "San-Mateo-Sindihui" },
          {
            label: "San Mateo Tlapiltepec",
            value: "San-Mateo-Tlapiltepec",
          },
          {
            label: "San Mateo Yoloxochitlan",
            value: "San-Mateo-Yoloxochitlan",
          },
          { label: "San Mateo Yucutindoo", value: "San-Mateo-Yucutindoo" },
          { label: "San Mateo del Mar", value: "San-Mateo-del-Mar" },
          { label: "San Melchor Betaza", value: "San-Melchor-Betaza" },
          { label: "San Miguel Achiutla", value: "San-Miguel-Achiutla" },
          {
            label: "San Miguel Ahuehuetitlan",
            value: "San-Miguel-Ahuehuetitlan",
          },
          { label: "San Miguel Aloapam", value: "San-Miguel-Aloapam" },
          { label: "San Miguel Amatitlan", value: "San-Miguel-Amatitlan" },
          { label: "San Miguel Amatlan", value: "San-Miguel-Amatlan" },
          { label: "San Miguel Chicahua", value: "San-Miguel-Chicahua" },
          { label: "San Miguel Chimalapa", value: "San-Miguel-Chimalapa" },
          { label: "San Miguel Coatlan", value: "San-Miguel-Coatlan" },
          { label: "San Miguel Ejutla", value: "San-Miguel-Ejutla" },
          { label: "San Miguel Huautla", value: "San-Miguel-Huautla" },
          { label: "San Miguel Mixtepec", value: "San-Miguel-Mixtepec" },
          {
            label: "San Miguel Panixtlahuaca",
            value: "San-Miguel-Panixtlahuaca",
          },
          { label: "San Miguel Peras", value: "San-Miguel-Peras" },
          { label: "San Miguel Piedras", value: "San-Miguel-Piedras" },
          {
            label: "San Miguel Quetzaltepec",
            value: "San-Miguel-Quetzaltepec",
          },
          {
            label: "San Miguel Santa Flor",
            value: "San-Miguel-Santa-Flor",
          },
          {
            label: "San Miguel Soyaltepec",
            value: "San-Miguel-Soyaltepec",
          },
          {
            label: "San Miguel Suchixtepec",
            value: "San-Miguel-Suchixtepec",
          },
          {
            label: "San Miguel Tecomatlan",
            value: "San-Miguel-Tecomatlan",
          },
          { label: "San Miguel Tenango", value: "San-Miguel-Tenango" },
          {
            label: "San Miguel Tequixtepec",
            value: "San-Miguel-Tequixtepec",
          },
          {
            label: "San Miguel Tilquiapam",
            value: "San-Miguel-Tilquiapam",
          },
          { label: "San Miguel Tlacamama", value: "San-Miguel-Tlacamama" },
          {
            label: "San Miguel Tlacotepec",
            value: "San-Miguel-Tlacotepec",
          },
          {
            label: "San Miguel Tulancingo",
            value: "San-Miguel-Tulancingo",
          },
          { label: "San Miguel Yotao", value: "San-Miguel-Yotao" },
          {
            label: "San Miguel del Puerto",
            value: "San-Miguel-del-Puerto",
          },
          { label: "San Miguel del Rio", value: "San-Miguel-del-Rio" },
          { label: "San Miguel el Grande", value: "San-Miguel-el-Grande" },
          { label: "San Nicolas Hidalgo", value: "San-Nicolas-Hidalgo" },
          { label: "San Nicolas", value: "San-Nicolas" },
          { label: "San Pablo Coatlan", value: "San-Pablo-Coatlan" },
          {
            label: "San Pablo Cuatro Venados",
            value: "San-Pablo-Cuatro-Venados",
          },
          { label: "San Pablo Etla", value: "San-Pablo-Etla" },
          { label: "San Pablo Huitzo", value: "San-Pablo-Huitzo" },
          { label: "San Pablo Huixtepec", value: "San-Pablo-Huixtepec" },
          {
            label: "San Pablo Macuiltianguis",
            value: "San-Pablo-Macuiltianguis",
          },
          { label: "San Pablo Tijaltepec", value: "San-Pablo-Tijaltepec" },
          {
            label: "San Pablo Villa de Mitla",
            value: "San-Pablo-Villa-de-Mitla",
          },
          { label: "San Pablo Yaganiza", value: "San-Pablo-Yaganiza" },
          { label: "San Pedro Amuzgos", value: "San-Pedro-Amuzgos" },
          { label: "San Pedro Apostol", value: "San-Pedro-Apostol" },
          { label: "San Pedro Atoyac", value: "San-Pedro-Atoyac" },
          { label: "San Pedro Cajonos", value: "San-Pedro-Cajonos" },
          {
            label: "San Pedro Comitancillo",
            value: "San-Pedro-Comitancillo",
          },
          {
            label: "San Pedro Coxcaltepec Cantaros",
            value: "San-Pedro-Coxcaltepec-Cantaros",
          },
          { label: "San Pedro Huamelula", value: "San-Pedro-Huamelula" },
          { label: "San Pedro Huilotepec", value: "San-Pedro-Huilotepec" },
          { label: "San Pedro Ixcatlan", value: "San-Pedro-Ixcatlan" },
          { label: "San Pedro Ixtlahuaca", value: "San-Pedro-Ixtlahuaca" },
          {
            label: "San Pedro Jaltepetongo",
            value: "San-Pedro-Jaltepetongo",
          },
          { label: "San Pedro Jicayan", value: "San-Pedro-Jicayan" },
          { label: "San Pedro Jocotipac", value: "San-Pedro-Jocotipac" },
          { label: "San Pedro Juchatengo", value: "San-Pedro-Juchatengo" },
          {
            label: "San Pedro Martir Quiechapa",
            value: "San-Pedro-Martir-Quiechapa",
          },
          {
            label: "San Pedro Martir Yucuxaco",
            value: "San-Pedro-Martir-Yucuxaco",
          },
          { label: "San Pedro Martir", value: "San-Pedro-Martir" },
          { label: "San Pedro Mixtepec", value: "San-Pedro-Mixtepec" },
          { label: "San Pedro Molinos", value: "San-Pedro-Molinos" },
          { label: "San Pedro Nopala", value: "San-Pedro-Nopala" },
          {
            label: "San Pedro Ocopetatillo",
            value: "San-Pedro-Ocopetatillo",
          },
          { label: "San Pedro Ocotepec", value: "San-Pedro-Ocotepec" },
          { label: "San Pedro Pochutla", value: "San-Pedro-Pochutla" },
          { label: "San Pedro Quiatoni", value: "San-Pedro-Quiatoni" },
          { label: "San Pedro Sochiapam", value: "San-Pedro-Sochiapam" },
          {
            label: "San Pedro Tapanatepec",
            value: "San-Pedro-Tapanatepec",
          },
          { label: "San Pedro Taviche", value: "San-Pedro-Taviche" },
          {
            label: "San Pedro Teozacoalco",
            value: "San-Pedro-Teozacoalco",
          },
          { label: "San Pedro Teutila", value: "San-Pedro-Teutila" },
          { label: "San Pedro Tidaa", value: "San-Pedro-Tidaa" },
          { label: "San Pedro Topiltepec", value: "San-Pedro-Topiltepec" },
          { label: "San Pedro Totolapam", value: "San-Pedro-Totolapam" },
          { label: "San Pedro Yaneri", value: "San-Pedro-Yaneri" },
          { label: "San Pedro Yolox", value: "San-Pedro-Yolox" },
          { label: "San Pedro Yucunama", value: "San-Pedro-Yucunama" },
          { label: "San Pedro el Alto", value: "San-Pedro-el-Alto" },
          {
            label: "San Pedro y San Pablo Ayutla",
            value: "San-Pedro-y-San-Pablo-Ayutla",
          },
          {
            label: "San Pedro y San Pablo Teposcolula",
            value: "San-Pedro-y-San-Pablo-Teposcolula",
          },
          {
            label: "San Pedro y San Pablo Tequixtepec",
            value: "San-Pedro-y-San-Pablo-Tequixtepec",
          },
          { label: "San Raymundo Jalpan", value: "San-Raymundo-Jalpan" },
          {
            label: "San Sebastian Abasolo",
            value: "San-Sebastian-Abasolo",
          },
          {
            label: "San Sebastian Coatlan",
            value: "San-Sebastian-Coatlan",
          },
          { label: "San Sebastian Ixcapa", value: "San-Sebastian-Ixcapa" },
          {
            label: "San Sebastian Nicananduta",
            value: "San-Sebastian-Nicananduta",
          },
          {
            label: "San Sebastian Rio Hondo",
            value: "San-Sebastian-Rio-Hondo",
          },
          {
            label: "San Sebastian Tecomaxtlahuaca",
            value: "San-Sebastian-Tecomaxtlahuaca",
          },
          {
            label: "San Sebastian Teitipac",
            value: "San-Sebastian-Teitipac",
          },
          { label: "San Sebastian Tutla", value: "San-Sebastian-Tutla" },
          { label: "San Simon Almolongas", value: "San-Simon-Almolongas" },
          { label: "San Simon Zahuatlan", value: "San-Simon-Zahuatlan" },
          { label: "San Vicente Coatlan", value: "San-Vicente-Coatlan" },
          { label: "San Vicente Lachixio", value: "San-Vicente-Lachixio" },
          { label: "San Vicente Nunu", value: "San-Vicente-Nunu" },
          {
            label: "Santa Ana Ateixtlahuaca",
            value: "Santa-Ana-Ateixtlahuaca",
          },
          { label: "Santa Ana Cuauhtemoc", value: "Santa-Ana-Cuauhtemoc" },
          { label: "Santa Ana Tavela", value: "Santa-Ana-Tavela" },
          { label: "Santa Ana Tlapacoyan", value: "Santa-Ana-Tlapacoyan" },
          { label: "Santa Ana Yareni", value: "Santa-Ana-Yareni" },
          { label: "Santa Ana Zegache", value: "Santa-Ana-Zegache" },
          { label: "Santa Ana del Valle", value: "Santa-Ana-del-Valle" },
          { label: "Santa Ana", value: "Santa-Ana" },
          {
            label: "Santa Catalina Quieri",
            value: "Santa-Catalina-Quieri",
          },
          {
            label: "Santa Catarina Cuixtla",
            value: "Santa-Catarina-Cuixtla",
          },
          {
            label: "Santa Catarina Ixtepeji",
            value: "Santa-Catarina-Ixtepeji",
          },
          {
            label: "Santa Catarina Juquila",
            value: "Santa-Catarina-Juquila",
          },
          {
            label: "Santa Catarina Lachatao",
            value: "Santa-Catarina-Lachatao",
          },
          {
            label: "Santa Catarina Loxicha",
            value: "Santa-Catarina-Loxicha",
          },
          {
            label: "Santa Catarina Mechoacan",
            value: "Santa-Catarina-Mechoacan",
          },
          { label: "Santa Catarina Minas", value: "Santa-Catarina-Minas" },
          {
            label: "Santa Catarina Quiane",
            value: "Santa-Catarina-Quiane",
          },
          {
            label: "Santa Catarina Quioquitani",
            value: "Santa-Catarina-Quioquitani",
          },
          {
            label: "Santa Catarina Tayata",
            value: "Santa-Catarina-Tayata",
          },
          { label: "Santa Catarina Ticua", value: "Santa-Catarina-Ticua" },
          {
            label: "Santa Catarina Yosonotu",
            value: "Santa-Catarina-Yosonotu",
          },
          {
            label: "Santa Catarina Zapoquila",
            value: "Santa-Catarina-Zapoquila",
          },
          { label: "Santa Cruz Acatepec", value: "Santa-Cruz-Acatepec" },
          { label: "Santa Cruz Amilpas", value: "Santa-Cruz-Amilpas" },
          { label: "Santa Cruz Itundujia", value: "Santa-Cruz-Itundujia" },
          { label: "Santa Cruz Mixtepec", value: "Santa-Cruz-Mixtepec" },
          { label: "Santa Cruz Nundaco", value: "Santa-Cruz-Nundaco" },
          { label: "Santa Cruz Papalutla", value: "Santa-Cruz-Papalutla" },
          {
            label: "Santa Cruz Tacache de Mina",
            value: "Santa-Cruz-Tacache-de-Mina",
          },
          { label: "Santa Cruz Tacahua", value: "Santa-Cruz-Tacahua" },
          { label: "Santa Cruz Tayata", value: "Santa-Cruz-Tayata" },
          { label: "Santa Cruz Xitla", value: "Santa-Cruz-Xitla" },
          {
            label: "Santa Cruz Xoxocotlan",
            value: "Santa-Cruz-Xoxocotlan",
          },
          {
            label: "Santa Cruz Zenzontepec",
            value: "Santa-Cruz-Zenzontepec",
          },
          { label: "Santa Cruz de Bravo", value: "Santa-Cruz-de-Bravo" },
          { label: "Santa Gertrudis", value: "Santa-Gertrudis" },
          { label: "Santa Ines Yatzeche", value: "Santa-Ines-Yatzeche" },
          {
            label: "Santa Ines de Zaragoza",
            value: "Santa-Ines-de-Zaragoza",
          },
          { label: "Santa Ines del Monte", value: "Santa-Ines-del-Monte" },
          {
            label: "Santa Lucia Miahuatlan",
            value: "Santa-Lucia-Miahuatlan",
          },
          {
            label: "Santa Lucia Monteverde",
            value: "Santa-Lucia-Monteverde",
          },
          { label: "Santa Lucia Ocotlan", value: "Santa-Lucia-Ocotlan" },
          {
            label: "Santa Lucia del Camino",
            value: "Santa-Lucia-del-Camino",
          },
          {
            label: "Santa Magdalena Jicotlan",
            value: "Santa-Magdalena-Jicotlan",
          },
          { label: "Santa Maria Alotepec", value: "Santa-Maria-Alotepec" },
          { label: "Santa Maria Apazco", value: "Santa-Maria-Apazco" },
          { label: "Santa Maria Atzompa", value: "Santa-Maria-Atzompa" },
          { label: "Santa Maria Camotlan", value: "Santa-Maria-Camotlan" },
          {
            label: "Santa Maria Chachoapam",
            value: "Santa-Maria-Chachoapam",
          },
          {
            label: "Santa Maria Chilchotla",
            value: "Santa-Maria-Chilchotla",
          },
          {
            label: "Santa Maria Chimalapa",
            value: "Santa-Maria-Chimalapa",
          },
          {
            label: "Santa Maria Colotepec",
            value: "Santa-Maria-Colotepec",
          },
          { label: "Santa Maria Cortijo", value: "Santa-Maria-Cortijo" },
          {
            label: "Santa Maria Coyotepec",
            value: "Santa-Maria-Coyotepec",
          },
          { label: "Santa Maria Ecatepec", value: "Santa-Maria-Ecatepec" },
          { label: "Santa Maria Guelace", value: "Santa-Maria-Guelace" },
          {
            label: "Santa Maria Guienagati",
            value: "Santa-Maria-Guienagati",
          },
          { label: "Santa Maria Huatulco", value: "Santa-Maria-Huatulco" },
          {
            label: "Santa Maria Huazolotitlan",
            value: "Santa-Maria-Huazolotitlan",
          },
          { label: "Santa Maria Ipalapa", value: "Santa-Maria-Ipalapa" },
          { label: "Santa Maria Ixcatlan", value: "Santa-Maria-Ixcatlan" },
          {
            label: "Santa Maria Jacatepec",
            value: "Santa-Maria-Jacatepec",
          },
          {
            label: "Santa Maria Jalapa del Marques",
            value: "Santa-Maria-Jalapa-del-Marques",
          },
          {
            label: "Santa Maria Jaltianguis",
            value: "Santa-Maria-Jaltianguis",
          },
          { label: "Santa Maria Lachixio", value: "Santa-Maria-Lachixio" },
          {
            label: "Santa Maria Mixtequilla",
            value: "Santa-Maria-Mixtequilla",
          },
          {
            label: "Santa Maria Nativitas",
            value: "Santa-Maria-Nativitas",
          },
          { label: "Santa Maria Nduayaco", value: "Santa-Maria-Nduayaco" },
          {
            label: "Santa Maria Ozolotepec",
            value: "Santa-Maria-Ozolotepec",
          },
          { label: "Santa Maria Papalo", value: "Santa-Maria-Papalo" },
          { label: "Santa Maria Penoles", value: "Santa-Maria-Penoles" },
          { label: "Santa Maria Petapa", value: "Santa-Maria-Petapa" },
          {
            label: "Santa Maria Quiegolani",
            value: "Santa-Maria-Quiegolani",
          },
          { label: "Santa Maria Sola", value: "Santa-Maria-Sola" },
          {
            label: "Santa Maria Tataltepec",
            value: "Santa-Maria-Tataltepec",
          },
          {
            label: "Santa Maria Tecomavaca",
            value: "Santa-Maria-Tecomavaca",
          },
          {
            label: "Santa Maria Temaxcalapa",
            value: "Santa-Maria-Temaxcalapa",
          },
          {
            label: "Santa Maria Temaxcaltepec",
            value: "Santa-Maria-Temaxcaltepec",
          },
          { label: "Santa Maria Teopoxco", value: "Santa-Maria-Teopoxco" },
          {
            label: "Santa Maria Tepantlali",
            value: "Santa-Maria-Tepantlali",
          },
          {
            label: "Santa Maria Texcatitlan",
            value: "Santa-Maria-Texcatitlan",
          },
          {
            label: "Santa Maria Tlahuitoltepec",
            value: "Santa-Maria-Tlahuitoltepec",
          },
          {
            label: "Santa Maria Tlalixtac",
            value: "Santa-Maria-Tlalixtac",
          },
          { label: "Santa Maria Tonameca", value: "Santa-Maria-Tonameca" },
          {
            label: "Santa Maria Totolapilla",
            value: "Santa-Maria-Totolapilla",
          },
          { label: "Santa Maria Xadani", value: "Santa-Maria-Xadani" },
          { label: "Santa Maria Yalina", value: "Santa-Maria-Yalina" },
          { label: "Santa Maria Yavesia", value: "Santa-Maria-Yavesia" },
          {
            label: "Santa Maria Yolotepec",
            value: "Santa-Maria-Yolotepec",
          },
          { label: "Santa Maria Yosoyua", value: "Santa-Maria-Yosoyua" },
          { label: "Santa Maria Yucuhiti", value: "Santa-Maria-Yucuhiti" },
          {
            label: "Santa Maria Zacatepec",
            value: "Santa-Maria-Zacatepec",
          },
          { label: "Santa Maria Zaniza", value: "Santa-Maria-Zaniza" },
          {
            label: "Santa Maria Zoquitlan",
            value: "Santa-Maria-Zoquitlan",
          },
          {
            label: "Santa Maria del Rosario",
            value: "Santa-Maria-del-Rosario",
          },
          { label: "Santa Maria del Tule", value: "Santa-Maria-del-Tule" },
          {
            label: "Santa Maria la Asuncion",
            value: "Santa-Maria-la-Asuncion",
          },
          { label: "Santiago Amoltepec", value: "Santiago-Amoltepec" },
          { label: "Santiago Apoala", value: "Santiago-Apoala" },
          { label: "Santiago Apostol", value: "Santiago-Apostol" },
          { label: "Santiago Astata", value: "Santiago-Astata" },
          { label: "Santiago Atitlan", value: "Santiago-Atitlan" },
          { label: "Santiago Ayuquililla", value: "Santiago-Ayuquililla" },
          {
            label: "Santiago Cacaloxtepec",
            value: "Santiago-Cacaloxtepec",
          },
          { label: "Santiago Camotlan", value: "Santiago-Camotlan" },
          { label: "Santiago Chazumba", value: "Santiago-Chazumba" },
          { label: "Santiago Choapam", value: "Santiago-Choapam" },
          { label: "Santiago Comaltepec", value: "Santiago-Comaltepec" },
          {
            label: "Santiago Huajolotitlan",
            value: "Santiago-Huajolotitlan",
          },
          { label: "Santiago Huauclilla", value: "Santiago-Huauclilla" },
          {
            label: "Santiago Ihuitlan Plumas",
            value: "Santiago-Ihuitlan-Plumas",
          },
          { label: "Santiago Ixcuintepec", value: "Santiago-Ixcuintepec" },
          { label: "Santiago Ixtayutla", value: "Santiago-Ixtayutla" },
          { label: "Santiago Jamiltepec", value: "Santiago-Jamiltepec" },
          { label: "Santiago Jocotepec", value: "Santiago-Jocotepec" },
          { label: "Santiago Juxtlahuaca", value: "Santiago-Juxtlahuaca" },
          { label: "Santiago Lachiguiri", value: "Santiago-Lachiguiri" },
          { label: "Santiago Lalopa", value: "Santiago-Lalopa" },
          { label: "Santiago Laollaga", value: "Santiago-Laollaga" },
          { label: "Santiago Laxopa", value: "Santiago-Laxopa" },
          {
            label: "Santiago Llano Grande",
            value: "Santiago-Llano-Grande",
          },
          { label: "Santiago Matatlan", value: "Santiago-Matatlan" },
          { label: "Santiago Miltepec", value: "Santiago-Miltepec" },
          { label: "Santiago Minas", value: "Santiago-Minas" },
          { label: "Santiago Nacaltepec", value: "Santiago-Nacaltepec" },
          { label: "Santiago Nejapilla", value: "Santiago-Nejapilla" },
          { label: "Santiago Niltepec", value: "Santiago-Niltepec" },
          { label: "Santiago Nundiche", value: "Santiago-Nundiche" },
          { label: "Santiago Nuyoo", value: "Santiago-Nuyoo" },
          {
            label: "Santiago Pinotepa Nacional",
            value: "Santiago-Pinotepa-Nacional",
          },
          {
            label: "Santiago Suchilquitongo",
            value: "Santiago-Suchilquitongo",
          },
          { label: "Santiago Tamazola", value: "Santiago-Tamazola" },
          { label: "Santiago Tapextla", value: "Santiago-Tapextla" },
          { label: "Santiago Tenango", value: "Santiago-Tenango" },
          { label: "Santiago Tepetlapa", value: "Santiago-Tepetlapa" },
          { label: "Santiago Tetepec", value: "Santiago-Tetepec" },
          { label: "Santiago Texcalcingo", value: "Santiago-Texcalcingo" },
          { label: "Santiago Textitlan", value: "Santiago-Textitlan" },
          { label: "Santiago Tilantongo", value: "Santiago-Tilantongo" },
          { label: "Santiago Tillo", value: "Santiago-Tillo" },
          {
            label: "Santiago Tlazoyaltepec",
            value: "Santiago-Tlazoyaltepec",
          },
          { label: "Santiago Xanica", value: "Santiago-Xanica" },
          { label: "Santiago Xiacui", value: "Santiago-Xiacui" },
          { label: "Santiago Yaitepec", value: "Santiago-Yaitepec" },
          { label: "Santiago Yaveo", value: "Santiago-Yaveo" },
          { label: "Santiago Yolomecatl", value: "Santiago-Yolomecatl" },
          { label: "Santiago Yosondua", value: "Santiago-Yosondua" },
          { label: "Santiago Yucuyachi", value: "Santiago-Yucuyachi" },
          { label: "Santiago Zacatepec", value: "Santiago-Zacatepec" },
          { label: "Santiago Zoochila", value: "Santiago-Zoochila" },
          { label: "Santiago del Rio", value: "Santiago-del-Rio" },
          {
            label: "Santo Domingo Albarradas",
            value: "Santo-Domingo-Albarradas",
          },
          {
            label: "Santo Domingo Armenta",
            value: "Santo-Domingo-Armenta",
          },
          {
            label: "Santo Domingo Chihuitan",
            value: "Santo-Domingo-Chihuitan",
          },
          {
            label: "Santo Domingo Ingenio",
            value: "Santo-Domingo-Ingenio",
          },
          {
            label: "Santo Domingo Ixcatlan",
            value: "Santo-Domingo-Ixcatlan",
          },
          { label: "Santo Domingo Nuxaa", value: "Santo-Domingo-Nuxaa" },
          {
            label: "Santo Domingo Ozolotepec",
            value: "Santo-Domingo-Ozolotepec",
          },
          { label: "Santo Domingo Petapa", value: "Santo-Domingo-Petapa" },
          {
            label: "Santo Domingo Roayaga",
            value: "Santo-Domingo-Roayaga",
          },
          {
            label: "Santo Domingo Tehuantepec",
            value: "Santo-Domingo-Tehuantepec",
          },
          {
            label: "Santo Domingo Teojomulco",
            value: "Santo-Domingo-Teojomulco",
          },
          {
            label: "Santo Domingo Tepuxtepec",
            value: "Santo-Domingo-Tepuxtepec",
          },
          {
            label: "Santo Domingo Tlatayapam",
            value: "Santo-Domingo-Tlatayapam",
          },
          {
            label: "Santo Domingo Tomaltepec",
            value: "Santo-Domingo-Tomaltepec",
          },
          { label: "Santo Domingo Tonala", value: "Santo-Domingo-Tonala" },
          {
            label: "Santo Domingo Tonaltepec",
            value: "Santo-Domingo-Tonaltepec",
          },
          {
            label: "Santo Domingo Xagacia",
            value: "Santo-Domingo-Xagacia",
          },
          {
            label: "Santo Domingo Yanhuitlan",
            value: "Santo-Domingo-Yanhuitlan",
          },
          {
            label: "Santo Domingo Yodohino",
            value: "Santo-Domingo-Yodohino",
          },
          {
            label: "Santo Domingo Zanatepec",
            value: "Santo-Domingo-Zanatepec",
          },
          {
            label: "Santo Domingo de Morelos",
            value: "Santo-Domingo-de-Morelos",
          },
          { label: "Santo Tomas Jalieza", value: "Santo-Tomas-Jalieza" },
          {
            label: "Santo Tomas Mazaltepec",
            value: "Santo-Tomas-Mazaltepec",
          },
          { label: "Santo Tomas Ocotepec", value: "Santo-Tomas-Ocotepec" },
          {
            label: "Santo Tomas Tamazulapan",
            value: "Santo-Tomas-Tamazulapan",
          },
          { label: "Santos Reyes Nopala", value: "Santos-Reyes-Nopala" },
          { label: "Santos Reyes Papalo", value: "Santos-Reyes-Papalo" },
          {
            label: "Santos Reyes Tepejillo",
            value: "Santos-Reyes-Tepejillo",
          },
          { label: "Santos Reyes Yucuna", value: "Santos-Reyes-Yucuna" },
          { label: "Silacayoapam", value: "Silacayoapam" },
          { label: "Sitio de Xitlapehua", value: "Sitio-de-Xitlapehua" },
          { label: "Soledad Etla", value: "Soledad-Etla" },
          {
            label: "Tamazulapam del Espiritu Santo",
            value: "Tamazulapam-del-Espiritu-Santo",
          },
          { label: "Tanetze de Zaragoza", value: "Tanetze-de-Zaragoza" },
          { label: "Taniche", value: "Taniche" },
          { label: "Tataltepec de Valdes", value: "Tataltepec-de-Valdes" },
          {
            label: "Teococuilco de Marcos Perez",
            value: "Teococuilco-de-Marcos-Perez",
          },
          {
            label: "Teotitlan de Flores Magon",
            value: "Teotitlan-de-Flores-Magon",
          },
          { label: "Teotitlan del Valle", value: "Teotitlan-del-Valle" },
          { label: "Teotongo", value: "Teotongo" },
          {
            label: "Tepelmeme Villa de Morelos",
            value: "Tepelmeme-Villa-de-Morelos",
          },
          {
            label: "Tlacolula de Matamoros",
            value: "Tlacolula-de-Matamoros",
          },
          { label: "Tlacotepec Plumas", value: "Tlacotepec-Plumas" },
          { label: "Tlalixtac de Cabrera", value: "Tlalixtac-de-Cabrera" },
          {
            label: "Totontepec Villa de Morelos",
            value: "Totontepec-Villa-de-Morelos",
          },
          { label: "Trinidad Zaachila", value: "Trinidad-Zaachila" },
          { label: "Union Hidalgo", value: "Union-Hidalgo" },
          { label: "Valerio Trujano", value: "Valerio-Trujano" },
          { label: "Villa Diaz Ordaz", value: "Villa-Diaz-Ordaz" },
          { label: "Villa Hidalgo", value: "Villa-Hidalgo" },
          { label: "Villa Sola de Vega", value: "Villa-Sola-de-Vega" },
          {
            label: "Villa Talea de Castro",
            value: "Villa-Talea-de-Castro",
          },
          {
            label: "Villa Tejupam de la Union",
            value: "Villa-Tejupam-de-la-Union",
          },
          {
            label: "Villa de Chilapa de Diaz",
            value: "Villa-de-Chilapa-de-Diaz",
          },
          { label: "Villa de Etla", value: "Villa-de-Etla" },
          {
            label: "Villa de Tamazulapam del Progreso",
            value: "Villa-de-Tamazulapam-del-Progreso",
          },
          { label: "Villa de Tututepec", value: "Villa-de-Tututepec" },
          { label: "Villa de Zaachila", value: "Villa-de-Zaachila" },
          { label: "Yaxe", value: "Yaxe" },
          { label: "Yogana", value: "Yogana" },
          {
            label: "Yutanduchi de Guerrero",
            value: "Yutanduchi-de-Guerrero",
          },
          { label: "Zapotitlan Lagunas", value: "Zapotitlan-Lagunas" },
          { label: "Zapotitlan Palmas", value: "Zapotitlan-Palmas" },
          { label: "Zimatlan de Alvarez", value: "Zimatlan-de-Alvarez" },
        ],
      },
      {
        label: "Puebla",
        value: "Puebla",
        subregions: [
          { label: "Acajete", value: "Acajete" },
          { label: "Acateno", value: "Acateno" },
          { label: "Acatlan", value: "Acatlan" },
          { label: "Acatzingo", value: "Acatzingo" },
          { label: "Acteopan", value: "Acteopan" },
          { label: "Ahuacatlan", value: "Ahuacatlan" },
          { label: "Ahuatlan", value: "Ahuatlan" },
          { label: "Ahuazotepec", value: "Ahuazotepec" },
          { label: "Ahuehuetitla", value: "Ahuehuetitla" },
          { label: "Ajalpan", value: "Ajalpan" },
          { label: "Albino Zertuche", value: "Albino-Zertuche" },
          { label: "Aljojuca", value: "Aljojuca" },
          { label: "Altepexi", value: "Altepexi" },
          { label: "Amixtlan", value: "Amixtlan" },
          { label: "Amozoc", value: "Amozoc" },
          { label: "Aquixtla", value: "Aquixtla" },
          { label: "Atempan", value: "Atempan" },
          { label: "Atexcal", value: "Atexcal" },
          { label: "Atlequizayan", value: "Atlequizayan" },
          { label: "Atlixco", value: "Atlixco" },
          { label: "Atoyatempan", value: "Atoyatempan" },
          { label: "Atzala", value: "Atzala" },
          { label: "Atzitzihuacan", value: "Atzitzihuacan" },
          { label: "Atzitzintla", value: "Atzitzintla" },
          { label: "Axutla", value: "Axutla" },
          { label: "Ayotoxco de Guerrero", value: "Ayotoxco-de-Guerrero" },
          { label: "Calpan", value: "Calpan" },
          { label: "Caltepec", value: "Caltepec" },
          { label: "Camocuautla", value: "Camocuautla" },
          { label: "Canada Morelos", value: "Canada-Morelos" },
          { label: "Caxhuacan", value: "Caxhuacan" },
          {
            label: "Chalchicomula de Sesma",
            value: "Chalchicomula-de-Sesma",
          },
          { label: "Chapulco", value: "Chapulco" },
          { label: "Chiautla", value: "Chiautla" },
          { label: "Chiautzingo", value: "Chiautzingo" },
          { label: "Chichiquila", value: "Chichiquila" },
          { label: "Chiconcuautla", value: "Chiconcuautla" },
          { label: "Chietla", value: "Chietla" },
          { label: "Chigmecatitlan", value: "Chigmecatitlan" },
          { label: "Chignahuapan", value: "Chignahuapan" },
          { label: "Chignautla", value: "Chignautla" },
          { label: "Chila de la Sal", value: "Chila-de-la-Sal" },
          { label: "Chila", value: "Chila" },
          { label: "Chilchotla", value: "Chilchotla" },
          { label: "Chinantla", value: "Chinantla" },
          { label: "Coatepec", value: "Coatepec" },
          { label: "Coatzingo", value: "Coatzingo" },
          { label: "Cohetzala", value: "Cohetzala" },
          { label: "Cohuecan", value: "Cohuecan" },
          { label: "Coronango", value: "Coronango" },
          { label: "Coxcatlan", value: "Coxcatlan" },
          { label: "Coyomeapan", value: "Coyomeapan" },
          { label: "Coyotepec", value: "Coyotepec" },
          { label: "Cuapiaxtla de Madero", value: "Cuapiaxtla-de-Madero" },
          { label: "Cuautempan", value: "Cuautempan" },
          { label: "Cuautinchan", value: "Cuautinchan" },
          { label: "Cuautlancingo", value: "Cuautlancingo" },
          { label: "Cuayuca de Andrade", value: "Cuayuca-de-Andrade" },
          {
            label: "Cuetzalan del Progreso",
            value: "Cuetzalan-del-Progreso",
          },
          { label: "Cuyoaco", value: "Cuyoaco" },
          { label: "Domingo Arenas", value: "Domingo-Arenas" },
          { label: "Eloxochitlan", value: "Eloxochitlan" },
          { label: "Epatlan", value: "Epatlan" },
          { label: "Esperanza", value: "Esperanza" },
          { label: "Francisco Z. Mena", value: "Francisco-Z.-Mena" },
          {
            label: "General Felipe Angeles",
            value: "General-Felipe-Angeles",
          },
          { label: "Guadalupe Victoria", value: "Guadalupe-Victoria" },
          { label: "Guadalupe", value: "Guadalupe" },
          { label: "Hermenegildo Galeana", value: "Hermenegildo-Galeana" },
          { label: "Honey", value: "Honey" },
          { label: "Huaquechula", value: "Huaquechula" },
          { label: "Huatlatlauca", value: "Huatlatlauca" },
          { label: "Huauchinango", value: "Huauchinango" },
          { label: "Huehuetla", value: "Huehuetla" },
          { label: "Huehuetlan el Chico", value: "Huehuetlan-el-Chico" },
          { label: "Huehuetlan el Grande", value: "Huehuetlan-el-Grande" },
          { label: "Huejotzingo", value: "Huejotzingo" },
          { label: "Hueyapan", value: "Hueyapan" },
          { label: "Hueytamalco", value: "Hueytamalco" },
          { label: "Hueytlalpan", value: "Hueytlalpan" },
          { label: "Huitzilan de Serdan", value: "Huitzilan-de-Serdan" },
          { label: "Huitziltepec", value: "Huitziltepec" },
          {
            label: "Ixcamilpa de Guerrero",
            value: "Ixcamilpa-de-Guerrero",
          },
          { label: "Ixcaquixtla", value: "Ixcaquixtla" },
          { label: "Ixtacamaxtitlan", value: "Ixtacamaxtitlan" },
          { label: "Ixtepec", value: "Ixtepec" },
          { label: "Izucar de Matamoros", value: "Izucar-de-Matamoros" },
          { label: "Jalpan", value: "Jalpan" },
          { label: "Jolalpan", value: "Jolalpan" },
          { label: "Jonotla", value: "Jonotla" },
          { label: "Jopala", value: "Jopala" },
          { label: "Juan C. Bonilla", value: "Juan-C.-Bonilla" },
          { label: "Juan Galindo", value: "Juan-Galindo" },
          { label: "Juan N. Mendez", value: "Juan-N.-Mendez" },
          {
            label: "La Magdalena Tlatlauquitepec",
            value: "La-Magdalena-Tlatlauquitepec",
          },
          { label: "Lafragua", value: "Lafragua" },
          { label: "Libres", value: "Libres" },
          { label: "Los Reyes de Juarez", value: "Los-Reyes-de-Juarez" },
          {
            label: "Mazapiltepec de Juarez",
            value: "Mazapiltepec-de-Juarez",
          },
          { label: "Mixtla", value: "Mixtla" },
          { label: "Molcaxac", value: "Molcaxac" },
          { label: "Naupan", value: "Naupan" },
          { label: "Nauzontla", value: "Nauzontla" },
          { label: "Nealtican", value: "Nealtican" },
          { label: "Nicolas Bravo", value: "Nicolas-Bravo" },
          { label: "Nopalucan", value: "Nopalucan" },
          { label: "Ocotepec", value: "Ocotepec" },
          { label: "Ocoyucan", value: "Ocoyucan" },
          { label: "Olintla", value: "Olintla" },
          { label: "Oriental", value: "Oriental" },
          { label: "Pahuatlan", value: "Pahuatlan" },
          { label: "Palmar de Bravo", value: "Palmar-de-Bravo" },
          { label: "Pantepec", value: "Pantepec" },
          { label: "Petlalcingo", value: "Petlalcingo" },
          { label: "Piaxtla", value: "Piaxtla" },
          { label: "Puebla", value: "Puebla" },
          { label: "Quecholac", value: "Quecholac" },
          { label: "Quimixtlan", value: "Quimixtlan" },
          { label: "Rafael Lara Grajales", value: "Rafael-Lara-Grajales" },
          { label: "San Andres Cholula", value: "San-Andres-Cholula" },
          { label: "San Antonio Canada", value: "San-Antonio-Canada" },
          {
            label: "San Diego la Mesa Tochimiltzingo",
            value: "San-Diego-la-Mesa-Tochimiltzingo",
          },
          {
            label: "San Felipe Teotlalcingo",
            value: "San-Felipe-Teotlalcingo",
          },
          { label: "San Felipe Tepatlan", value: "San-Felipe-Tepatlan" },
          { label: "San Gabriel Chilac", value: "San-Gabriel-Chilac" },
          { label: "San Gregorio Atzompa", value: "San-Gregorio-Atzompa" },
          {
            label: "San Jeronimo Tecuanipan",
            value: "San-Jeronimo-Tecuanipan",
          },
          {
            label: "San Jeronimo Xayacatlan",
            value: "San-Jeronimo-Xayacatlan",
          },
          { label: "San Jose Chiapa", value: "San-Jose-Chiapa" },
          { label: "San Jose Miahuatlan", value: "San-Jose-Miahuatlan" },
          { label: "San Juan Atenco", value: "San-Juan-Atenco" },
          { label: "San Juan Atzompa", value: "San-Juan-Atzompa" },
          {
            label: "San Martin Texmelucan",
            value: "San-Martin-Texmelucan",
          },
          {
            label: "San Martin Totoltepec",
            value: "San-Martin-Totoltepec",
          },
          {
            label: "San Matias Tlalancaleca",
            value: "San-Matias-Tlalancaleca",
          },
          { label: "San Miguel Ixitlan", value: "San-Miguel-Ixitlan" },
          { label: "San Miguel Xoxtla", value: "San-Miguel-Xoxtla" },
          {
            label: "San Nicolas Buenos Aires",
            value: "San-Nicolas-Buenos-Aires",
          },
          {
            label: "San Nicolas de los Ranchos",
            value: "San-Nicolas-de-los-Ranchos",
          },
          { label: "San Pablo Anicano", value: "San-Pablo-Anicano" },
          { label: "San Pedro Cholula", value: "San-Pedro-Cholula" },
          {
            label: "San Pedro Yeloixtlahuaca",
            value: "San-Pedro-Yeloixtlahuaca",
          },
          {
            label: "San Salvador Huixcolotla",
            value: "San-Salvador-Huixcolotla",
          },
          { label: "San Salvador el Seco", value: "San-Salvador-el-Seco" },
          {
            label: "San Salvador el Verde",
            value: "San-Salvador-el-Verde",
          },
          {
            label: "San Sebastian Tlacotepec",
            value: "San-Sebastian-Tlacotepec",
          },
          {
            label: "Santa Catarina Tlaltempan",
            value: "Santa-Catarina-Tlaltempan",
          },
          {
            label: "Santa Ines Ahuatempan",
            value: "Santa-Ines-Ahuatempan",
          },
          { label: "Santa Isabel Cholula", value: "Santa-Isabel-Cholula" },
          { label: "Santiago Miahuatlan", value: "Santiago-Miahuatlan" },
          {
            label: "Santo Tomas Hueyotlipan",
            value: "Santo-Tomas-Hueyotlipan",
          },
          { label: "Soltepec", value: "Soltepec" },
          { label: "Tecali de Herrera", value: "Tecali-de-Herrera" },
          { label: "Tecamachalco", value: "Tecamachalco" },
          { label: "Tecomatlan", value: "Tecomatlan" },
          { label: "Tehuacan", value: "Tehuacan" },
          { label: "Tehuitzingo", value: "Tehuitzingo" },
          { label: "Tenampulco", value: "Tenampulco" },
          { label: "Teopantlan", value: "Teopantlan" },
          { label: "Teotlalco", value: "Teotlalco" },
          { label: "Tepanco de Lopez", value: "Tepanco-de-Lopez" },
          { label: "Tepango de Rodriguez", value: "Tepango-de-Rodriguez" },
          {
            label: "Tepatlaxco de Hidalgo",
            value: "Tepatlaxco-de-Hidalgo",
          },
          { label: "Tepeaca", value: "Tepeaca" },
          { label: "Tepemaxalco", value: "Tepemaxalco" },
          { label: "Tepeojuma", value: "Tepeojuma" },
          { label: "Tepetzintla", value: "Tepetzintla" },
          { label: "Tepexco", value: "Tepexco" },
          { label: "Tepexi de Rodriguez", value: "Tepexi-de-Rodriguez" },
          {
            label: "Tepeyahualco de Cuauhtemoc",
            value: "Tepeyahualco-de-Cuauhtemoc",
          },
          { label: "Tepeyahualco", value: "Tepeyahualco" },
          { label: "Tetela de Ocampo", value: "Tetela-de-Ocampo" },
          {
            label: "Teteles de Avila Castillo",
            value: "Teteles-de-Avila-Castillo",
          },
          { label: "Teziutlan", value: "Teziutlan" },
          { label: "Tianguismanalco", value: "Tianguismanalco" },
          { label: "Tilapa", value: "Tilapa" },
          { label: "Tlachichuca", value: "Tlachichuca" },
          {
            label: "Tlacotepec de Benito Juarez",
            value: "Tlacotepec-de-Benito-Juarez",
          },
          { label: "Tlacuilotepec", value: "Tlacuilotepec" },
          { label: "Tlahuapan", value: "Tlahuapan" },
          { label: "Tlaltenango", value: "Tlaltenango" },
          { label: "Tlanepantla", value: "Tlanepantla" },
          { label: "Tlaola", value: "Tlaola" },
          { label: "Tlapacoya", value: "Tlapacoya" },
          { label: "Tlapanala", value: "Tlapanala" },
          { label: "Tlatlauquitepec", value: "Tlatlauquitepec" },
          { label: "Tlaxco", value: "Tlaxco" },
          { label: "Tochimilco", value: "Tochimilco" },
          { label: "Tochtepec", value: "Tochtepec" },
          {
            label: "Totoltepec de Guerrero",
            value: "Totoltepec-de-Guerrero",
          },
          { label: "Tulcingo", value: "Tulcingo" },
          { label: "Tuzamapan de Galeana", value: "Tuzamapan-de-Galeana" },
          { label: "Tzicatlacoyan", value: "Tzicatlacoyan" },
          { label: "Venustiano Carranza", value: "Venustiano-Carranza" },
          { label: "Vicente Guerrero", value: "Vicente-Guerrero" },
          { label: "Xayacatlan de Bravo", value: "Xayacatlan-de-Bravo" },
          { label: "Xicotepec", value: "Xicotepec" },
          { label: "Xicotlan", value: "Xicotlan" },
          { label: "Xiutetelco", value: "Xiutetelco" },
          { label: "Xochiapulco", value: "Xochiapulco" },
          { label: "Xochiltepec", value: "Xochiltepec" },
          {
            label: "Xochitlan Todos Santos",
            value: "Xochitlan-Todos-Santos",
          },
          {
            label: "Xochitlan de Vicente Suarez",
            value: "Xochitlan-de-Vicente-Suarez",
          },
          { label: "Yaonahuac", value: "Yaonahuac" },
          { label: "Yehualtepec", value: "Yehualtepec" },
          { label: "Zacapala", value: "Zacapala" },
          { label: "Zacapoaxtla", value: "Zacapoaxtla" },
          { label: "Zacatlan", value: "Zacatlan" },
          { label: "Zapotitlan de Mendez", value: "Zapotitlan-de-Mendez" },
          { label: "Zapotitlan", value: "Zapotitlan" },
          { label: "Zaragoza", value: "Zaragoza" },
          { label: "Zautla", value: "Zautla" },
          { label: "Zihuateutla", value: "Zihuateutla" },
          { label: "Zinacatepec", value: "Zinacatepec" },
          { label: "Zongozotla", value: "Zongozotla" },
          { label: "Zoquiapan", value: "Zoquiapan" },
          { label: "Zoquitlan", value: "Zoquitlan" },
        ],
      },
      {
        label: "Queretaro",
        value: "Queretaro",
        subregions: [
          { label: "Amealco de Bonfil", value: "Amealco-de-Bonfil" },
          { label: "Arroyo Seco", value: "Arroyo-Seco" },
          { label: "Cadereyta de Montes", value: "Cadereyta-de-Montes" },
          { label: "Colon", value: "Colon" },
          { label: "Corregidora", value: "Corregidora" },
          { label: "El Marques", value: "El-Marques" },
          { label: "Ezequiel Montes", value: "Ezequiel-Montes" },
          { label: "Huimilpan", value: "Huimilpan" },
          { label: "Jalpan de Serra", value: "Jalpan-de-Serra" },
          { label: "Landa de Matamoros", value: "Landa-de-Matamoros" },
          { label: "Pedro Escobedo", value: "Pedro-Escobedo" },
          { label: "Penamiller", value: "Penamiller" },
          { label: "Pinal de Amoles", value: "Pinal-de-Amoles" },
          { label: "Queretaro", value: "Queretaro" },
          { label: "San Joaquin", value: "San-Joaquin" },
          { label: "San Juan del Rio", value: "San-Juan-del-Rio" },
          { label: "Tequisquiapan", value: "Tequisquiapan" },
          { label: "Toliman", value: "Toliman" },
        ],
      },
      {
        label: "Quintana Roo",
        value: "Quintana-Roo",
        subregions: [
          { label: "Bacalar", value: "Bacalar" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          { label: "Cozumel", value: "Cozumel" },
          {
            label: "Felipe Carrillo Puerto",
            value: "Felipe-Carrillo-Puerto",
          },
          { label: "Isla Mujeres", value: "Isla-Mujeres" },
          { label: "Jose Maria Morelos", value: "Jose-Maria-Morelos" },
          { label: "Lazaro Cardenas", value: "Lazaro-Cardenas" },
          { label: "Othon P. Blanco", value: "Othon-P.-Blanco" },
          { label: "Puerto Morelos", value: "Puerto-Morelos" },
          { label: "Solidaridad", value: "Solidaridad" },
          { label: "Tulum", value: "Tulum" },
        ],
      },
      {
        label: "San Luis Potosi",
        value: "San-Luis-Potosi",
        subregions: [
          { label: "Ahualulco", value: "Ahualulco" },
          { label: "Alaquines", value: "Alaquines" },
          { label: "Aquismon", value: "Aquismon" },
          {
            label: "Armadillo de los Infante",
            value: "Armadillo-de-los-Infante",
          },
          { label: "Axtla de Terrazas", value: "Axtla-de-Terrazas" },
          { label: "Cardenas", value: "Cardenas" },
          { label: "Catorce", value: "Catorce" },
          { label: "Cedral", value: "Cedral" },
          { label: "Cerritos", value: "Cerritos" },
          { label: "Cerro de San Pedro", value: "Cerro-de-San-Pedro" },
          { label: "Charcas", value: "Charcas" },
          { label: "Ciudad Fernandez", value: "Ciudad-Fernandez" },
          { label: "Ciudad Valles", value: "Ciudad-Valles" },
          { label: "Ciudad del Maiz", value: "Ciudad-del-Maiz" },
          { label: "Coxcatlan", value: "Coxcatlan" },
          { label: "Ebano", value: "Ebano" },
          { label: "El Naranjo", value: "El-Naranjo" },
          { label: "Guadalcazar", value: "Guadalcazar" },
          { label: "Huehuetlan", value: "Huehuetlan" },
          { label: "Lagunillas", value: "Lagunillas" },
          { label: "Matehuala", value: "Matehuala" },
          { label: "Matlapa", value: "Matlapa" },
          { label: "Mexquitic de Carmona", value: "Mexquitic-de-Carmona" },
          { label: "Moctezuma", value: "Moctezuma" },
          { label: "Rayon", value: "Rayon" },
          { label: "Rioverde", value: "Rioverde" },
          { label: "Salinas", value: "Salinas" },
          { label: "San Antonio", value: "San-Antonio" },
          { label: "San Ciro de Acosta", value: "San-Ciro-de-Acosta" },
          { label: "San Luis Potosi", value: "San-Luis-Potosi" },
          {
            label: "San Martin Chalchicuautla",
            value: "San-Martin-Chalchicuautla",
          },
          {
            label: "San Nicolas Tolentino",
            value: "San-Nicolas-Tolentino",
          },
          {
            label: "San Vicente Tancuayalab",
            value: "San-Vicente-Tancuayalab",
          },
          { label: "Santa Catarina", value: "Santa-Catarina" },
          { label: "Santa Maria del Rio", value: "Santa-Maria-del-Rio" },
          { label: "Santo Domingo", value: "Santo-Domingo" },
          {
            label: "Soledad de Graciano Sanchez",
            value: "Soledad-de-Graciano-Sanchez",
          },
          { label: "Tamasopo", value: "Tamasopo" },
          { label: "Tamazunchale", value: "Tamazunchale" },
          { label: "Tampacan", value: "Tampacan" },
          { label: "Tampamolon Corona", value: "Tampamolon-Corona" },
          { label: "Tamuin", value: "Tamuin" },
          { label: "Tancanhuitz", value: "Tancanhuitz" },
          { label: "Tanlajas", value: "Tanlajas" },
          { label: "Tanquian de Escobedo", value: "Tanquian-de-Escobedo" },
          { label: "Tierra Nueva", value: "Tierra-Nueva" },
          { label: "Vanegas", value: "Vanegas" },
          { label: "Venado", value: "Venado" },
          { label: "Villa Hidalgo", value: "Villa-Hidalgo" },
          { label: "Villa Juarez", value: "Villa-Juarez" },
          { label: "Villa de Arista", value: "Villa-de-Arista" },
          { label: "Villa de Arriaga", value: "Villa-de-Arriaga" },
          { label: "Villa de Guadalupe", value: "Villa-de-Guadalupe" },
          { label: "Villa de Ramos", value: "Villa-de-Ramos" },
          { label: "Villa de Reyes", value: "Villa-de-Reyes" },
          { label: "Villa de la Paz", value: "Villa-de-la-Paz" },
          { label: "Xilitla", value: "Xilitla" },
          { label: "Zaragoza", value: "Zaragoza" },
        ],
      },
      {
        label: "Sinaloa",
        value: "Sinaloa",
        subregions: [
          { label: "Ahome", value: "Ahome" },
          { label: "Angostura", value: "Angostura" },
          { label: "Badiraguato", value: "Badiraguato" },
          { label: "Choix", value: "Choix" },
          { label: "Concordia", value: "Concordia" },
          { label: "Cosala", value: "Cosala" },
          { label: "Culiacan", value: "Culiacan" },
          { label: "El Fuerte", value: "El-Fuerte" },
          { label: "Elota", value: "Elota" },
          { label: "Escuinapa", value: "Escuinapa" },
          { label: "Guasave", value: "Guasave" },
          { label: "Mazatlan", value: "Mazatlan" },
          { label: "Mocorito", value: "Mocorito" },
          { label: "Navolato", value: "Navolato" },
          { label: "Rosario", value: "Rosario" },
          { label: "Salvador Alvarado", value: "Salvador-Alvarado" },
          { label: "San Ignacio", value: "San-Ignacio" },
          { label: "Sinaloa", value: "Sinaloa" },
        ],
      },
      {
        label: "Sonora",
        value: "Sonora",
        subregions: [
          { label: "Aconchi", value: "Aconchi" },
          { label: "Agua Prieta", value: "Agua-Prieta" },
          { label: "Alamos", value: "Alamos" },
          { label: "Altar", value: "Altar" },
          { label: "Arivechi", value: "Arivechi" },
          { label: "Arizpe", value: "Arizpe" },
          { label: "Atil", value: "Atil" },
          { label: "Bacadehuachi", value: "Bacadehuachi" },
          { label: "Bacanora", value: "Bacanora" },
          { label: "Bacerac", value: "Bacerac" },
          { label: "Bacoachi", value: "Bacoachi" },
          { label: "Bacum", value: "Bacum" },
          { label: "Banamichi", value: "Banamichi" },
          { label: "Baviacora", value: "Baviacora" },
          { label: "Bavispe", value: "Bavispe" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          { label: "Benjamin Hill", value: "Benjamin-Hill" },
          { label: "Caborca", value: "Caborca" },
          { label: "Cajeme", value: "Cajeme" },
          { label: "Cananea", value: "Cananea" },
          { label: "Carbo", value: "Carbo" },
          { label: "Cucurpe", value: "Cucurpe" },
          { label: "Cumpas", value: "Cumpas" },
          { label: "Divisaderos", value: "Divisaderos" },
          { label: "Empalme", value: "Empalme" },
          { label: "Etchojoa", value: "Etchojoa" },
          { label: "Fronteras", value: "Fronteras" },
          {
            label: "General Plutarco Elias Calles",
            value: "General-Plutarco-Elias-Calles",
          },
          { label: "Granados", value: "Granados" },
          { label: "Guaymas", value: "Guaymas" },
          { label: "Hermosillo", value: "Hermosillo" },
          { label: "Huachinera", value: "Huachinera" },
          { label: "Huasabas", value: "Huasabas" },
          { label: "Huatabampo", value: "Huatabampo" },
          { label: "Huepac", value: "Huepac" },
          { label: "Imuris", value: "Imuris" },
          { label: "La Colorada", value: "La-Colorada" },
          { label: "Magdalena", value: "Magdalena" },
          { label: "Mazatan", value: "Mazatan" },
          { label: "Moctezuma", value: "Moctezuma" },
          { label: "Naco", value: "Naco" },
          { label: "Nacori Chico", value: "Nacori-Chico" },
          { label: "Nacozari de Garcia", value: "Nacozari-de-Garcia" },
          { label: "Navojoa", value: "Navojoa" },
          { label: "Nogales", value: "Nogales" },
          { label: "Onavas", value: "Onavas" },
          { label: "Opodepe", value: "Opodepe" },
          { label: "Oquitoa", value: "Oquitoa" },
          { label: "Pitiquito", value: "Pitiquito" },
          { label: "Puerto Penasco", value: "Puerto-Penasco" },
          { label: "Quiriego", value: "Quiriego" },
          { label: "Rayon", value: "Rayon" },
          { label: "Rosario", value: "Rosario" },
          { label: "Sahuaripa", value: "Sahuaripa" },
          { label: "San Felipe de Jesus", value: "San-Felipe-de-Jesus" },
          {
            label: "San Ignacio Rio Muerto",
            value: "San-Ignacio-Rio-Muerto",
          },
          { label: "San Javier", value: "San-Javier" },
          {
            label: "San Luis Rio Colorado",
            value: "San-Luis-Rio-Colorado",
          },
          {
            label: "San Miguel de Horcasitas",
            value: "San-Miguel-de-Horcasitas",
          },
          {
            label: "San Pedro de la Cueva",
            value: "San-Pedro-de-la-Cueva",
          },
          { label: "Santa Ana", value: "Santa-Ana" },
          { label: "Santa Cruz", value: "Santa-Cruz" },
          { label: "Saric", value: "Saric" },
          { label: "Soyopa", value: "Soyopa" },
          { label: "Suaqui Grande", value: "Suaqui-Grande" },
          { label: "Tepache", value: "Tepache" },
          { label: "Trincheras", value: "Trincheras" },
          { label: "Tubutama", value: "Tubutama" },
          { label: "Ures", value: "Ures" },
          { label: "Villa Hidalgo", value: "Villa-Hidalgo" },
          { label: "Villa Pesqueira", value: "Villa-Pesqueira" },
          { label: "Yecora", value: "Yecora" },
        ],
      },
      {
        label: "Tabasco",
        value: "Tabasco",
        subregions: [
          { label: "Balancan", value: "Balancan" },
          { label: "Cardenas", value: "Cardenas" },
          { label: "Centla", value: "Centla" },
          { label: "Centro", value: "Centro" },
          { label: "Comalcalco", value: "Comalcalco" },
          { label: "Cunduacan", value: "Cunduacan" },
          { label: "Emiliano Zapata", value: "Emiliano-Zapata" },
          { label: "Huimanguillo", value: "Huimanguillo" },
          { label: "Jalapa", value: "Jalapa" },
          { label: "Jalpa de Mendez", value: "Jalpa-de-Mendez" },
          { label: "Jonuta", value: "Jonuta" },
          { label: "Macuspana", value: "Macuspana" },
          { label: "Nacajuca", value: "Nacajuca" },
          { label: "Paraiso", value: "Paraiso" },
          { label: "Tacotalpa", value: "Tacotalpa" },
          { label: "Teapa", value: "Teapa" },
          { label: "Tenosique", value: "Tenosique" },
        ],
      },
      {
        label: "Tamaulipas",
        value: "Tamaulipas",
        subregions: [
          { label: "Abasolo", value: "Abasolo" },
          { label: "Aldama", value: "Aldama" },
          { label: "Altamira", value: "Altamira" },
          { label: "Antiguo Morelos", value: "Antiguo-Morelos" },
          { label: "Burgos", value: "Burgos" },
          { label: "Bustamante", value: "Bustamante" },
          { label: "Camargo", value: "Camargo" },
          { label: "Casas", value: "Casas" },
          { label: "Ciudad Madero", value: "Ciudad-Madero" },
          { label: "Cruillas", value: "Cruillas" },
          { label: "El Mante", value: "El-Mante" },
          { label: "Gomez Farias", value: "Gomez-Farias" },
          { label: "Gonzalez", value: "Gonzalez" },
          { label: "Guemez", value: "Guemez" },
          { label: "Guerrero", value: "Guerrero" },
          { label: "Gustavo Diaz Ordaz", value: "Gustavo-Diaz-Ordaz" },
          { label: "Hidalgo", value: "Hidalgo" },
          { label: "Jaumave", value: "Jaumave" },
          { label: "Jimenez", value: "Jimenez" },
          { label: "Llera", value: "Llera" },
          { label: "Mainero", value: "Mainero" },
          { label: "Matamoros", value: "Matamoros" },
          { label: "Mendez", value: "Mendez" },
          { label: "Mier", value: "Mier" },
          { label: "Miguel Aleman", value: "Miguel-Aleman" },
          { label: "Miquihuana", value: "Miquihuana" },
          { label: "Nuevo Laredo", value: "Nuevo-Laredo" },
          { label: "Nuevo Morelos", value: "Nuevo-Morelos" },
          { label: "Ocampo", value: "Ocampo" },
          { label: "Padilla", value: "Padilla" },
          { label: "Palmillas", value: "Palmillas" },
          { label: "Reynosa", value: "Reynosa" },
          { label: "Rio Bravo", value: "Rio-Bravo" },
          { label: "San Carlos", value: "San-Carlos" },
          { label: "San Fernando", value: "San-Fernando" },
          { label: "San Nicolas", value: "San-Nicolas" },
          { label: "Soto la Marina", value: "Soto-la-Marina" },
          { label: "Tampico", value: "Tampico" },
          { label: "Tula", value: "Tula" },
          { label: "Valle Hermoso", value: "Valle-Hermoso" },
          { label: "Victoria", value: "Victoria" },
          { label: "Villagran", value: "Villagran" },
          { label: "Xicotencatl", value: "Xicotencatl" },
        ],
      },
      {
        label: "Tlaxcala",
        value: "Tlaxcala",
        subregions: [
          {
            label: "Acuamanala de Miguel Hidalgo",
            value: "Acuamanala-de-Miguel-Hidalgo",
          },
          { label: "Amaxac de Guerrero", value: "Amaxac-de-Guerrero" },
          {
            label: "Apetatitlan de Antonio Carvajal",
            value: "Apetatitlan-de-Antonio-Carvajal",
          },
          { label: "Apizaco", value: "Apizaco" },
          { label: "Atlangatepec", value: "Atlangatepec" },
          { label: "Atltzayanca", value: "Atltzayanca" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          { label: "Calpulalpan", value: "Calpulalpan" },
          { label: "Chiautempan", value: "Chiautempan" },
          {
            label: "Contla de Juan Cuamatzi",
            value: "Contla-de-Juan-Cuamatzi",
          },
          { label: "Cuapiaxtla", value: "Cuapiaxtla" },
          { label: "Cuaxomulco", value: "Cuaxomulco" },
          {
            label: "El Carmen Tequexquitla",
            value: "El-Carmen-Tequexquitla",
          },
          { label: "Emiliano Zapata", value: "Emiliano-Zapata" },
          { label: "Espanita", value: "Espanita" },
          { label: "Huamantla", value: "Huamantla" },
          { label: "Hueyotlipan", value: "Hueyotlipan" },
          {
            label: "Ixtacuixtla de Mariano Matamoros",
            value: "Ixtacuixtla-de-Mariano-Matamoros",
          },
          { label: "Ixtenco", value: "Ixtenco" },
          {
            label: "La Magdalena Tlaltelulco",
            value: "La-Magdalena-Tlaltelulco",
          },
          { label: "Lazaro Cardenas", value: "Lazaro-Cardenas" },
          {
            label: "Mazatecochco de Jose Maria Morelos",
            value: "Mazatecochco-de-Jose-Maria-Morelos",
          },
          {
            label: "Munoz de Domingo Arenas",
            value: "Munoz-de-Domingo-Arenas",
          },
          {
            label: "Nanacamilpa de Mariano Arista",
            value: "Nanacamilpa-de-Mariano-Arista",
          },
          { label: "Nativitas", value: "Nativitas" },
          { label: "Panotla", value: "Panotla" },
          {
            label: "Papalotla de Xicohtencatl",
            value: "Papalotla-de-Xicohtencatl",
          },
          { label: "San Damian Texoloc", value: "San-Damian-Texoloc" },
          {
            label: "San Francisco Tetlanohcan",
            value: "San-Francisco-Tetlanohcan",
          },
          {
            label: "San Jeronimo Zacualpan",
            value: "San-Jeronimo-Zacualpan",
          },
          { label: "San Jose Teacalco", value: "San-Jose-Teacalco" },
          { label: "San Juan Huactzinco", value: "San-Juan-Huactzinco" },
          {
            label: "San Lorenzo Axocomanitla",
            value: "San-Lorenzo-Axocomanitla",
          },
          { label: "San Lucas Tecopilco", value: "San-Lucas-Tecopilco" },
          { label: "San Pablo del Monte", value: "San-Pablo-del-Monte" },
          {
            label: "Sanctorum de Lazaro Cardenas",
            value: "Sanctorum-de-Lazaro-Cardenas",
          },
          { label: "Santa Ana Nopalucan", value: "Santa-Ana-Nopalucan" },
          {
            label: "Santa Apolonia Teacalco",
            value: "Santa-Apolonia-Teacalco",
          },
          {
            label: "Santa Catarina Ayometla",
            value: "Santa-Catarina-Ayometla",
          },
          { label: "Santa Cruz Quilehtla", value: "Santa-Cruz-Quilehtla" },
          { label: "Santa Cruz Tlaxcala", value: "Santa-Cruz-Tlaxcala" },
          {
            label: "Santa Isabel Xiloxoxtla",
            value: "Santa-Isabel-Xiloxoxtla",
          },
          { label: "Tenancingo", value: "Tenancingo" },
          { label: "Teolocholco", value: "Teolocholco" },
          {
            label: "Tepetitla de Lardizabal",
            value: "Tepetitla-de-Lardizabal",
          },
          { label: "Tepeyanco", value: "Tepeyanco" },
          { label: "Terrenate", value: "Terrenate" },
          {
            label: "Tetla de la Solidaridad",
            value: "Tetla-de-la-Solidaridad",
          },
          { label: "Tetlatlahuca", value: "Tetlatlahuca" },
          { label: "Tlaxcala", value: "Tlaxcala" },
          { label: "Tlaxco", value: "Tlaxco" },
          { label: "Tocatlan", value: "Tocatlan" },
          { label: "Totolac", value: "Totolac" },
          { label: "Tzompantepec", value: "Tzompantepec" },
          { label: "Xaloztoc", value: "Xaloztoc" },
          { label: "Xaltocan", value: "Xaltocan" },
          { label: "Xicohtzinco", value: "Xicohtzinco" },
          { label: "Yauhquemehcan", value: "Yauhquemehcan" },
          { label: "Zacatelco", value: "Zacatelco" },
          {
            label: "Ziltlaltepec de Trinidad Sanchez Santos",
            value: "Ziltlaltepec-de-Trinidad-Sanchez-Santos",
          },
        ],
      },
      {
        label: "Veracruz",
        value: "Veracruz",
        subregions: [
          { label: "Acajete", value: "Acajete" },
          { label: "Acatlan", value: "Acatlan" },
          { label: "Acayucan", value: "Acayucan" },
          { label: "Actopan", value: "Actopan" },
          { label: "Acula", value: "Acula" },
          { label: "Acultzingo", value: "Acultzingo" },
          { label: "Agua Dulce", value: "Agua-Dulce" },
          { label: "Alamo Temapache", value: "Alamo-Temapache" },
          { label: "Alpatlahuac", value: "Alpatlahuac" },
          {
            label: "Alto Lucero de Gutierrez Barrios",
            value: "Alto-Lucero-de-Gutierrez-Barrios",
          },
          { label: "Altotonga", value: "Altotonga" },
          { label: "Alvarado", value: "Alvarado" },
          { label: "Amatitlan", value: "Amatitlan" },
          { label: "Amatlan de los Reyes", value: "Amatlan-de-los-Reyes" },
          { label: "Angel R. Cabada", value: "Angel-R.-Cabada" },
          { label: "Apazapan", value: "Apazapan" },
          { label: "Aquila", value: "Aquila" },
          { label: "Astacinga", value: "Astacinga" },
          { label: "Atlahuilco", value: "Atlahuilco" },
          { label: "Atoyac", value: "Atoyac" },
          { label: "Atzacan", value: "Atzacan" },
          { label: "Atzalan", value: "Atzalan" },
          { label: "Ayahualulco", value: "Ayahualulco" },
          { label: "Banderilla", value: "Banderilla" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          { label: "Boca del Rio", value: "Boca-del-Rio" },
          { label: "Calcahualco", value: "Calcahualco" },
          { label: "Camaron de Tejeda", value: "Camaron-de-Tejeda" },
          { label: "Camerino Z. Mendoza", value: "Camerino-Z.-Mendoza" },
          { label: "Carlos A. Carrillo", value: "Carlos-A.-Carrillo" },
          { label: "Carrillo Puerto", value: "Carrillo-Puerto" },
          { label: "Castillo de Teayo", value: "Castillo-de-Teayo" },
          { label: "Catemaco", value: "Catemaco" },
          { label: "Cazones de Herrera", value: "Cazones-de-Herrera" },
          { label: "Cerro Azul", value: "Cerro-Azul" },
          { label: "Chacaltianguis", value: "Chacaltianguis" },
          { label: "Chalma", value: "Chalma" },
          { label: "Chiconamel", value: "Chiconamel" },
          { label: "Chiconquiaco", value: "Chiconquiaco" },
          { label: "Chicontepec", value: "Chicontepec" },
          { label: "Chinameca", value: "Chinameca" },
          {
            label: "Chinampa de Gorostiza",
            value: "Chinampa-de-Gorostiza",
          },
          { label: "Chocaman", value: "Chocaman" },
          { label: "Chontla", value: "Chontla" },
          { label: "Chumatlan", value: "Chumatlan" },
          { label: "Citlaltepetl", value: "Citlaltepetl" },
          { label: "Coacoatzintla", value: "Coacoatzintla" },
          { label: "Coahuitlan", value: "Coahuitlan" },
          { label: "Coatepec", value: "Coatepec" },
          { label: "Coatzacoalcos", value: "Coatzacoalcos" },
          { label: "Coatzintla", value: "Coatzintla" },
          { label: "Coetzala", value: "Coetzala" },
          { label: "Colipa", value: "Colipa" },
          { label: "Comapa", value: "Comapa" },
          { label: "Cordoba", value: "Cordoba" },
          {
            label: "Cosamaloapan de Carpio",
            value: "Cosamaloapan-de-Carpio",
          },
          {
            label: "Cosautlan de Carvajal",
            value: "Cosautlan-de-Carvajal",
          },
          { label: "Coscomatepec", value: "Coscomatepec" },
          { label: "Cosoleacaque", value: "Cosoleacaque" },
          { label: "Cotaxtla", value: "Cotaxtla" },
          { label: "Coxquihui", value: "Coxquihui" },
          { label: "Coyutla", value: "Coyutla" },
          { label: "Cuichapa", value: "Cuichapa" },
          { label: "Cuitlahuac", value: "Cuitlahuac" },
          { label: "El Higo", value: "El-Higo" },
          { label: "Emiliano Zapata", value: "Emiliano-Zapata" },
          { label: "Espinal", value: "Espinal" },
          { label: "Filomeno Mata", value: "Filomeno-Mata" },
          { label: "Fortin", value: "Fortin" },
          { label: "Gutierrez Zamora", value: "Gutierrez-Zamora" },
          { label: "Hidalgotitlan", value: "Hidalgotitlan" },
          { label: "Huatusco", value: "Huatusco" },
          { label: "Huayacocotla", value: "Huayacocotla" },
          { label: "Hueyapan de Ocampo", value: "Hueyapan-de-Ocampo" },
          {
            label: "Huiloapan de Cuauhtemoc",
            value: "Huiloapan-de-Cuauhtemoc",
          },
          { label: "Ignacio de la Llave", value: "Ignacio-de-la-Llave" },
          { label: "Ilamatlan", value: "Ilamatlan" },
          { label: "Isla", value: "Isla" },
          { label: "Ixcatepec", value: "Ixcatepec" },
          {
            label: "Ixhuacan de los Reyes",
            value: "Ixhuacan-de-los-Reyes",
          },
          { label: "Ixhuatlan de Madero", value: "Ixhuatlan-de-Madero" },
          { label: "Ixhuatlan del Cafe", value: "Ixhuatlan-del-Cafe" },
          {
            label: "Ixhuatlan del Sureste",
            value: "Ixhuatlan-del-Sureste",
          },
          { label: "Ixhuatlancillo", value: "Ixhuatlancillo" },
          { label: "Ixmatlahuacan", value: "Ixmatlahuacan" },
          { label: "Ixtaczoquitlan", value: "Ixtaczoquitlan" },
          { label: "Jalacingo", value: "Jalacingo" },
          { label: "Jalcomulco", value: "Jalcomulco" },
          { label: "Jaltipan", value: "Jaltipan" },
          { label: "Jamapa", value: "Jamapa" },
          { label: "Jesus Carranza", value: "Jesus-Carranza" },
          { label: "Jilotepec", value: "Jilotepec" },
          { label: "Jose Azueta", value: "Jose-Azueta" },
          { label: "Juan Rodriguez Clara", value: "Juan-Rodriguez-Clara" },
          { label: "Juchique de Ferrer", value: "Juchique-de-Ferrer" },
          { label: "La Antigua", value: "La-Antigua" },
          { label: "La Perla", value: "La-Perla" },
          { label: "Landero y Coss", value: "Landero-y-Coss" },
          { label: "Las Choapas", value: "Las-Choapas" },
          { label: "Las Minas", value: "Las-Minas" },
          { label: "Las Vigas de Ramirez", value: "Las-Vigas-de-Ramirez" },
          { label: "Lerdo de Tejada", value: "Lerdo-de-Tejada" },
          { label: "Los Reyes", value: "Los-Reyes" },
          { label: "Magdalena", value: "Magdalena" },
          { label: "Maltrata", value: "Maltrata" },
          {
            label: "Manlio Fabio Altamirano",
            value: "Manlio-Fabio-Altamirano",
          },
          { label: "Mariano Escobedo", value: "Mariano-Escobedo" },
          { label: "Martinez de la Torre", value: "Martinez-de-la-Torre" },
          { label: "Mecatlan", value: "Mecatlan" },
          { label: "Mecayapan", value: "Mecayapan" },
          { label: "Medellin de Bravo", value: "Medellin-de-Bravo" },
          { label: "Miahuatlan", value: "Miahuatlan" },
          { label: "Minatitlan", value: "Minatitlan" },
          { label: "Misantla", value: "Misantla" },
          { label: "Mixtla de Altamirano", value: "Mixtla-de-Altamirano" },
          { label: "Moloacan", value: "Moloacan" },
          {
            label: "Nanchital de Lazaro Cardenas del Rio",
            value: "Nanchital-de-Lazaro-Cardenas-del-Rio",
          },
          { label: "Naolinco", value: "Naolinco" },
          { label: "Naranjal", value: "Naranjal" },
          { label: "Naranjos Amatlan", value: "Naranjos-Amatlan" },
          { label: "Nautla", value: "Nautla" },
          { label: "Nogales", value: "Nogales" },
          { label: "Oluta", value: "Oluta" },
          { label: "Omealca", value: "Omealca" },
          { label: "Orizaba", value: "Orizaba" },
          { label: "Otatitlan", value: "Otatitlan" },
          { label: "Oteapan", value: "Oteapan" },
          {
            label: "Ozuluama de Mascarenas",
            value: "Ozuluama-de-Mascarenas",
          },
          { label: "Pajapan", value: "Pajapan" },
          { label: "Panuco", value: "Panuco" },
          { label: "Papantla", value: "Papantla" },
          { label: "Paso de Ovejas", value: "Paso-de-Ovejas" },
          { label: "Paso del Macho", value: "Paso-del-Macho" },
          { label: "Perote", value: "Perote" },
          { label: "Platon Sanchez", value: "Platon-Sanchez" },
          { label: "Playa Vicente", value: "Playa-Vicente" },
          { label: "Poza Rica de Hidalgo", value: "Poza-Rica-de-Hidalgo" },
          { label: "Pueblo Viejo", value: "Pueblo-Viejo" },
          { label: "Puente Nacional", value: "Puente-Nacional" },
          { label: "Rafael Delgado", value: "Rafael-Delgado" },
          { label: "Rafael Lucio", value: "Rafael-Lucio" },
          { label: "Rio Blanco", value: "Rio-Blanco" },
          { label: "Saltabarranca", value: "Saltabarranca" },
          { label: "San Andres Tenejapan", value: "San-Andres-Tenejapan" },
          { label: "San Andres Tuxtla", value: "San-Andres-Tuxtla" },
          { label: "San Juan Evangelista", value: "San-Juan-Evangelista" },
          { label: "San Rafael", value: "San-Rafael" },
          { label: "Santiago Sochiapan", value: "Santiago-Sochiapan" },
          { label: "Santiago Tuxtla", value: "Santiago-Tuxtla" },
          { label: "Sayula de Aleman", value: "Sayula-de-Aleman" },
          { label: "Sochiapa", value: "Sochiapa" },
          { label: "Soconusco", value: "Soconusco" },
          { label: "Soledad Atzompa", value: "Soledad-Atzompa" },
          { label: "Soledad de Doblado", value: "Soledad-de-Doblado" },
          { label: "Soteapan", value: "Soteapan" },
          { label: "Tamalin", value: "Tamalin" },
          { label: "Tamiahua", value: "Tamiahua" },
          { label: "Tampico Alto", value: "Tampico-Alto" },
          { label: "Tancoco", value: "Tancoco" },
          { label: "Tantima", value: "Tantima" },
          { label: "Tantoyuca", value: "Tantoyuca" },
          {
            label: "Tatahuicapan de Juarez",
            value: "Tatahuicapan-de-Juarez",
          },
          { label: "Tatatila", value: "Tatatila" },
          { label: "Tecolutla", value: "Tecolutla" },
          { label: "Tehuipango", value: "Tehuipango" },
          { label: "Tempoal", value: "Tempoal" },
          { label: "Tenampa", value: "Tenampa" },
          { label: "Tenochtitlan", value: "Tenochtitlan" },
          { label: "Teocelo", value: "Teocelo" },
          { label: "Tepatlaxco", value: "Tepatlaxco" },
          { label: "Tepetlan", value: "Tepetlan" },
          { label: "Tepetzintla", value: "Tepetzintla" },
          { label: "Tequila", value: "Tequila" },
          { label: "Texcatepec", value: "Texcatepec" },
          { label: "Texhuacan", value: "Texhuacan" },
          { label: "Texistepec", value: "Texistepec" },
          { label: "Tezonapa", value: "Tezonapa" },
          { label: "Tierra Blanca", value: "Tierra-Blanca" },
          { label: "Tihuatlan", value: "Tihuatlan" },
          { label: "Tlachichilco", value: "Tlachichilco" },
          { label: "Tlacojalpan", value: "Tlacojalpan" },
          { label: "Tlacolulan", value: "Tlacolulan" },
          { label: "Tlacotalpan", value: "Tlacotalpan" },
          { label: "Tlacotepec de Mejia", value: "Tlacotepec-de-Mejia" },
          { label: "Tlalixcoyan", value: "Tlalixcoyan" },
          { label: "Tlalnelhuayocan", value: "Tlalnelhuayocan" },
          { label: "Tlaltetela", value: "Tlaltetela" },
          { label: "Tlapacoyan", value: "Tlapacoyan" },
          { label: "Tlaquilpa", value: "Tlaquilpa" },
          { label: "Tlilapan", value: "Tlilapan" },
          { label: "Tomatlan", value: "Tomatlan" },
          { label: "Tonayan", value: "Tonayan" },
          { label: "Totutla", value: "Totutla" },
          { label: "Tres Valles", value: "Tres-Valles" },
          { label: "Tuxpan", value: "Tuxpan" },
          { label: "Tuxtilla", value: "Tuxtilla" },
          { label: "Ursulo Galvan", value: "Ursulo-Galvan" },
          { label: "Uxpanapa", value: "Uxpanapa" },
          { label: "Vega de Alatorre", value: "Vega-de-Alatorre" },
          { label: "Veracruz", value: "Veracruz" },
          { label: "Villa Aldama", value: "Villa-Aldama" },
          { label: "Xalapa", value: "Xalapa" },
          { label: "Xico", value: "Xico" },
          { label: "Xoxocotla", value: "Xoxocotla" },
          { label: "Yanga", value: "Yanga" },
          { label: "Yecuatla", value: "Yecuatla" },
          { label: "Zacualpan", value: "Zacualpan" },
          { label: "Zaragoza", value: "Zaragoza" },
          { label: "Zentla", value: "Zentla" },
          { label: "Zongolica", value: "Zongolica" },
          {
            label: "Zontecomatlan de Lopez y Fuentes",
            value: "Zontecomatlan-de-Lopez-y-Fuentes",
          },
          { label: "Zozocolco de Hidalgo", value: "Zozocolco-de-Hidalgo" },
        ],
      },
      {
        label: "Yucatan",
        value: "Yucatan",
        subregions: [
          { label: "Abala", value: "Abala" },
          { label: "Acanceh", value: "Acanceh" },
          { label: "Akil", value: "Akil" },
          { label: "Baca", value: "Baca" },
          { label: "Bokoba", value: "Bokoba" },
          { label: "Buctzotz", value: "Buctzotz" },
          { label: "Cacalchen", value: "Cacalchen" },
          { label: "Calotmul", value: "Calotmul" },
          { label: "Cansahcab", value: "Cansahcab" },
          { label: "Cantamayec", value: "Cantamayec" },
          { label: "Celestun", value: "Celestun" },
          { label: "Cenotillo", value: "Cenotillo" },
          { label: "Chacsinkin", value: "Chacsinkin" },
          { label: "Chankom", value: "Chankom" },
          { label: "Chapab", value: "Chapab" },
          { label: "Chemax", value: "Chemax" },
          { label: "Chichimila", value: "Chichimila" },
          { label: "Chicxulub Pueblo", value: "Chicxulub-Pueblo" },
          { label: "Chikindzonot", value: "Chikindzonot" },
          { label: "Chochola", value: "Chochola" },
          { label: "Chumayel", value: "Chumayel" },
          { label: "Conkal", value: "Conkal" },
          { label: "Cuncunul", value: "Cuncunul" },
          { label: "Cuzama", value: "Cuzama" },
          { label: "Dzan", value: "Dzan" },
          { label: "Dzemul", value: "Dzemul" },
          { label: "Dzidzantun", value: "Dzidzantun" },
          { label: "Dzilam Gonzalez", value: "Dzilam-Gonzalez" },
          { label: "Dzilam de Bravo", value: "Dzilam-de-Bravo" },
          { label: "Dzitas", value: "Dzitas" },
          { label: "Dzoncauich", value: "Dzoncauich" },
          { label: "Espita", value: "Espita" },
          { label: "Halacho", value: "Halacho" },
          { label: "Hocaba", value: "Hocaba" },
          { label: "Hoctun", value: "Hoctun" },
          { label: "Homun", value: "Homun" },
          { label: "Huhi", value: "Huhi" },
          { label: "Hunucma", value: "Hunucma" },
          { label: "Ixil", value: "Ixil" },
          { label: "Izamal", value: "Izamal" },
          { label: "Kanasin", value: "Kanasin" },
          { label: "Kantunil", value: "Kantunil" },
          { label: "Kaua", value: "Kaua" },
          { label: "Kinchil", value: "Kinchil" },
          { label: "Kopoma", value: "Kopoma" },
          { label: "Mama", value: "Mama" },
          { label: "Mani", value: "Mani" },
          { label: "Maxcanu", value: "Maxcanu" },
          { label: "Mayapan", value: "Mayapan" },
          { label: "Merida", value: "Merida" },
          { label: "Mococha", value: "Mococha" },
          { label: "Motul", value: "Motul" },
          { label: "Muna", value: "Muna" },
          { label: "Muxupip", value: "Muxupip" },
          { label: "Opichen", value: "Opichen" },
          { label: "Oxkutzcab", value: "Oxkutzcab" },
          { label: "Panaba", value: "Panaba" },
          { label: "Peto", value: "Peto" },
          { label: "Progreso", value: "Progreso" },
          { label: "Quintana Roo", value: "Quintana-Roo" },
          { label: "Rio Lagartos", value: "Rio-Lagartos" },
          { label: "Sacalum", value: "Sacalum" },
          { label: "Samahil", value: "Samahil" },
          { label: "San Felipe", value: "San-Felipe" },
          { label: "Sanahcat", value: "Sanahcat" },
          { label: "Santa Elena", value: "Santa-Elena" },
          { label: "Seye", value: "Seye" },
          { label: "Sinanche", value: "Sinanche" },
          { label: "Sotuta", value: "Sotuta" },
          { label: "Sucila", value: "Sucila" },
          { label: "Sudzal", value: "Sudzal" },
          { label: "Suma", value: "Suma" },
          { label: "Tahdziu", value: "Tahdziu" },
          { label: "Tahmek", value: "Tahmek" },
          { label: "Teabo", value: "Teabo" },
          { label: "Tecoh", value: "Tecoh" },
          { label: "Tekal de Venegas", value: "Tekal-de-Venegas" },
          { label: "Tekanto", value: "Tekanto" },
          { label: "Tekax", value: "Tekax" },
          { label: "Tekit", value: "Tekit" },
          { label: "Tekom", value: "Tekom" },
          { label: "Telchac Pueblo", value: "Telchac-Pueblo" },
          { label: "Telchac Puerto", value: "Telchac-Puerto" },
          { label: "Temax", value: "Temax" },
          { label: "Temozon", value: "Temozon" },
          { label: "Tepakan", value: "Tepakan" },
          { label: "Tetiz", value: "Tetiz" },
          { label: "Teya", value: "Teya" },
          { label: "Ticul", value: "Ticul" },
          { label: "Timucuy", value: "Timucuy" },
          { label: "Tinum", value: "Tinum" },
          { label: "Tixcacalcupul", value: "Tixcacalcupul" },
          { label: "Tixkokob", value: "Tixkokob" },
          { label: "Tixmehuac", value: "Tixmehuac" },
          { label: "Tixpehual", value: "Tixpehual" },
          { label: "Tizimin", value: "Tizimin" },
          { label: "Tunkas", value: "Tunkas" },
          { label: "Tzucacab", value: "Tzucacab" },
          { label: "Uayma", value: "Uayma" },
          { label: "Ucu", value: "Ucu" },
          { label: "Uman", value: "Uman" },
          { label: "Valladolid", value: "Valladolid" },
          { label: "Xocchel", value: "Xocchel" },
          { label: "Yaxcaba", value: "Yaxcaba" },
          { label: "Yaxkukul", value: "Yaxkukul" },
          { label: "Yobain", value: "Yobain" },
        ],
      },
      {
        label: "Zacatecas",
        value: "Zacatecas",
        subregions: [
          { label: "Apozol", value: "Apozol" },
          { label: "Apulco", value: "Apulco" },
          { label: "Atolinga", value: "Atolinga" },
          { label: "Benito Juarez", value: "Benito-Juarez" },
          { label: "Calera", value: "Calera" },
          {
            label: "Canitas de Felipe Pescador",
            value: "Canitas-de-Felipe-Pescador",
          },
          { label: "Chalchihuites", value: "Chalchihuites" },
          { label: "Concepcion del Oro", value: "Concepcion-del-Oro" },
          { label: "Cuauhtemoc", value: "Cuauhtemoc" },
          {
            label: "El Plateado de Joaquin Amaro",
            value: "El-Plateado-de-Joaquin-Amaro",
          },
          { label: "El Salvador", value: "El-Salvador" },
          { label: "Fresnillo", value: "Fresnillo" },
          { label: "Genaro Codina", value: "Genaro-Codina" },
          {
            label: "General Enrique Estrada",
            value: "General-Enrique-Estrada",
          },
          {
            label: "General Francisco R. Murguia",
            value: "General-Francisco-R.-Murguia",
          },
          {
            label: "General Panfilo Natera",
            value: "General-Panfilo-Natera",
          },
          { label: "Guadalupe", value: "Guadalupe" },
          { label: "Huanusco", value: "Huanusco" },
          { label: "Jalpa", value: "Jalpa" },
          { label: "Jerez", value: "Jerez" },
          { label: "Jimenez del Teul", value: "Jimenez-del-Teul" },
          { label: "Juan Aldama", value: "Juan-Aldama" },
          { label: "Juchipila", value: "Juchipila" },
          { label: "Loreto", value: "Loreto" },
          { label: "Luis Moya", value: "Luis-Moya" },
          { label: "Mazapil", value: "Mazapil" },
          { label: "Melchor Ocampo", value: "Melchor-Ocampo" },
          { label: "Mezquital del Oro", value: "Mezquital-del-Oro" },
          { label: "Miguel Auza", value: "Miguel-Auza" },
          { label: "Momax", value: "Momax" },
          { label: "Monte Escobedo", value: "Monte-Escobedo" },
          { label: "Morelos", value: "Morelos" },
          { label: "Moyahua de Estrada", value: "Moyahua-de-Estrada" },
          { label: "Nochistlan de Mejia", value: "Nochistlan-de-Mejia" },
          { label: "Noria de Angeles", value: "Noria-de-Angeles" },
          { label: "Ojocaliente", value: "Ojocaliente" },
          { label: "Panuco", value: "Panuco" },
          { label: "Pinos", value: "Pinos" },
          { label: "Rio Grande", value: "Rio-Grande" },
          { label: "Sain Alto", value: "Sain-Alto" },
          {
            label: "Santa Maria de la Paz",
            value: "Santa-Maria-de-la-Paz",
          },
          { label: "Sombrerete", value: "Sombrerete" },
          { label: "Susticacan", value: "Susticacan" },
          { label: "Tabasco", value: "Tabasco" },
          { label: "Tepechitlan", value: "Tepechitlan" },
          { label: "Tepetongo", value: "Tepetongo" },
          {
            label: "Teul de Gonzalez Ortega",
            value: "Teul-de-Gonzalez-Ortega",
          },
          {
            label: "Tlaltenango de Sanchez Roman",
            value: "Tlaltenango-de-Sanchez-Roman",
          },
          { label: "Trancoso", value: "Trancoso" },
          {
            label: "Trinidad Garcia de la Cadena",
            value: "Trinidad-Garcia-de-la-Cadena",
          },
          { label: "Valparaiso", value: "Valparaiso" },
          { label: "Vetagrande", value: "Vetagrande" },
          { label: "Villa Garcia", value: "Villa-Garcia" },
          {
            label: "Villa Gonzalez Ortega",
            value: "Villa-Gonzalez-Ortega",
          },
          { label: "Villa Hidalgo", value: "Villa-Hidalgo" },
          { label: "Villa de Cos", value: "Villa-de-Cos" },
          { label: "Villanueva", value: "Villanueva" },
          { label: "Zacatecas", value: "Zacatecas" },
        ],
      },
    ],
  },
];
