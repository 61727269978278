import { getData } from '../services/local-storage.service';
import { constants } from '../utils';
import { ServerResponse, Links, Link } from '../utils/models'

async function getAllLinks (): Promise<Links> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/link`, fetchOpts);
  const response: ServerResponse<Links> = await fetchResponse.json();
  return response.data;
}

async function createLink (school: Link): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(school)
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/link`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

async function updateLink (id: any, data: any): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/link/${id}`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  else return true;
}

export {
  getAllLinks,
  createLink,
  updateLink
}