import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Stack, TextField, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { auth } from "../../../services";
import PasswordChecklist from "react-password-checklist";
import { DialogModal } from "../../../common/components";

type NewPassFormProps = {};

const NewPassForm: FC<NewPassFormProps> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [textModal, setTextModal] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  type formValues = {
    password: string;
  };

  const form = useForm<formValues>({
    defaultValues: {
      password: "",
    },
  });

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setPassword(e.target.value);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  function onSubmit(data: formValues) {
    if (data && password) {
      setLoading(true);
      auth.user.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: (session) => {
            setTextModal(
              "¡Listo! Tu contraseña se ha establecido correctamente. Por favor, inicia sesión con el correo y tu nueva contraseña."
            );
            setOpenDialog(true);
          },
          onFailure: (error) => {
            console.log(error);
            setTextModal("Tu contraseña no pudo ser cambiada, verifica que cumpla con los requerimientos");
            setOpenDialog(true);
          },
        }
      );
    }
  }

  const onAgree = async () => {
    if (
      textModal ===
      "¡Listo! Tu contraseña se ha establecido correctamente. Por favor, inicia sesión con el correo y tu nueva contraseña."
    ) {
      history.replace("/auth/login");
    } else {
      setOpenDialog(false);
    }
  };

  return (
    <Stack spacing={2}>
      <DialogModal
        text={textModal}
        onAgree={onAgree}
        open={openDialog}
        setOpen={setOpenDialog}
        hideCloseButton={true}
      />
      <Typography sx={{ fontSize: '14px', marginRight: '8px' }}>Por seguridad por favor cambia tu contraseña por defecto</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Ingresa tu nueva contraseña"
          type="text"
          {...register("password", {
            required: "La contraseña no puede estar vacia",
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
          value={password}
          onChange={onValueChange}
          sx={{ width: "100%", marginBottom: "12px" }}
        />
        <PasswordChecklist
            className="password-check-list"
            style={{ margin: "12px 6px" }}
            iconSize={11}
            rules={[
              "capital",
              "specialChar",
              "minLength",
              "lowercase",
              "number",
            ]}
            minLength={8}
            value={password}
            messages={{
              minLength: "8 caracteres.",
              specialChar: "Al menos un caracter especial.",
              number: "Al menos un número",
              capital: "Al menos una letra mayúscula.",
              lowercase: "Al menos una letra minúscula.",
            }}
          />
        <Stack>
          <Button type="submit" variant="contained" color="primary">
            {loading ? "Procesando..." : "Actualizar contraseña"}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default NewPassForm;
export { NewPassForm };
