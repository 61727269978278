import { DateRange, TimeUnit, constants } from "../utils";
import { storage } from '.';

export type AnalyticsServerResponse<T> = {
  message: string;
  report: T
}

export interface AnswersPerformance {
  name: string;
  answers: number;
  percentage: number;
}

export type PerformanceReport = {
  n: number;
  chart: Array<AnswersPerformance>
}

export interface AnalyticsDateRange {
  field: string;
  start: string;
  end: string;
}

export interface ReportResponse<R> {
  message: string,
  report: R,
}

interface KpiSummaryDetail {
  n: number,
  percentage: number,
  distinctId: string,
  display: {
    value: string,
    display: string
  }
}

interface KpiTrendPeriod {
  n: number,
  result: number,
  dateRange: Omit<AnalyticsDateRange, 'field'>,
  detail: Array<KpiSummaryDetail>,
}

export interface KpiTrendReport {
  n: number,
  periods: Array<KpiTrendPeriod>
}

export interface KpiTrendReportQuery {
  dateRange: AnalyticsDateRange,
  field: string,
  calc: {
    options?: {
      precision?: number
    }
  },
  timeUnit: TimeUnit,
  filters?: any
}

async function getPerformanceReport (
  range: DateRange,
  dateRangeField = 'createdAt',
  filters?: any
): Promise<PerformanceReport> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: any = {
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
  };
  if (filters && filters.length > 0) {
    body.filters = filters
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/performance/answers-report`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: AnalyticsServerResponse<PerformanceReport> = await fetchResponse.json();
  return response.report;
}

async function getKpiTrendReport (query: KpiTrendReportQuery): Promise<KpiTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/performance/trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

export {
  getPerformanceReport,
  getKpiTrendReport
}