import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { studies } from "../../services";
import {
  setAvailable,
  setStudy,
  selectStudies,
} from "../../store/slices/studies";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import StudiesEmpty from "./widgets/studies-empty.card";
import StudiesCard from "./widgets/studies.card";
import { translateError } from "../../utils/handle-error";
import { CenteredLayout } from "../../common/layouts";
import { Grid } from "@mui/material";

const StartScreen = () => {
  const dispatch = useAppDispatch();
  const myStudies = useAppSelector(selectStudies);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  function chooseStudy(_id: string, path: string = "/performance") {
    dispatch(setStudy(_id));
    history.push(path);
  }

  useEffect(() => {
    async function loadMyStudies() {
      try {
        const studyList = await studies.getStudies();
        if (!studyList) {
          setLoading(false);
          return;
        }
        dispatch(setAvailable(studyList));
        if (studyList?.length === 1) {
          chooseStudy(studyList[0]._id, "/performance")
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        const error = translateError(err);
        alert(error);
      }
    }
    loadMyStudies();
  }, [dispatch]);

  if (loading) return null;

  return (
    <CenteredLayout title="Selecciona un estudio">
      <Grid container>
        {myStudies.length === 0 && (
          <Grid item>
            <StudiesEmpty />
          </Grid>
        )}
        {myStudies.length > 0 &&
          myStudies.map((st) => (
            <Grid item key={st._id}>
              <StudiesCard key={st._id} {...st} onClick={chooseStudy} />
            </Grid>
          ))}
      </Grid>
    </CenteredLayout>
  );
};

export default StartScreen;
