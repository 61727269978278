import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectSlice } from "../../../store/slices/studies";
import { DateRange, toNumber, toPercentage } from "../../../utils";
import { Box, Typography } from "@mui/material";
import {
  PerformanceReport,
  getPerformanceReport,
} from "../../../services/analytics.service";

interface AnalyticsTableProps {
  dateRange: any;
}

interface CellProps {
  name: string; //Titulo de la celda
  number: string; //Total a mostrar
  percentage?: string; //Porcentaje del total a mostrar
  backgroundColor?: string; //Color de fondo para la celda
}

const Cell: FC<CellProps> = ({ name, number, percentage, backgroundColor }) => {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        backgroundColor: backgroundColor ? backgroundColor : "",
        margin: "0px 12px",
        padding: "12px",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <p
          style={{
            margin: "2px",
            paddingRight: "8px",
            fontSize: "12px",
            textAlign: "left",
            fontFamily: "Helvetica",
          }}
        >
          {name}
        </p>
      </div>
      <p
        style={{
          fontSize: "24px",
          textAlign: "left",
          margin: "2px",
          fontFamily: "Helvetica",
        }}
      >
        {number}
      </p>
      <p
        style={{
          fontSize: "16px",
          textAlign: "left",
          margin: "2px",
          fontFamily: "Helvetica",
        }}
      >
        {percentage ?? ""}
      </p>
    </div>
  );
};

const AnalyticsTable: FC<AnalyticsTableProps> = ({ dateRange }) => {
  const studies = useAppSelector(selectSlice);
  const [loading, setLoading] = useState(true);

  const [responsesPerformance, setResponsesPerformance] = useState<
    PerformanceReport | undefined
  >(undefined);

  useEffect(() => {
    async function loadResponsesPerformance() {
      if (!studies.selected) return;
      try {
        setLoading(true);
        let reportResult = await getPerformanceReport(
          dateRange as DateRange,
          "createdAt"
        );
        setResponsesPerformance(reportResult);
      } catch (err: any) {
        console.log("Error al obtener datos de performance: " + err?.message);
      } finally {
        setLoading(false);
      }
    }
    loadResponsesPerformance();
  }, [studies.selected, dateRange]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        padding: "40px 16px 24px 16px",
        marginBottom: "16px",
        minHeight: 200,
        display: "flex",
        flexDirection: "row",
        position: "relative",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#56356c",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          padding: "6px",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            lineHeight: 1,
            color: "white",
            fontFamily: "Helvetica",
          }}
        >
          {toNumber(responsesPerformance?.n) || 0} {`${" respuestas"}`}
        </p>
      </Box>
      {loading ? (
        <p
          style={{
            fontSize: "18px",
            lineHeight: 1,
            fontFamily: "Helvetica",
          }}
        >
          Cargando datos...
        </p>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            margin: "8px auto ",
          }}
        >
          {loading ? (
            <Typography>Cargando datos...</Typography>
          ) : responsesPerformance && responsesPerformance?.n > 0 ? (
            responsesPerformance?.chart.map((element: any, idx: number) => {
              return (
                <Box
                  sx={{
                    backgroundColor: "rgb(243, 243, 243)",
                    marginTop: "24px",
                  }}
                  key={idx}
                >
                  <Cell
                    name={element.name}
                    number={toNumber(element.answers)}
                    percentage={
                      element.percentage
                        ? toPercentage(element.percentage)
                        : undefined
                    }
                  />
                </Box>
              );
            })
          ) : (
            <Typography sx={{ margin: "auto" }}>
              No existen datos en las fechas consultadas
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export { AnalyticsTable };
