import { Component, FC } from 'react';
import { BarChart, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Cell, LabelList } from 'recharts';

interface AnalyticsBarChartProps {
  value: string | number;
  segments: any;
  displayOptions?: any;
}

const formatData = (segments: any | undefined, indexedOptions: any | undefined): any[] => {
  const res: any[] = [];
  if (segments) {
    const keys =  Object.keys(segments);
    keys.forEach(key => {
      if (!['DX_', 'RS_', 'PS_', 'GLOBAL'].some(v => key.includes(v)) ){
        let label = key;
        let id = '';
        const color = getcolor(segments[key]);
        if (indexedOptions && indexedOptions[key]) {
          label = indexedOptions[key].display;
          id = indexedOptions[key].id;
        }
        res.push({
          display: label,
          value:  id,
          percentage: segments[key]+ '%',
          result: segments[key],
          color
        })
      }
    });
  }
  return res;
}

const getcolor = (value: any): any => {
  if(value < 26)
    return "#E33945"
  else if(value > 25 && value < 51)
    return "#FC9416"
  else if(value > 50 && value < 76)
    return "#FFD700"
  else if(value > 75)
    return "#56B448"
}

class CustomAxis extends Component<any> {
  getIcon() {
    const { x, y, payload } = this.props;
    let icon;
    switch (payload.value) {
      case '1':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 21v-13l9 -4l9 4v13"></path>
          <path d="M13 13h4v8h-10v-6h6"></path>
          <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>
        </svg>
        );
        break;
      case '2':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0"></path>
            <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
            <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
            <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
            <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
            <path d="M5 15v1m0 -8v1"></path>
          </svg>
        );
        break;
      case '3':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
            <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
            <path d="M3 6l0 13"></path>
            <path d="M12 6l0 13"></path>
            <path d="M21 6l0 13"></path>
          </svg>
        );
        break;
      case '4':
        icon = (
          <svg  x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 21l18 0"></path>
            <path d="M9 8l1 0"></path>
            <path d="M9 12l1 0"></path>
            <path d="M9 16l1 0"></path>
            <path d="M14 8l1 0"></path>
            <path d="M14 12l1 0"></path>
            <path d="M14 16l1 0"></path>
            <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16"></path>
          </svg>
        );
        break;
      case '5':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
            <path d="M6 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
            <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
          </svg>
        );
        break;
      case '6':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
            <path d="M14.473 17.659a8.897 8.897 0 0 1 -2.473 .341c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
            <path d="M19 16v3"></path>
            <path d="M19 22v.01"></path>
          </svg>
        );
        break;
      case '7':
        icon = (
          <svg x={x - 10} y={y} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
            <path d="M12 11v-6a2 2 0 0 1 2 -2h2v1a2 2 0 0 1 -2 2h-2"></path>
            <path d="M10 10.5c1.333 .667 2.667 .667 4 0"></path>
          </svg>
        );
        break;
      default:
        break;
    }
    return icon;
  }

  render() {
    return (
      <g>
        {this.getIcon()}
      </g>
    );
  }
}

const AnalyticsBarChart: FC<AnalyticsBarChartProps> = ({
  value,
  segments,
  displayOptions
}) => {

  let data = formatData(segments, displayOptions);

  return (
    <ResponsiveContainer
      width="90%"
      height={200}
    >
      <BarChart
        width={400}
        height={200}
        data={data}
      >
        {/*<Tooltip
          cursor={false}
          offset={40}
          separator=": "
          labelFormatter={() => ``}
          formatter={(value: any, name: any, props: any) => [`${value}%`, props?.payload?.display]}
        />*/}
        <XAxis
          padding={{ left: 16, right: 16 }}
          dataKey="value"
          tickLine={false}  tick={<CustomAxis />}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <YAxis hide={true} />
        <Bar
          isAnimationActive={false}
          dataKey="result"
          background={{ fill: "#f1f1f4" }}
          maxBarSize={48}
          fill="#6D31AC"
        >
          <LabelList  fill='black' position='inside' fontSize={14} dataKey='percentage'/>
          {
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color}/>
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export {
  AnalyticsBarChart,
}