import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Stack, TextField, Button, Box, Typography, InputLabel, OutlinedInput, InputAdornment, IconButton, FormControl, FormHelperText } from '@mui/material';
import { auth, storage } from '../../../services'
import { useForm } from 'react-hook-form';
import { messages, constants } from '../../../utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { resendEmail } from '../../../services/users.services';

type LoginFormProps = {};

const LoginForm: FC<LoginFormProps> = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();

  type formValues = {
    email:string;
    password:string;
  }

  const form = useForm<formValues> ({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { register, handleSubmit, formState: { errors } } = form;

  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  };

  async function onSubmit (data: formValues): Promise<void> {
    if(data){
      try {
        setLoading(true);
        const {
          needsNewPass,
          loginSuccess,
          session,
        } = await auth.login(data.email.trim(), data.password);
        if (needsNewPass) {
          setLoading(false);
          history.push('/auth/new-password', { guideMessage: messages.REQUIRED_PASS, needsNewPass });
        }
        if (loginSuccess) {
          const token = session?.getIdToken().getJwtToken();
          const refreshToken = session?.getRefreshToken()?.getToken();
          storage.saveData(constants.AUTH_USERNAME_KEY, data.email);
          storage.saveData(constants.AUTH_USER_TOKEN_KEY, token);
          storage.saveData(constants.AUTH_REFRESH_TOKEN_KEY, refreshToken);
          history.push('/start');
        }
      } catch (err: any) {
        if (err?.message.includes('Incorrect username or password')){
          const e : any ='El usuario o la contraseña son incorrectos'
          setError(e);
        }
        else if(err?.message.includes('Temporary password has expired and must be reset by an administrator')) {
          const e : any ='La contraseña temporal con la que intenta acceder ha expirado, hemos mandado un nuevo correo con una contraseña temporal a su cuenta'
          const response = await resendEmail(data.email.trim())
          console.log(response)
          setError(e);
        }
        else setError(err?.message);
        setLoading(false);
      }
    }
  }

  return (
    <Stack spacing={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Correo"
          {...register("email", {required: "El correo no puede estar vacio"})}
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{width: '100%', marginBottom: '12px'}}
        />
        <FormControl variant="outlined" sx={{ width: "100%", marginBottom: "12px" }}>
          <InputLabel htmlFor="outlined-adornment-password" sx={{background:'white', padding: '0 2px'}}>Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPass ? 'text' : 'password'}
            {...register("password", {required: "La contraseña no puede estar vacia"})}
            error={!!errors.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="my-helper-text" sx={{color:'#d32f2f'}}>{errors.password?.message}</FormHelperText>
        </FormControl>
        <Stack>
          <Button type='submit' variant='contained' color='primary'>{loading ? 'Procesando...' : 'Iniciar sesión'}</Button>
        </Stack>
        <p className='text-errors'>{error}</p>
        <Box sx={{display:'flex', justifyContent: 'center', alignItems:'center'}}>
          <Typography sx={{ fontSize: '14px', marginRight: '8px' }}>Olvide mi contraseña</Typography>
          <Link to="/auth/recover-password" className="form-link">
            <Typography sx={{ fontSize: '14px', marginRight: '8px', fontStyle:'normal' }}>Ingresa una aquí</Typography>
          </Link>
        </Box>
      </form>
    </Stack>
  );
}

export default LoginForm;
export {
  LoginForm,
}