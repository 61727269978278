import React, { FC } from 'react';
import { Study } from '../../../utils';
import { Card, Button } from '@mui/material';

interface StudyCardProps extends Study {
  onClick?: any;
}

const StudiesCard: FC<StudyCardProps> = ({
  name,
  onClick,
}) => {
  return (
    <Card sx={{ minWidth: 256 }}>
      <Button sx={{height:'128px'}} fullWidth onClick={onClick}>
        {name}
      </Button>
    </Card>
  );
}

export default StudiesCard;