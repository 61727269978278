import MainLayout from "../../common/layouts/main.layout";
import { Box, Drawer, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useSchools from "../../common/hooks/use-schools";
import { BaseTable, DialogModal, SimpleModal } from "../../common/components";
import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Edit, Verified, AssignmentTurnedIn } from "@mui/icons-material";
import { School } from "../../utils";
import { shedules, scopes, countries, statusTypes } from "../../utils/catalogs";
import { FormSchool } from "./widgets";
import { updateSchool } from "../../services/schools.services";
import { useAppDispatch } from "../../store/hooks";
import { setSchools } from "../../store/slices/schools";

const SchoolScreen = () => {
  const dispatch = useAppDispatch();
  const [loadSchools, loadingSchool, schools] = useSchools();
  const [rows, setRows] = useState<any>([]);
  const [edition, setEdition] = useState(false);
  const [schoolSelected, setSchoolselected] = useState<School>();
  const [updatedSchool, setUpdatedSchool] = useState<School>();
  const [open, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const getDisplay = (
    field: string,
    catalog: any
  ): GridColDef["valueGetter"] => {
    let result;
    switch (field) {
      case "region":
        result = (params: GridValueGetterParams) => {
          const countryRes = catalog.find(
            (item: any) => item.value === params.row["country"]
          );
          return (
            countryRes?.regions.find(
              (item: any) => item.value === params.row[field]
            )?.label ?? "-"
          );
        };
        break;
      case "subregion":
        result = (params: GridValueGetterParams) => {
          const countryRes = catalog.find(
            (item: any) => item.value === params.row["country"]
          );
          const regionRes = countryRes?.regions.find(
            (item: any) => item.value === params.row["region"]
          );
          return (
            regionRes?.subregions.find(
              (item: any) => item.value === params.row[field]
            )?.label ?? "-"
          );
        };
        break;
      default:
        result = (params: GridValueGetterParams) =>
          catalog.find((item: any) => item.value === params.row[field])
            ?.label ?? "-";
        break;
    }

    return result;
  };

  const handleEditClick = (school: School) => () => {
    setSchoolselected(school);
    setEdition(true);
  };

  const onAgree = async () => {
    setOpenDialog(false);
    if (schoolSelected) {
      try {
        setLoading(true);
        const value = "approved";
        const succes = await updateSchool(schoolSelected._id, {
          status: value,
        });
        if (succes) {
          const idx = rows.indexOf(schoolSelected);
          if (idx > -1) {
            setRows((prevState: any) => {
              const nextData = prevState.slice();
              const nextItem = { ...nextData[idx] };
              nextItem.status = "approved";
              nextData[idx] = nextItem;
              dispatch(setSchools(nextData));
              return nextData;
            });
          }
          setTextModal(
            `La escuela ${schoolSelected.name} se ha actualizado correctamente`
          );
        } else {
          setTextModal(
            "No se pudo enviar la solicitud de actualización, revisa tu conexión"
          );
        }
        setLoading(false);
        setOpenModal(true);
      } catch (err: any) {
        console.log(err);
        setTextModal(
          "No se pudo enviar la solicitud de actualización, revisa tu conexión sss"
        );
        setLoading(false);
        setOpenModal(true);
      }
    }
  };

  const handleAproveClick = (school: School) => () => {
    setSchoolselected(school);
    setTextModal(
      `Quieres aprobar la escuela ${school.name} para que aprarezca en la aplicacion de nutrento?`
    );
    setOpenDialog(true);
  };

  const getColorCell = (row: School) => {
    if (row.archived) return "archivedCell";
    return "auto";
  };

  const cols: GridColDef[] = [
    {
      field: "Verificado",
      type: "actions",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      width: 40,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<Verified />}
            label="Aprobar"
            color="success"
            disabled={row.status === "approved" ? false : true}
            sx={{ cursor: "auto" }}
          />,
        ];
      },
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "schoolKey",
      type: "string",
      headerName: "Clave",
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "country",
      type: "string",
      headerName: "País",
      valueGetter: getDisplay("country", countries),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "region",
      type: "string",
      headerName: "Región",
      valueGetter: getDisplay("region", countries),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "subregion",
      type: "string",
      headerName: "Subregión",
      valueGetter: getDisplay("subregion", countries),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "locality",
      type: "string",
      headerName: "Localidad",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "zipCode",
      type: "string",
      headerName: "Codigo postal",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "shedule",
      type: "string",
      headerName: "Horario",
      valueGetter: getDisplay("shedule", shedules),
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "scope",
      type: "string",
      headerName: "Ambito",
      valueGetter: getDisplay("scope", scopes),
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "status",
      type: "string",
      headerName: "Estado",
      disableColumnMenu: true,
      valueGetter: getDisplay("status", statusTypes),
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <Tooltip title="Editar">
            <GridActionsCellItem
              icon={<Edit />}
              label="Editar"
              className="textPrimary"
              onClick={handleEditClick(row)}
              color="primary"
            />
          </Tooltip>,
          <Tooltip title={row.status === "approved" ? "" : "Aprobar"}>
            <GridActionsCellItem
              icon={
                row.status === "approved" ? (
                  <AssignmentTurnedIn sx={{ color: "transparent" }} />
                ) : (
                  <AssignmentTurnedIn />
                )
              }
              label="Aprobar"
              onClick={handleAproveClick(row)}
              color="secondary"
              disabled={row.status === "approved" || loading ? true : false}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  useEffect(() => {
    loadSchools();
  }, [loadSchools]);

  useEffect(() => {
    if (schools) {
      setRows(schools);
      dispatch(setSchools(schools));
    }
  }, [schools, dispatch]);

  useEffect(() => {
    function update() {
      const idx = rows.indexOf(schoolSelected);
      if (idx > -1) {
        setRows((prevState: any) => {
          const nextData = prevState.slice();
          nextData[idx] = updatedSchool;
          dispatch(setSchools(nextData));
          return nextData;
        });
      }
    }
    update();
  }, [updatedSchool]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setEdition(open);
    };

  return (
    <MainLayout title="Lista Escuelas" showActions={true}>
      <SimpleModal open={open} setOpen={setOpenModal} text={textModal} />
      <DialogModal
        title="Aprobar escuela"
        text={textModal}
        onAgree={onAgree}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Box sx={{ padding: "12px 0", margin: "0" }}>
        <BaseTable
          rows={rows}
          columns={cols}
          loading={loadingSchool}
          onReload={loadSchools}
          rowHeight={32}
          autoHeight
          downloadFileName={"Escuelas"}
          pagination={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
              columnVisibilityModel: {
                status: false,
                scope: false,
                shedule: false,
              },
            },
          }}
          initialSortModel={[{
            field: 'status',
            sort: 'desc'
          }]}
          pageSizeOptions={[15, 25]}
          slotProps={{
            toolbar: {
              csvOptions: {
                allColumns: true,
              },
              showQuickFilter: true,
              showFilterButton: true,
              showDownload: true,
            },
          }}
          localeText={{
            toolbarFilters: "filtrar",
            toolbarExport: "",
            toolbarColumns: "Columnas",
            noRowsLabel: "No se encontraron escuelas.",
            toolbarExportCSV: "Descargar CSV",
            toolbarExportLabel: "Exportar",
            toolbarFiltersTooltipShow: "Mostrar filtros",
            toolbarFiltersTooltipHide: "Ocultar filtros",
            MuiTablePagination: {
              labelRowsPerPage: "Escuelas por página:",
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} de ${count}`,
            },
          }}
        />
        <Drawer anchor="right" open={edition} onClose={toggleDrawer(false)}>
          <Box sx={{ width: "250px", padding: "14px", paddingTop: "80px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h3"
              sx={{ marginBottom: "24px" }}
            >
              Actualizar
            </Typography>
            <FormSchool
              schoolSelected={schoolSelected}
              actionDrawer={setEdition}
              setUpdatedSchool={setUpdatedSchool}
              setOpenModal={setOpenModal}
              setTextModal={setTextModal}
            />
          </Box>
        </Drawer>
      </Box>
    </MainLayout>
  );
};

export default SchoolScreen;
