import {
  Typography,
  Box,
  Modal,
} from "@mui/material";
import { FC } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

interface DialogModalProps {
  text: string;
  open: boolean;
  setOpen: any;
}

const SimpleModal: FC<DialogModalProps> = ({
  text,
  open,
  setOpen,
}) => {
  return (
    <Modal
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {text}
      </Typography>
    </Box>
  </Modal>
  );
};

export { SimpleModal };
