import { useEffect, useState } from 'react'
import moment from 'moment'
import { getColorByKey, TimeUnit } from '../../../utils'
import useKpiTrend from '../../../common/hooks/use-kpi-trend'
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts'
import { Dropdown } from '../../../common/components/dropdown'
import { Box, Typography } from '@mui/material'
moment.locale('es', {
  months: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
})

const availableTimeUnits = [
  {
    distinctId: "day",
    display: {
      es: "Diario",
      default: "Daily"
    }
  },
  {
    distinctId: "isoWeek",
    display: {
      es: "Semanal",
      default: "Weekly"
    }
  },
  {
    distinctId: "month",
    display: {
      es: "Mensual",
      default: "Monthly"
    }
  }
]

const xTickFormatter = (tick: string, dataLen: number, group = "") => {
  if (dataLen > 5) {
      return '';
  }
  if (group === TimeUnit.week) {
      const values = tick.split(" ").slice(0, 2);
      return values.join(" ");
  }
  return tick;
};

const KpiTrend = ({
  dateRange
}: any) => {
  const [timeUnit, setTimeUnit] = useState('isoWeek');
  const [loadReport, loading, error, kpiTrend] = useKpiTrend({ dateRange, timeUnit });

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  if (loading) return <div />
  if (error || !kpiTrend) return (
    <Typography>{error?.message}</Typography>
  )

  const chartData = kpiTrend.periods.map((p:any) => ({
    ...p,
    dateRangeDisplay: moment(p.dateRange?.start).locale('es').format(timeUnit === TimeUnit.month ? 'MMMM' : 'DD MMMM'),
    ...p.detail.reduce((a:any, d:any) => (Object.assign(a, {
      [d.distinctId]: d.percentage
    })), {})
  }))
  const detailDisplay: Record<string, string> = chartData[0].detail.reduce((map:any, segment:any) => Object.assign(map, {
    [segment.distinctId]: segment.display
  }), { result: "respuestas" })

  return (
    <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          padding: "40px 16px 24px 16px",
          marginBottom: 16,
          minHeight: 200,
          display: "flex",
          flexDirection: "row",
          alignItems:'center',
          justifyContent: 'center',
          position: "relative",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        top: '16px',
        left: '0',
        padding: '0 24px',
        position:'absolute',
        width: '100%'
      }}>
        <Typography>
          <b>Tendencia respuestas</b>
        </Typography>
        <Dropdown
          options={availableTimeUnits}
          selectedValue={timeUnit}
          onChange={(o) => setTimeUnit(o.distinctId as TimeUnit)}
        />
      </Box>
      <ComposedChart
        width={680}
        height={380}
        data={chartData}
        style={{ fontFamily: "Helvetica"}}
      >
        <XAxis dataKey="dateRangeDisplay"
          tickFormatter={(t) => xTickFormatter(t, chartData.length, timeUnit)}
        />
        <YAxis
          yAxisId="left"
          dataKey="percentage"
          domain={[(dataMin: number) => dataMin >= 0 ? 0 : Math.round(dataMin / 100) * 100 - 10, 100]}
          allowDataOverflow={true}
          tickCount={11}
          padding={{top: 20}}
        />
         <YAxis
          yAxisId="right"
          orientation="right" 
          dataKey="result"
          domain={[(dataMin: number) => dataMin >= 0 ? 0 : (Math.round(dataMin / 100) * 100) - 20, 100]}
          tickCount={11}
          padding={{top: 20, bottom:20}}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip
          offset={40}
          separator=": "
          formatter={(v: any, name: any, { dataKey }: any) => [['result'].includes(dataKey) ? `${v}` : `${v}%`, `${detailDisplay[dataKey]}`]}
        />
        <Legend  />
        {chartData[0]?.detail?.map((segment:any) => {
          const color = getColorByKey(segment.distinctId);
          return (
            <Bar
              yAxisId="left"
              key={segment.distinctId}
              name={segment.display}
              stackId="a"
              dataKey={segment.distinctId}
              fill={color}
              opacity={0.75}
              maxBarSize={48}
            >
            </Bar>
          );
        })}
        <Line
          yAxisId="right"
          dataKey="result"
          name={ "respuestas"}
          type="monotone"
          stroke="#070707"
        >
          <LabelList offset={12} position={'top'} fontSize={chartData.length > 14 ?  10 : 14} />
        </Line>
      </ComposedChart>
    </Box>
  )
}

export {
  KpiTrend
}