import { FC, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectSlice } from "../../../store/slices/studies";
import { genExport } from "../../../services/exports.service";
import { DateRange } from "../../../utils/models";
import DateRangeInput from "../../../common/components/date-range";
import { Button, Stack, Typography } from "@mui/material";

type SuccessCallback = () => void;

interface GenExportFormProps {
  onSuccess?: SuccessCallback;
}

const GenExportForm: FC<GenExportFormProps> = ({ onSuccess }) => {
  const studyId = useAppSelector(selectSlice)?.selected?._id;
  const [dateRange, setDateRange] = useState<DateRange>();

  async function generateExport() {
    if (!studyId || !dateRange) return;
    try {
      await genExport(studyId, dateRange);
      onSuccess && onSuccess();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Stack direction="column" alignItems="left" spacing={2}>
      <Typography>Periodo</Typography>
      <DateRangeInput onRangeChange={setDateRange} />
      <Stack direction="column" alignItems="center" spacing={2}>
        <Button
          disabled={!dateRange?.start || !dateRange?.end}
          type="submit"
          color="primary"
          variant="contained"
          onClick={generateExport}
          sx={{ width: "200px" }}
        >
          Exportar
        </Button>
      </Stack>
      </Stack>
  );
};

export default GenExportForm;
export { GenExportForm };
