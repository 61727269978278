import { configureStore } from '@reduxjs/toolkit';
import studiesReducer from './slices/studies';
import globalReducer from './slices/global';
import schoolsReducer from './slices/schools';

export const store = configureStore({
  reducer: {
    studies: studiesReducer,
    global: globalReducer,
    schools: schoolsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;