import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { getKpiTrendReport } from '../../services/analytics.service';
import { KpiTrendReport, KpiTrendReportQuery } from '../../services/analytics.service';
import { messages } from '../../utils';

type UseKpiTrendHook = (config: any) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  KpiTrendReport | undefined
];

const useKpiTrend: UseKpiTrendHook = ({
  dateRange,
  timeUnit
}) => {
  const studies = useAppSelector(selectSlice);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<KpiTrendReport | undefined>(undefined);

  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        if (!dateRange.start || !dateRange.end) {
          return setAnalyticsError(new Error('No hay periodo de tiempo seleccionado'));
        }
        const query: KpiTrendReportQuery = {
          dateRange: {
            field: 'createdAt',
            start: dateRange.start.startOf('day').toISOString(),
            end: dateRange.end.endOf('day').toISOString(),
          },
          field: 'status',
          calc: {
            options: {
                precision: 2
            }
          },
          timeUnit
        }
        let analyticsResponse = await getKpiTrendReport(query);
        if (analyticsResponse.n < 1) throw new Error(messages.NO_DATA_FOR_QUERY);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      timeUnit
    ]
  );

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ]

}

export default useKpiTrend;