const sortBy = (key: string) => {
  return (a: any, b: any) => {
    if (a[key] > b[key]) return 1;
    if (a[key] < b[key]) return -1;
    return 0;
  };
};

const getColorByKey = (key: string) => {
  let color = "#31AAEB";
  switch (key) {
    case "completed":
      color = "#30b131";
      break;
    case "started":
      color = "#E84147";
      break;
    case "leave":
      color = "#31AAEB";
      break;
    default:
      color = "#30b131";
  }
  return color;
};

const getSlug = function(projectName: string) {
  let trim = projectName;
  if(projectName.length > 40) trim = projectName.substring( 0, 40 );
  return trim.toLowerCase().replace(/ /g, '-');
};

export { sortBy, getColorByKey, getSlug };
