import { getData } from '../services/local-storage.service';
import { constants } from '../utils';
import { ServerResponse, Schools, School } from '../utils/models'

async function getAllSchools (): Promise<Schools> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/school`, fetchOpts);
  const response: ServerResponse<Schools> = await fetchResponse.json();
  return response.data;
}

async function createSchool (school: School): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(school)
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/school`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  return true;
}

async function updateSchool (id: any, data: any): Promise<boolean> {
  const token = getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  };
  const fetchResponse: Response = await fetch(`${constants.API_URL}/school/${id}`, fetchOpts);
  const response: ServerResponse<any> = await fetchResponse.json();
  if (response.errorCode) throw new Error(response.message.es);
  else return true;
}

export {
  getAllSchools,
  createSchool,
  updateSchool
}