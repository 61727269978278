import { FC } from 'react';
import MainLayout from "../../common/layouts/main.layout";
import { ExportsTable } from './widgets';
import { Box } from '@mui/material';

interface ExportScreenProps {
};

const ExportScreen: FC<ExportScreenProps> = () => {

  return (
    <MainLayout title='Exportar resultados' showActions={true}>
      <Box sx={{ padding: "12px 0", margin: "0" }}>
      <ExportsTable />
      </Box>
     
    </MainLayout>
  );
}

export default ExportScreen;
export {
    ExportScreen,
};