import MainLayout from "../../common/layouts/main.layout";
import { Box, Drawer, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useLinks from "../../common/hooks/use-links";
import { BaseTable, SimpleModal } from "../../common/components";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { Link } from "../../utils";
import { FormLink } from "./widgets";

const LinksScreen = () => {
  const [loadLinks, loadingLink, links] = useLinks();
  const [rows, setRows] = useState<any>([]);
  const [edition, setEdition] = useState(false);
  const [linkSelected, setLinkselected] = useState<Link>();
  const [updatedLink, setUpdatedLink] = useState<Link>();
  const [open, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");

  const handleEditClick = (school: Link) => () => {
    setLinkselected(school);
    setEdition(true);
  };

  const getColorCell = (row: Link) => {
    if (row.archived) return "archivedCell";
    return "auto";
  };

  const cols: GridColDef[] = [
    {
      field: "tag",
      type: "string",
      headerName: "Etiqueta",
      disableColumnMenu: true,
      flex: 1,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "url",
      type: "string",
      headerName: "Url",
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      flex: 0.8,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "description",
      type: "string",
      headerName: "Descripción",
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      flex: 0.8,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "relevance",
      type: "number",
      headerName: "Relevancia",
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.3,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <Tooltip title="Editar">
            <GridActionsCellItem
              icon={<Edit />}
              label="Editar"
              className="textPrimary"
              onClick={handleEditClick(row)}
              color="primary"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  useEffect(() => {
    loadLinks();
  }, [loadLinks]);

  useEffect(() => {
    if (links) {
      setRows(links);
    }
  }, [links]);

  useEffect(() => {
    function update() {
      const idx = rows.indexOf(linkSelected);
      if (idx > -1) {
        setRows((prevState: any) => {
          const nextData = prevState.slice();
          nextData[idx] = updatedLink;
          return nextData;
        });
      }
    }
    update();
  }, [updatedLink]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setEdition(open);
    };

  return (
    <MainLayout title="Lista Ligas de interés" showActions={true}>
      <SimpleModal open={open} setOpen={setOpenModal} text={textModal} />
      <Box sx={{ padding: "12px 0", margin: "0" }}>
        <BaseTable
          rows={rows}
          columns={cols}
          loading={loadingLink}
          onReload={loadLinks}
          rowHeight={32}
          autoHeight
          downloadFileName={"Ligas"}
          pagination={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
              columnVisibilityModel: {
                status: false,
                scope: false,
                shedule: false,
              },
            },
          }}
          pageSizeOptions={[15, 25]}
          slotProps={{
            toolbar: {
              csvOptions: {
                allColumns: true,
              },
              showQuickFilter: true,
              showFilterButton: true,
              showDownload: true,
            },
          }}
          localeText={{
            toolbarFilters: "filtrar",
            toolbarExport: "",
            toolbarColumns: "Columnas",
            noRowsLabel: "No se encontraron ligas.",
            toolbarExportCSV: "Descargar CSV",
            toolbarExportLabel: "Exportar",
            toolbarFiltersTooltipShow: "Mostrar filtros",
            toolbarFiltersTooltipHide: "Ocultar filtros",
            MuiTablePagination: {
              labelRowsPerPage: "Ligas por página:",
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} de ${count}`,
            },
          }}
        />
        <Drawer anchor="right" open={edition} onClose={toggleDrawer(false)}>
          <Box sx={{ width: "650px", padding: "14px", paddingTop: "80px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h3"
              sx={{ marginBottom: "24px" }}
            >
              Actualizar
            </Typography>
            <FormLink
              linkSelected={linkSelected}
              actionDrawer={setEdition}
              setUpdatedLink={setUpdatedLink}
              setOpenModal={setOpenModal}
              setTextModal={setTextModal}
            />
          </Box>
        </Drawer>
      </Box>
    </MainLayout>
  );
};

export default LinksScreen;
