import { Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import useUsers from "../../../common/hooks/use-users";
import {
  BaseTable,
} from "../../../common/components";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Edit, RemoveCircle, CheckCircle } from "@mui/icons-material";
import { User } from "../../../utils";
import {
  countries,
  educationalLevels,
  genders,
  scopes,
  maritalStatusCatalog,
  degrees,
  contracts,
  lastDegree,
  shedules,
  consentTypes,
} from "../../../utils/catalogs";
import { useAppSelector } from "../../../store/hooks";
import { selectSchoolsApprovedOptions } from "../../../store/slices/schools";

interface UserTableProps {
  setTextModal: any;
  setOpenDialog: any;
  setEdition: any;
  updatedUser: User | undefined;
  userSelected: User | undefined;
  setUserselected: any;
  loading: boolean;
}

const UserTable: FC<UserTableProps> = ({
  setTextModal,
  setOpenDialog,
  setEdition,
  updatedUser,
  userSelected,
  setUserselected,
  loading
}) => {
  const [loadUsers, loadingUser, users] = useUsers({admin: false});
  const [rows, setRows] = useState<any>([]);
  const schools = useAppSelector((state) =>
    selectSchoolsApprovedOptions(state, "approved")
  );

  const handleEditClick = (user: User) => () => {
    setUserselected(user);
    setEdition(true);
  };

  const getInformation = (
    information: any,
    field: string,
    catalog?: any
  ): any => {
    if (information && information[field] && field === "region") {
      const countryRes = catalog.find(
        (item: any) => item.value === information["country"]
      );
      return (
        countryRes?.regions.find(
          (item: any) => item.value === information[field]
        )?.label ?? "-"
      );
    }
    if (information && information.hasOwnProperty(field) && catalog)
      return catalog.find((item: any) => item.value === information[field])
        ?.label;
    if (information && information[field]) return information[field];

    return "";
  };

  const getSchools = (schoolIds: any): any => {
    let result = "";
    if (schoolIds.length > 0) {
      const data = schools.filter(
        (item) => schoolIds.indexOf(item.value) !== -1
      );

      data.forEach((res) => {
        result += "[ " + res.label + " ] ";
      });
    }
    return result;
  };

  const handleRemoveClick = (user: User) => () => {
    setUserselected(user);
    setTextModal(
      `Estas seguro de que quieres quitar el acceso al usuario ${user.name} para que ya no pueda acceder a la aplicacion de nutrento? Una vez se quite el acceso sus respuestas asocidas se quedaran como anónimas`
    );
    setOpenDialog(true);
  };

  const getColorCell = (row: User) => {
    if (row.archived) return "archivedCell";
    return "auto";
  };

  const cols: GridColDef[] = [
    {
      field: "Consentimiento",
      type: "actions",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      width: 40,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<CheckCircle />}
            label="Aprobar"
            color="primary"
            disabled={!row.acceptTerms}
            sx={{ cursor: "auto" }}
          />,
        ];
      },
    },
    {
      field: "_id",
      type: "string",
      headerName: "Id",
      disableColumnMenu: true,
      flex: 0.75,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "acceptTerms",
      type: "string",
      headerName: "Estado Consentimiento",
      disableColumnMenu: true,
      valueGetter: ({ row }) =>  getInformation(row, "acceptTerms", consentTypes),
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      disableColumnMenu: true,
      flex: 0.65,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "email",
      type: "string",
      headerName: "Correo",
      disableColumnMenu: true,
      flex: 0.65,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "schools",
      type: "string",
      headerName: "Escuelas",
      valueGetter: ({ row }) => getSchools(row.schools),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "age",
      type: "number",
      headerName: "Edad",
      valueGetter: ({ row }) => getInformation(row.information, "age"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "gender",
      type: "string",
      headerName: "Genero",
      valueGetter: ({ row }) =>
        getInformation(row.information, "gender", genders),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "country",
      type: "string",
      headerName: "País",
      valueGetter: ({ row }) =>
        getInformation(row.information, "country", countries),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "region",
      type: "string",
      headerName: "Región",
      valueGetter: ({ row }) =>
        getInformation(row.information, "region", countries),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "scope",
      type: "string",
      headerName: "Ambito",
      valueGetter: ({ row }) =>
        getInformation(row.information, "scope", scopes),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "maritalstatus",
      type: "string",
      headerName: "Estado civil",
      valueGetter: ({ row }) =>
        getInformation(row.information, "maritalstatus", maritalStatusCatalog),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p1Display",
      type: "string",
      headerName: "1 Último año de estudios",
      valueGetter: ({ row }) => getInformation(row.information, "p1Display"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p1Score",
      type: "string",
      headerName: "1 Puntuación",
      valueGetter: ({ row }) => getInformation(row.information, "p1Score"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p2Display",
      type: "string",
      headerName: "2 Baños completos",
      valueGetter: ({ row }) => getInformation(row.information, "p2Display"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p2Score",
      type: "string",
      headerName: "2 Puntuación",
      valueGetter: ({ row }) => getInformation(row.information, "p2Score"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p3Display",
      type: "string",
      headerName: "3 Automóviles",
      valueGetter: ({ row }) => getInformation(row.information, "p3Display"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p3Score",
      type: "string",
      headerName: "3 Puntuación",
      valueGetter: ({ row }) => getInformation(row.information, "p3Score"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p4Display",
      type: "string",
      headerName: "4 Cuenta con internet",
      valueGetter: ({ row }) => getInformation(row.information, "p4Display"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p4Score",
      type: "string",
      headerName: "4 Puntuación",
      valueGetter: ({ row }) => getInformation(row.information, "p4Score"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p5Display",
      type: "string",
      headerName: "5 Personas que trabajan",
      valueGetter: ({ row }) => getInformation(row.information, "p5Display"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p5Score",
      type: "string",
      headerName: "5 Puntuación",
      valueGetter: ({ row }) => getInformation(row.information, "p5Score"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p6Display",
      type: "string",
      headerName: "6 Cuartos para dormir",
      valueGetter: ({ row }) => getInformation(row.information, "p6Display"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "p6Score",
      type: "string",
      headerName: "6 Puntuación",
      valueGetter: ({ row }) => getInformation(row.information, "p6Score"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "nse",
      type: "number",
      headerName: "Puntuación NSE",
      valueGetter: ({ row }) => getInformation(row.information, "nse"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "degree",
      type: "string",
      headerName: "Titulo",
      valueGetter: ({ row }) =>
        getInformation(row.information, "degree", degrees),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "otherDegree",
      type: "string",
      headerName: "Titulo Otro",
      valueGetter: ({ row }) => getInformation(row.information, "otherDegree"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "educationalLevel",
      type: "string",
      headerName: "Nivel de educación",
      valueGetter: ({ row }) =>
        getInformation(row.information, "educationalLevel", educationalLevels),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "courses",
      type: "string",
      headerName: "Cursos",
      valueGetter: ({ row }) => getInformation(row.information, "courses"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "coursesNames",
      type: "string",
      headerName: "Cursos nombres",
      valueGetter: ({ row }) => getInformation(row.information, "coursesNames"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "lastDegreeComplete",
      type: "string",
      headerName: "Último grado titulado",
      valueGetter: ({ row }) =>
        getInformation(row.information, "lastDegreeComplete", lastDegree),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "teachingYears",
      type: "string",
      headerName: "Años de ejercicio docente",
      valueGetter: ({ row }) =>
        getInformation(row.information, "teachingYears"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "contractType",
      type: "string",
      headerName: "Contrato",
      valueGetter: ({ row }) =>
        getInformation(row.information, "contractType", contracts),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "shedule",
      type: "string",
      headerName: "Horario",
      valueGetter: ({ row }) =>
        getInformation(row.information, "shedule", shedules),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "studentsNumber",
      type: "string",
      headerName: "Número de estudiantes",
      valueGetter: ({ row }) =>
        getInformation(row.information, "studentsNumber"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "morningGrade",
      type: "string",
      headerName: "Grados (turno matutino)",
      valueGetter: ({ row }) => getInformation(row.information, "morningGrade"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "eveningGrade",
      type: "string",
      headerName: "Grados (turno vespertino)",
      valueGetter: ({ row }) => getInformation(row.information, "eveningGrade"),
      disableColumnMenu: true,
      flex: 0.5,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <Tooltip title="Editar">
            <GridActionsCellItem
              icon={<Edit />}
              label="Editar"
              className="textPrimary"
              onClick={handleEditClick(row)}
              color="primary"
            />
          </Tooltip>,
          <Tooltip
            title={row.email === "anonimo@nutrento.org" ? "" : "Remover acceso"}
          >
            <GridActionsCellItem
              icon={
                row.email === "anonimo@nutrento.org" ? (
                  <RemoveCircle sx={{ color: "transparent" }} />
                ) : (
                  <RemoveCircle />
                )
              }
              label="Remover acceso"
              onClick={handleRemoveClick(row)}
              color="error"
              disabled={
                row.email === "anonimo@nutrento.org" || loading ? true : false
              }
            />
          </Tooltip>,
        ];
      },
    },
  ];

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    if (users) {
      setRows(users);
    }
  }, [users]);

  useEffect(() => {
    function update() {
      const idx = rows.indexOf(userSelected);
      if (idx > -1) {
        setRows((prevState: any) => {
          const nextData = prevState.slice();
          nextData[idx] = updatedUser;
          return nextData;
        });
      }
    }
    update();
  }, [updatedUser]);

  return (
    <BaseTable
      rows={rows}
      columns={cols}
      loading={loadingUser}
      onReload={loadUsers}
      rowHeight={32}
      autoHeight
      downloadFileName={"Docentes"}
      pagination={true}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
        columns: {
          columnVisibilityModel: {
            acceptTerms: false,
            schools: false,
            country: false,
            region: false,
            scope: false,
            maritalstatus: false,
            p1Score: false,
            p1Display: false,
            p2Score: false,
            p2Display: false,
            p3Score: false,
            p3Display: false,
            p4Score: false,
            p4Display: false,
            p5Score: false,
            p5Display: false,
            p6Score: false,
            p6Display: false,
            degree: false,
            otherDegree: false,
            courses: false,
            coursesNames: false,
            lastDegreeComplete: false,
            teachingYears: false,
            contractType: false,
            shedule: false,
            morningGrade: false,
            eveningGrade: false,
            studentsNumber: false,
          },
        },
      }}
      pageSizeOptions={[15, 25]}
      slotProps={{
        toolbar: {
          csvOptions: {
            allColumns: true,
          },
          showQuickFilter: true,
          showFilterButton: true,
          showDownload: true,
        },
      }}
      localeText={{
        toolbarFilters: "filtrar",
        toolbarExport: "",
        toolbarColumns: "Columnas",
        noRowsLabel: "No se encontraron docentes.",
        toolbarExportCSV: "Descargar CSV",
        toolbarExportLabel: "Exportar",
        toolbarFiltersTooltipShow: "Mostrar filtros",
        toolbarFiltersTooltipHide: "Ocultar filtros",
        MuiTablePagination: {
          labelRowsPerPage: "Usuarios por página:",
          labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de ${count}`,
        },
      }}
    />
  );
};

export default UserTable;
