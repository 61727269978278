import { Moment } from 'moment';

export enum TimeUnit {
  day = 'day',
  week = 'isoWeek',
  month = 'month',
}

export interface MomentRange {
  start?: Moment;
  end?: Moment;
}

export interface Study {
  _id: string;
  name: string;
  importSchoolsDatamapId: string;
  importUsersDatamapId: string;
}

export interface School {
  _id?: string;
  schoolKey: string;
  name: string;
  country: string;
  region: string;
  subregion: string;
  locality: string;
  shedule: string;
  scope: string;
  zipCode: number;
  status?: string;
  archived?: boolean;
  approvalDate?: Date;
  archivedDate?: Date;
  createdAt?: string;
}

export interface Link {
  _id?: string;
  tag: string;
  url: string;
  description: string;
  relevance?: number;
  archived?: boolean;
  archivedDate?: Date;
  createdAt?: string;
}

export interface Survey {
  _id: string;
  name: string;
}

export interface Experience {
  _id?: string;
  projectName: string;
  userId?: string;
  userObj?: User;
  schoolName: string;
  projectManagers: string;
  location: string;
  description: string;
  projectImages?: Array<string>;
  productionImages?: Array<string>;
  projectNetworks?: string;
  status?: string;
  archived?: boolean;
  relevance?: number;
  highlight?: boolean;
  category?: string;
  archivedDate?: Date;
  publishedDateAt?: Date;
  createdAt?: string;
}

export type ServerResponse<T> = {
  message: any;
  data: T,
  errorCode?: string;
}

export interface Import {
  _id: string;
  id: string;
  createdAt: string;
  completeRecords: number;
  datamapId: string;
  discardedRecords: number;
  fileId: string;
  fileObj: {
    _id: string;
    originalname: string;
  }
  status: string;
  studyId: string;
  typeInput: string;
  updatedAt: string;
}

export interface Export {
  _id: string;
  createdAt: string;
  totalInteractions: number;
  discardInteractions: number;
  fileId: string;
  status: string;
  surveyId: string;
}

export interface MenuAction {
  icon: string;
  label:  string;
  path:  string;
}

export interface MenuOption {
  icon: string;
  label: string;
  path:  string;
  actions?: MenuAction[];
}

export interface User {
  _id?: string;
  name: string;
  information?: Information; 
  email?: string;
  externalId?: string;
  schools?: Array<string>;
  archived?: boolean;
  archivedDate?: Date;
  createdAt?: string;
  updatedAt?: string;
  password?: string;
  admin?: boolean;
}

export class Information {
  age?: number;
  gender?: string;
  country?: string;
  region?: string;
  scope?: string;
  maritalstatus?: string;
  p1Score?: number;
  p1Display?: string;
  p2Score?: number;
  p2Display?: string;
  p3Score?: number;
  p3Display?: string;
  p4Score?: number;
  p4Display?: string;
  p5Score?: number;
  p5Display?: string;
  p6Score?: number;
  p6Display?: string;
  nse?: number;
  degree?: string;
  otherDegree?: string;
  educationalLevel?: string;
  courses?: number;
  coursesNames?: string;
  lastDegreeComplete?: boolean;
  teachingYears?: number;
  contractType?: string;
  shedule?: string;
  morningGrade?: string;
  eveningGrade?: string;
  studentsNumber?: number;
}

export type DateRange = MomentRange | undefined;
export type Studies = Study[] | undefined;
export type Schools = School[] | undefined;
export type Links = Link[] | undefined;
export type Experiences = Experience[] | undefined;
export type Users = User[] | undefined;
export type Imports = Import[] | undefined;
export type Exports = Export[] | undefined;
export type Surveys = Survey[] | undefined;
