import MainLayout from "../../common/layouts/main.layout";
import { Box, Drawer, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BaseTable, DialogModal } from "../../common/components";
import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Edit, Verified, AssignmentTurnedIn } from "@mui/icons-material";
import { Experience } from "../../utils";
import { useAppDispatch } from "../../store/hooks";
import { updateExperience } from "../../services/experiences.service";
import useExperiences from "../../common/hooks/use-experiences";
import { FormExperience } from "./widgets";
import { statusTypes } from "../../utils/catalogs";
import { SERVER_URL } from '../../utils/constants';
import { getSlug } from '../../utils/helpers';

const ExperienceScreen = () => {
  const dispatch = useAppDispatch();
  const [loadExperiences, loadingExperience, experiences] = useExperiences();
  const [rows, setRows] = useState<any>([]);
  const [edition, setEdition] = useState(false);
  const [experienceSelected, setExperienceSelected] = useState<Experience>();
  const [updatedExperience, setUpdatedExperience] = useState<Experience>();
  const [open, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleEditClick = (school: Experience) => () => {
    setExperienceSelected(school);
    setEdition(true);
  };

  const getDisplay = (
    field: string,
    catalog: any
  ): GridColDef["valueGetter"] => {
    const result = (params: GridValueGetterParams) =>
          catalog.find((item: any) => item.value === params.row[field])
            ?.label ?? "-";

    return result;
  };

  const onAgree = async () => {
    setOpenDialog(false);
    if (experienceSelected?._id) {
      try {
        setLoading(true);
        const value = "approved";
        const succes = await updateExperience(experienceSelected._id, {
          status: value,
        });
        if (succes) {
          const idx = rows.indexOf(experienceSelected);
          if (idx > -1) {
            setRows((prevState: any) => {
              const nextData = prevState.slice();
              const nextItem = { ...nextData[idx] };
              nextItem.status = "approved";
              nextData[idx] = nextItem;
              return nextData;
            });
          }
          setTextModal(
            `El proyecto ${experienceSelected.projectName} se ha aprobado correctamente`
          );
          setTextModal(
            `El proyecto ${experienceSelected.projectName} se ha aprobado correctamente. A continuación te mostramos una plantilla para compartir su aprobación con el usuario:<br/><div class="preview-email">Tu proyecto ha sido aprobado en Nutrento<br/><br/>Gracias por compartir tu proyecto ${experienceSelected.projectName} con nosotros.<br/><br/> Puedes acceder a nuestra web para ver tu publicación en: ${`${SERVER_URL}/detalle/${getSlug(experienceSelected.projectName)}-${experienceSelected._id}`}</div> <a href='mailto:${experienceSelected.userObj?.email}?subject=Tu proyecto ha sido aprobado en Nutrento&body=Gracias por compartir tu proyecto ${experienceSelected.projectName} con nosotros. %0D%0A %0D%0A Puedes acceder a nuestra web para ver tu publicación en: ${`${SERVER_URL}/detalle/${getSlug(experienceSelected.projectName)}-${experienceSelected._id}`}' target='_blank' rel='noreferrer' style=color:#351051;>Pulsa aqui para usar tu cliente de correo</a><br/> <a aria-label="Pulsa aqui para usar tu cliente de WhatsApp" href="https://wa.me/?text=Tu proyecto ha sido aprobado en Nutrento %0A %0A Gracias por compartir tu proyecto ${experienceSelected.projectName} con nosotros. %0A %0A Puedes acceder a nuestra web para ver tu publicación en: ${`${SERVER_URL}/detalle/${getSlug(experienceSelected.projectName)}-${experienceSelected._id}`}" target='_blank' rel='noreferrer' style=color:#351051;>Compartir con WhatsApp<a/>`
          );
        } else {
          setTextModal(
            "No se pudo enviar la solicitud de actualización, revisa tu conexión"
          );
        }
        setLoading(false);
        setOpenModal(true);
      } catch (err: any) {
        console.log(err);
        setTextModal(
          "No se pudo enviar la solicitud de actualización, revisa tu conexión sss"
        );
        setLoading(false);
        setOpenModal(true);
      }
    }
  };

  const handleAproveClick = (experience: Experience) => () => {
    setExperienceSelected(experience);
    setTextModal(
      `Quieres aprobar la experiencia ${experience.projectName} para que aparezca en la página web nutrento.org?`
    );
    setOpenDialog(true);
  };

  const getColorCell = (row: Experience) => {
    if (row.archived) return "archivedCell";
    return "auto";
  };

  const cols: GridColDef[] = [
    {
      field: "Aprobado",
      type: "actions",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      width: 40,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<Verified />}
            label="Aprobar"
            color="success"
            disabled={row.status === "approved" ? false : true}
            sx={{ cursor: "auto" }}
          />,
        ];
      },
    },
    {
      field: "projectName",
      type: "string",
      headerName: "Nombre",
      disableColumnMenu: true,
      flex: 1.4,
      sortable: true,
      filterable: true,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "schoolName",
      type: "string",
      headerName: "Escuela",
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "location",
      type: "string",
      headerName: "Ubicación",
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "relevance",
      type: "number",
      headerName: "Relevancia",
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      flex: 0.3,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "status",
      type: "string",
      headerName: "Estado",
      disableColumnMenu: true,
      valueGetter: getDisplay("status", statusTypes),
      sortable: false,
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      flex: 0.5,
      cellClassName: ({ row }) => getColorCell(row),
      getActions: ({ row }) => {
        return [
          <Tooltip title="Editar">
            <GridActionsCellItem
              icon={<Edit />}
              label="Editar"
              className="textPrimary"
              onClick={handleEditClick(row)}
              color="primary"
            />
          </Tooltip>,
          <Tooltip title={row.status === "approved" ? "" : "Aprobar"}>
            <GridActionsCellItem
              icon={
                row.status === "approved" ? (
                  <AssignmentTurnedIn sx={{ color: "transparent" }} />
                ) : (
                  <AssignmentTurnedIn />
                )
              }
              label="Aprobar"
              onClick={handleAproveClick(row)}
              color="secondary"
              disabled={row.status === "approved" || loading ? true : false}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setEdition(open);
    };

  useEffect(() => {
    loadExperiences();
  }, [loadExperiences]);

  useEffect(() => {
    if (experiences) {
      setRows(experiences);
    }
  }, [experiences, dispatch]);

  useEffect(() => {
    function update() {
      const idx = rows.indexOf(experienceSelected);
      if (idx > -1) {
        setRows((prevState: any) => {
          const nextData = prevState.slice();
          nextData[idx] = updatedExperience;
          return nextData;
        });
        loadExperiences();
      }
    }
    update();
  }, [updatedExperience]);

  return (
    <MainLayout title="Lista Experiencias" showActions={true}>
      <DialogModal
        text={textModal}
        onAgree={() => setOpenModal(false)}
        open={open}
        setOpen={setOpenModal}
        hideCloseButton={true}
      />
      <DialogModal
        title="Aprobar experiencia"
        text={textModal}
        onAgree={onAgree}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Box sx={{ padding: "12px 0", margin: "0" }}>
        <BaseTable
          rows={rows}
          columns={cols}
          loading={loadingExperience}
          onReload={loadExperiences}
          rowHeight={32}
          autoHeight
          pagination={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
              columnVisibilityModel: {
                status: false,
              },
            },
          }}
          initialSortModel={[{
            field: 'status',
            sort: 'desc'
          }]}
          pageSizeOptions={[15, 25]}
          slotProps={{
            toolbar: {
              csvOptions: {
                allColumns: true,
              },
              showQuickFilter: true,
              showFilterButton: true,
            },
          }}
          localeText={{
            toolbarFilters: "filtrar",
            toolbarExport: "",
            toolbarColumns: "Columnas",
            noRowsLabel: "No se encontraron experiencias.",
            toolbarFiltersTooltipShow: "Mostrar filtros",
            toolbarFiltersTooltipHide: "Ocultar filtros",
            MuiTablePagination: {
              labelRowsPerPage: "Experiencias por página:",
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} de ${count}`,
            },
          }}
        />
         <Drawer anchor="right" open={edition} onClose={toggleDrawer(false)}>
          <Box sx={{ width: "1000px", padding: "14px", paddingTop: "80px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h3"
              sx={{ marginBottom: "24px" }}
            >
              Actualizar
            </Typography>
            <FormExperience
              experienceSelected={experienceSelected}
              actionDrawer={setEdition}
              setUpdatedExperience={setUpdatedExperience}
              setOpenModal={setOpenModal}
              setTextModal={setTextModal}
            />
          </Box>
        </Drawer>
      </Box>
    </MainLayout>
  );
};

export default ExperienceScreen;
