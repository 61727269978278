import { useCallback, useState } from 'react';
import { Experiences } from '../../utils/models';
import { getAllExperiences } from '../../services/experiences.service';

type UseExperiencesHook = () => [
  () => Promise<any>,
  boolean,
  Experiences,
];

const useExperiences: UseExperiencesHook = () => {

  const [loading, setLoading] = useState(true);
  const [experiences, setExperiences] = useState<Experiences>();

  const loadAllExperiences = useCallback(
    async () => {
      try {
        setExperiences(undefined);
        setLoading(true);
        const experienceList = await getAllExperiences();
        if (experienceList) {
          setExperiences(experienceList);
        }
      } catch (e) {
        console.log(e as Error)
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return [
    loadAllExperiences,
    loading,
    experiences,
  ]

}

export default useExperiences;