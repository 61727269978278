import { FC } from "react";
import { useHistory } from "react-router-dom";
import { MainLayout } from "../../common/layouts";
import GenExportForm from "./widgets/gen-export.form";
import { Box } from "@mui/material";

interface NewExportScreenProps {}

const NewExportScreen: FC<NewExportScreenProps> = () => {
  const history = useHistory();

  return (
    <MainLayout title="Nueva exportación" secondary={true}>
      <Box
        sx={{
          padding: "32px 24px",
          maxWidth: "600px",
          margin: "0 auto",
          borderRadius: "12px",
          background: "#fff",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        <GenExportForm onSuccess={() => history.push("/export")} />
      </Box>
    </MainLayout>
  );
};

export default NewExportScreen;
export { NewExportScreen };
