import { DateRange, Exports, ServerResponse } from '../utils/models';
import { AUTH_USER_TOKEN_KEY, API_URL } from '../utils/constants';
import { storage } from '.';

interface genExportDTO {
  studyId: string;
  query: {
    surveyId?: string;
    [key: string]: any;
  };
  dateRange: {
    valueName: string;
    start: string;
    end: string;
  }
}

async function getExports (studyId?: string): Promise<Exports> {
  const token = storage.getData(AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = `${API_URL}/export`;
  if (studyId) {
    URL += `?conditions=${JSON.stringify({ studyId })}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Exports> = await fetchResponse.json();
  return response.data;
}

async function genExport (studyId: string, range: DateRange): Promise<Response> {
  const token = storage.getData(AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: genExportDTO = {
    studyId,
    query: {
      'status': {
        '$in': ['leave', 'completed']
      }
    },
    dateRange: {
      valueName: 'createdAt',
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    }
  };
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${API_URL}/export`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  return fetchResponse;
}

export {
  getExports,
  genExport,
}