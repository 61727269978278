import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { storage, auth } from "./services";
import { constants } from "./utils";
import StartScreen from "./screens/start";
import AuthScreen from "./screens/auth";
import {
  SchoolScreen,
  CreateSchoolScreen,
  UploadSchoolScreen,
} from "./screens/schools";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ExportScreen, NewExportScreen } from "./screens/exports";
import { CreateAdminScreen, CreateUserScreen, UploadUserScreen, UserScreen } from "./screens/users";
import { PerformanceScreen } from "./screens/performance";
import ResultScreen from "./screens/results/result.screen";
import { CreateExperienceScreen, ExperienceScreen } from "./screens/experiences";
import { CreateLinkScreen, LinksScreen } from "./screens/links";

const theme = createTheme({
  palette: {
    primary: {
      main: "#351051",
    },
  },
});

function App(): JSX.Element | null {
  const history = useHistory();
  let location = useLocation()

  async function tryRefresh() {
    try {
      await auth.refreshCognitoSession();
    } catch (err) {
      console.log("No se pudo actualizar sesión.");
      history.push("/auth/login");
    }
  }

  useEffect(() => {
    async function validateAuth() {
      const refreshToken = storage.getData(constants.AUTH_REFRESH_TOKEN_KEY);
      if (refreshToken) {
        try {
          await auth.refreshCognitoSession();
          const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);

          if (token) {
            history.push("/start");
          } else {
            location.pathname === '/auth/confirm-password' ?
            history.push('/auth/confirm-password' + location.search) : history.push('/auth/login') 
          }
        } catch (err) {
          history.push("/auth/login");
        }
      } else {
        location.pathname === '/auth/confirm-password' ?
        history.push('/auth/confirm-password' + location.search) : history.push('/auth/login') 
      }
    }

    let interval: any;
    interval = setInterval(tryRefresh, 10 * 60 * 1000); // 10 minutos
    validateAuth();

    return function cleanup() {
      clearInterval(interval);
    };
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/auth" component={AuthScreen} />
        <Route path="/start" component={StartScreen} />
        <Route path="/performance" component={PerformanceScreen} />
        <Route path="/schools" component={SchoolScreen} />
        <Route path="/create-school" component={CreateSchoolScreen} />
        <Route path="/upload-schools" component={UploadSchoolScreen} />
        <Route path="/export" component={ExportScreen} />
        <Route path="/new-export" component={NewExportScreen} />
        <Route path="/users" component={UserScreen} />
        <Route path="/create-user" component={CreateUserScreen} />
        <Route path="/create-admin" component={CreateAdminScreen} />
        <Route path="/upload-users" component={UploadUserScreen} />
        <Route path="/results" component={ResultScreen} />
        <Route path="/experiences" component={ExperienceScreen} />
        <Route path="/create-experience" component={CreateExperienceScreen} />
        <Route path="/links" component={LinksScreen} />
        <Route path="/create-link" component={CreateLinkScreen} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
