import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { School } from "../../utils/models";

// Define a type for the slice state
interface SchoolsState {
  all: School[];
}

// Define the initial state using that type
const initialState: SchoolsState = {
  all: [],
};

export const schoolsSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSchools: (state, action: PayloadAction<School[]>) => {
      state.all = action.payload;
    },
  },
});

export const { setSchools } = schoolsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAllSchools = (state: RootState) => state.schools.all;
export const selectSchoolsApproved = createSelector(
  selectAllSchools,
  (_: RootState, status: string) => status,
  (allSchools, status) =>
    allSchools.filter((school) => school.status === status)
);
export const selectSchoolsApprovedOptions = createSelector(
  selectSchoolsApproved,
  (schools) =>
    schools
      ? schools.map((school) => ({
          key: school._id ? school._id : "",
          value: school._id ? school._id : "",
          label:
            school.name +
            " - " +
            school.schoolKey +
            " - CP " +
            school.zipCode +
            " - (" +
            school.country +
            "-" +
            school.region +
            "-" +
            school.subregion +
            "-" +
            school.locality +
            ")",
        }))
      : []
);
export const selectSchoolsRegistered = createSelector(
  selectSchoolsApproved,
  (schools) =>{
    const res:any = {};
    if (schools) {
      schools.forEach(school => {
        const {subregion, schoolKey, name, _id} = school;
        if(!res[subregion]){
          res[subregion] = [];
        }
        res[subregion].push({label: `${name} - Clave(${schoolKey})`, value: _id})
      });
    }
    return res;
  }
);

export default schoolsSlice.reducer;
