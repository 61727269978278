import { FC } from 'react';
import { Stack, Grid } from '@mui/material';

interface AuthLayoutProps {
};

const AuthLayout: FC<AuthLayoutProps> = ({
  children
}) => {
  return (
    <Grid container sx={{height: '100vh'}}>
      <Grid item lg={3} sm={12} sx={{padding: '24px'}}>
      <Stack height='100%' spacing={2}  justifyContent='center'>
        <img src="/images/nutrento-logo.svg" alt="" className="auth-brand-image" />
        { children }
        </Stack>
      </Grid>
      <Grid item lg={9}  sm={0} sx={{padding:'0', margin: '0'}}>
        <img
          className="auth-promo-image"
          alt=""
          src="/images/nutrento-banner.png"
        />
      </Grid>
    </Grid>
  );
}

export default AuthLayout;
export {
  AuthLayout
};