import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  archiveTypes,
} from "../../../utils/catalogs";
import { createLink, updateLink } from "../../../services/links.services";
import { Box, TextField, Button, MenuItem } from "@mui/material";
import { Link } from "../../../utils/models";

interface FormLinkProps {
  actionDrawer?: any;
  linkSelected?: Link | undefined;
  setUpdatedLink?: any;
  setTextModal: any;
  setOpenModal: any;
}

const FormLink: FC<FormLinkProps> = ({
  linkSelected,
  actionDrawer,
  setTextModal,
  setOpenModal,
  setUpdatedLink,
}) => {
  const [loading, setLoading] = useState(false);
  const [archived, setArchived] = useState<any>("");

  type formValues = {
    tag: string;
    url: string;
    description: string;
    relevance: any;
  };

  const form = useForm<formValues>({
    defaultValues: {
      tag: "",
      url: "",
      description: "",
      relevance: 1
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    function initialValues() {
      if (linkSelected) {
        const {
          tag,
          url,
          description,
          archived,
          relevance
        } = linkSelected;
        form.setValue("tag", tag);
        form.setValue("url", url);
        form.setValue("description", description);
        if (relevance) form.setValue("relevance", relevance);
        setArchived(archived);
      }
    }
    initialValues();
  }, [linkSelected, form]);

  function onDropdownChange(fieldName: string, newValue: any) {
    const value = newValue.target.value;
    switch (fieldName) {
      case "archived":
        setArchived(value);
        break;
      default:
        break;
    }
  }

  async function onSubmit(data: formValues): Promise<void> {
    if (data) {
        try {
          let succes;
          let updateData;
          setLoading(true);
          if (linkSelected) {
            updateData = {
              tag: data.tag,
              url: data.url,
              description: data.description,
              relevance: parseInt(data.relevance),
              archived: typeof archived == "boolean" ? archived : archived === "true",
            };
            const id = linkSelected._id;
            succes = await updateLink(id, updateData);
          } else {
            succes = await createLink({
              tag: data.tag,
              url: data.url,
              description: data.description,
              relevance: parseInt(data.relevance),
            });
          }

          if (succes) {
            if (setUpdatedLink) {
              const updateLink = Object.assign(
                { ...linkSelected },
                { ...updateData }
              );
              setUpdatedLink(updateLink);
            }
            if (linkSelected) {
              setTextModal(
                `La liga ${data.tag} se ha actualizado correctamente`
              );
            } else {
              setTextModal(
                `La liga ${data.tag} se ha creado correctamente y ya se puede visualizar dentro de la página nutrento`
              );
            }
          } else {
            setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          }
          if (actionDrawer) {
            actionDrawer(false);
          }
          setLoading(false);
          form.reset();
          setOpenModal(true);
        } catch (err: any) {
          setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          setLoading(false);
          setOpenModal(true);
        }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Etiqueta de la liga"
        {...register("tag", { required: "La etiqueta no puede estar vacia" })}
        error={!!errors.tag}
        helperText={errors.tag?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Url para acceder"
        {...register("url", {
          required: "La url no puede estar vacia",
        })}
        error={!!errors.url}
        helperText={errors.url?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Descripción"
        {...register("description", {
          required: "La descripción no puede estar vacia",
        })}
        error={!!errors.url}
        helperText={errors.url?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Relevancia"
        {...register("relevance", {
          required: "La relevancia no puede estar vacia",
        })}
        type="number"
        error={!!errors.relevance}
        helperText={
          errors.relevance?.message
            ? errors.relevance?.message
            : "Por defecto tiene el número 1, entre mayor sea la relevancia aparecera primero en la página de nutrento"
        }
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      {linkSelected && (
        <TextField
          select
          label="Archivar"
          value={archived}
          onChange={(value) => onDropdownChange("archived", value)}
          sx={{ width: "100%", marginBottom: "12px" }}
        >
          {archiveTypes.map((archiveType) => (
            <MenuItem key={archiveType.value} value={archiveType.value}>
              {archiveType.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained" color="primary">
          {loading
            ? "Procesando..."
            : linkSelected
            ? "Actualizar liga"
            : "Crear liga"}
        </Button>
      </Box>
    </form>
  );
};

export default FormLink;
