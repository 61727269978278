import { useState, useEffect, FC } from "react";
import EvaluationCard from "./evaluation.card";
import { countries } from "../../../utils/catalogs";
import { getResults } from "../../../services/results.service";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
/*import DateRangeInput from "../../../common/components/date-range";
import { DateRange } from "../../../utils";*/
import { useAppSelector } from "../../../store/hooks";
import { selectSchoolsRegistered } from "../../../store/slices/schools";
import { ReloadIcon } from "../../../common/components/reload";
import Select from "react-dropdown-select";
//import moment from "moment";

interface ResultsCardProps {}

const ResultsCard: FC<ResultsCardProps> = () => {
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  /*const [dateRange, setDateRange] = useState<DateRange>({
    start: moment().subtract(0, "month").startOf("month").startOf("day"),
    end: moment().endOf("day"),
  });*/
  const [country, setCountry] = useState<any>([]);
  const [region, setRegion] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [subregion, setSubregion] = useState<any>([]);
  const [subregions, setSubregions] = useState<any>([]);
  const [school, setSchool] = useState<any>([]);
  const [schools, setSchools] = useState<any>([]);
  const schoolsRegistered = useAppSelector((state) =>
    selectSchoolsRegistered(state, "approved")
  );

  function onDropdownChange(fieldName: string, newValue: any) {
    console.log(newValue)
    switch (fieldName) {
      case "country":
        setCountry(newValue);
        setRegion([]);
        setSubregion([]);
        setSchool([]);
        if(newValue.length > 0)
          setRegions(newValue[0].regions);
          else {
            setRegions([]);
            setSubregions([]);
            setSchool([]);
          }
        break;
      case "region":
        setRegion(newValue);
        setSubregion([]);
        setSchool([]);
        if(newValue.length > 0)
          setSubregions(newValue[0].subregions)
        else
          setSubregions([]);
          setSchool([]);
        break;
      case "subregion":
        setSubregion(newValue);
        setSchool([]);
        if (newValue.length > 0 && schoolsRegistered.hasOwnProperty(newValue[0].value)) setSchools(schoolsRegistered[newValue[0].value])
        else setSchools([])
        break;
      case "school":
        setSchool(newValue);
        break;
      default:
        break;
    }
  }

  async function handleSubmit(): Promise<void> {
    try {
      setLoading(true);
      const countryValue =country[0]?.value;
      const regionValue =region[0]?.value;
      const subregionValue =subregion[0]?.value;
      const schoolValue =school[0]?.value;
      let query = {
        groupField: "school.country",
        valueField: "Mexico",
        /*dateRange: {
          field: 'createdAt',
          start: dateRange?.start,
          end:dateRange?.end
        }*/
      };
      if (schoolValue) {
        query.groupField = "school._id";
        query.valueField = schoolValue;
      }else if (subregionValue) {
        query.groupField = "school.subregion";
        query.valueField = subregionValue;
      } else if (regionValue) {
        query.groupField = "school.region";
        query.valueField = regionValue;
      } else if (countryValue) {
        query.groupField = "school.country";
        query.valueField = countryValue;
      }
      const result = await getResults(query);
      if (result !== null) {
        let chart: any = {};
        result.chart.forEach((e: any) => {
          chart[e.name] = e.result;
        });
        setData(result);
        setScore(chart);
      } else {
        setData(null);
        setScore(null);
      }
      setLoading(false);
    } catch (err: any) {
      console.log("Error en datos: ", err?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    async function load() {
      setCountry([countries[1]]);
      const result = await getResults({
        groupField: "school.country",
        valueField: "Mexico",
        /*dateRange: {
          field: 'createdAt',
          start: dateRange?.start,
          end:dateRange?.end
        }*/
      });
      if (result !== null) {
        let chart: any = {};
        result.chart.forEach((e: any) => {
          chart[e.name] = e.result;
        });
        setData(result);
        setScore(chart);
      } else {
        setData(null);
        setScore(null);
      }
      setLoading(false);
    }
    load();
  }, []);

  return (
    <Box sx={{ padding: "12px 0", margin: "0" }}>
      <Box
        sx={{
          display: "flex",
          marginBottom: "18px",
          alignItems: "end",
          padding: "8px 0",
        }}
      >
        <Box className="form-container-select">
          <Typography>Pais</Typography>
          <Select
            className='select-dropdown'
            options={countries}
            values={country}
            valueField='value'
            labelField='label'
            searchBy='label'
            placeholder=''
            onChange={(value) => onDropdownChange('country', value)}
          />
        </Box>
        <Box className="form-container-select">
          <Typography>Región</Typography>
          <Select
            className='select-dropdown'
            options={regions}
            values={region}
            valueField='value'
            labelField='label'
            searchBy='label'
            placeholder=''
            disabled= {!(regions.length > 0)}
            onChange={(value) => onDropdownChange('region', value)}
          />
        </Box>
        <Box className="form-container-select">
          <Typography>SubRegión</Typography>
          <Select
            className='select-dropdown'
            options={subregions}
            values={subregion}
            valueField='value'
            labelField='label'
            searchBy='label'
            placeholder=''
            disabled= {!(subregions.length > 0)}
            onChange={(value) => onDropdownChange('subregion', value)}
          />
        </Box>
        <Box className="form-container-select">
          <Typography>Escuela</Typography>
          <Select
            className='select-dropdown'
            options={schools}
            values={school}
            valueField='value'
            labelField='label'
            searchBy='label'
            placeholder=''
            disabled= {!(schools.length > 0)}
            onChange={(value) => onDropdownChange('school', value)}
          />
        </Box>
        {/*<DateRangeInput onRangeChange={setDateRange} initialValue={dateRange}/>*/}
        <button
            className="reload-button"
            onClick={handleSubmit}
          >
            <ReloadIcon />
          </button>
      </Box>

      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          padding: "40px 16px 24px 16px",
          marginBottom: 16,
          minHeight: 200,
          display: "flex",
          flexDirection: "row",
          position: "relative",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        {loading ? (
          <div className="container-chart">Cargando datos...</div>
        ) : data === null ? (
          <div className="container-chart">No hay información</div>
        ) : (
           <Box sx={{width:'100%'}}>
            <EvaluationCard scores={score} data={data} />
            <Typography align="center" sx={{marginTop: '12px'}}>Respuestas: {data.answers} - Escuelas: {data.schools}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ResultsCard;
export { ResultsCard };
