import { Box, Button, MenuItem } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { FC, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useAppSelector } from "../../store/hooks";
import { useHistory } from "react-router-dom";
import { selectMenuOptionSlice } from "../../store/slices/global";
import { MenuAction } from "../../utils/models";
import { NavIcon } from "./nav-icon";

interface ActionsBarProps {}

const ActionsBar: FC<ActionsBarProps> = () => {
  const menuOption = useAppSelector(selectMenuOptionSlice);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const onClickLink = (item: MenuAction) => {
    handleClose();
    history.push(item.path);
  };

  return (
    <Box sx={{ display: "flex", marginBottom: "8px" }}>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <Button
          id="actions"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
        >
          Acciones
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOption?.actions &&
            menuOption?.actions.map((item) => (
              <MenuItem
                key={item.label}
                onClick={() => onClickLink(item)}
                disableRipple
              >
                <NavIcon name={item.icon} />
                {item.label}
              </MenuItem>
            ))}
        </StyledMenu>
      </Box>
    </Box>
  );
};

export { ActionsBar };
