import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, TextField, Button, Typography } from "@mui/material";
import { createUser } from "../../../services/users.services";
import PasswordChecklist from "react-password-checklist";

interface FormAdminProps {
  setTextModal: any;
  setOpenModal: any;
}

const FormAdmin: FC<FormAdminProps> = ({
  setTextModal,
  setOpenModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("pas$4Administrador");
  type formValues = {
    name: string;
    email?: string;
    level?: any;
  };

  const form = useForm<formValues>({
    defaultValues: {
      name: "",
      email: "",
      level: "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setPassword(e.target.value);
  }

  async function onSubmit(formValues: formValues): Promise<void> {
    if (formValues) {
      try {
        setLoading(true);
        if (formValues.email) {
          const { message, data } = await createUser(
            {
              name: formValues.name,
              email: formValues.email,
              password
            } ,
            true
          );
          if (data) {
            setTextModal(
              `El administrador ${formValues.name} se ha creado correctamente.A continuación te mostramos una plantilla para compartir sus credenciales:<br/><div class="preview-email">Bienvenido a Nutrento<br/><br/>Tus credenciales para acceder a la plataforma son: <br/> Correo: ${formValues.email} <br/>Contraseña temporal: ${password}<br/><br/> Puedes acceder a la plataforma en https//cms.nutrento.org</div> <a href='mailto:${formValues.email}?subject=Bienvenido a Nutrento&body=Tus credenciales para acceder a la plataforma son: %0D%0A %0D%0A Correo: ${formValues.email} %0D%0A %0D%0A Contraseña temporal: ${password} %0D%0A %0D%0A Puedes acceder a la plataforma en https//cms.nutrento.org' target='_blank' rel='noreferrer' style=color:#351051;> Pulsa aqui para usar tu cliente de correo</a><br/> <a aria-label="Pulsa aqui para usar tu cliente de WhatsApp" href="https://wa.me/?text=Bienvenido a Nutrento %0A %0A Tus credenciales para acceder a la plataforma son: Correo: ${formValues.email} %0A Contraseña temporal:${password} %0A %0A Puedes acceder a la plataforma en https//cms.nutrento.org" target='_blank' rel='noreferrer' style=color:#351051;>Compartir con WhatsApp<a/>`
            );
          } else {
            setTextModal(message?.es);
          }
        }
        
        setLoading(false);
        form.reset();
        setPassword("pas$4Administrador");
        setOpenModal(true);
      } catch (err: any) {
        setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
        setLoading(false);
        setOpenModal(true);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Nombre"
        {...register("name", { required: "El nombre no puede estar vacio" })}
        error={!!errors.name}
        helperText={errors.name?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
        <TextField
          label="Correo"
          {...register("email", {
            required: "Correo requerido",
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Introduce un correo valido",
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
          sx={{ width: "100%", marginBottom: "12px" }}
        />
        <TextField
          label="Contraseña"
          value={password}
          onChange={onValueChange}
          sx={{ width: "100%", marginBottom: "12px" }}
          helperText="Por defecto se puede utilizar esta contraseña para un administrador, cuando inicie sesión por primera vez, se le pedirá el cambio de contraseña por la que el administrador elija"
        />
        <Typography sx={{ margin: "0 6px" }}>
          Asegúrate de cumplir con los aspectos de una contraseña segura:
        </Typography>
        <PasswordChecklist
          className="password-check-list"
          style={{ margin: "12px 6px" }}
          iconSize={11}
          rules={[
            "capital",
            "specialChar",
            "minLength",
            "lowercase",
            "number",
          ]}
          minLength={8}
          value={password}
          messages={{
            minLength: "8 caracteres.",
            specialChar: "Al menos un caracter especial.",
            number: "Al menos un número",
            capital: "Al menos una letra mayúscula.",
            lowercase: "Al menos una letra minúscula.",
          }}
        />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained" color="primary">
          {loading
            ? "Procesando..."
            : "Crear administrador"}
        </Button>
      </Box>
    </form>
  );
};

export default FormAdmin;
