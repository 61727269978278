import { useCallback, useState } from 'react';
import { getAllUsers } from '../../services/users.services';
import { Users } from '../../utils/models';

type UseUsersHook = (config: {admin:boolean }) => [
  () => Promise<any>,
  boolean,
  Users,
];

const useUsers: UseUsersHook = ({admin}) => {

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<Users>();

  const loadAllUsers = useCallback(
    async () => {
      try {
        setUsers(undefined);
        setLoading(true);
        const usersList = await getAllUsers(admin);
        if (usersList) {
          setUsers(usersList);
        }
      } catch (e) {
        console.log(e as Error)
      } finally {
        setLoading(false);
      }
    },
    [admin]
  );

  return [
    loadAllUsers,
    loading,
    users,
  ]

}

export default useUsers;