import React, { FC } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridLoadIcon,
  GridLoadingOverlay,
  GridColDef,
  DataGridProps,
  GridToolbarFilterButton,
  GridSortModel,
  GridFilterModel,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';

const ToolbarIconStyles: React.CSSProperties = {
  lineHeight: 1,
  background: 'none',
  color: "primary.main",
  marginRight: '1em',
  border:'none'
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter placeholder='Buscar'/>
    </Box>
  );
}

const BaseTableToolbar = (toolbarProps: any) => {
  return (
    <GridToolbarContainer
      style={{ justifyContent: 'space-between' }}
    >
      {toolbarProps?.tableTitle && <b style={{ paddingLeft: '12px' }}>{toolbarProps?.tableTitle}</b>}
      {toolbarProps?.showQuickFilter && <QuickSearchToolbar/>}
      <div>
        <GridToolbarColumnsButton />
        {toolbarProps?.showFilterButton &&  <GridToolbarFilterButton />}
        <button
          onClick={toolbarProps?.onReload}
          style={ToolbarIconStyles}
        >
          <GridLoadIcon fontSize="small" />
        </button>
        {toolbarProps?.showDownload && <GridToolbarExport csvOptions={toolbarProps?.csvOptions} />}
      </div>
    </GridToolbarContainer>
  );
}

type DataGridExcludedProps = 'sortModel' | 'onSortModelChange' | 'filterModel' | '';

export interface BaseTableProps extends Omit<DataGridProps, DataGridExcludedProps> {
  rows: any;
  columns: GridColDef[];
  rowIdKey?: string;
  onReload?: () => void;
  containerHeight?: number;
  initialSortModel?: GridSortModel;
  initialFilterModel?: GridFilterModel;
  disableColumnSelector?: boolean;
  tableTitle?: string;
  downloadFileName?: string;
}

const BaseTable: FC<BaseTableProps> = ({
  rows = [],
  columns = [],
  rowIdKey = '_id',
  onReload,
  containerHeight = 440,
  initialSortModel = [],
  initialFilterModel,
  disableColumnSelector,
  tableTitle = '',
  downloadFileName = 'Datos',
  ...restProps
}) => {
  const [sortModel, setSortModel] = React.useState<GridSortModel>(initialSortModel);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel | undefined>(initialFilterModel);

  return (
    <div style={{ backgroundColor: 'white', borderRadius: 16 }}>
      <DataGrid
        density="compact"
        getRowId={r => r[rowIdKey]}
        columns={columns}
        rows={rows}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        filterModel={filterModel}
        onFilterModelChange={(filterModel: GridFilterModel) => setFilterModel(filterModel)}
        disableRowSelectionOnClick
        disableDensitySelector
        disableColumnSelector={disableColumnSelector}
        {...restProps}
        localeText={{
          noRowsLabel: 'No se encontraron registros.',
          toolbarExport: '',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          columnsPanelTextFieldLabel:  "Buscar columna",
          columnsPanelTextFieldPlaceholder: "Nombre",
          columnsPanelHideAllButton: "Esconder todo",
          columnsPanelShowAllButton: "Mostrar todo",
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual a",
          filterOperatorStartsWith: "Empieza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Esta vacio",
          filterOperatorIsNotEmpty: "No esta vacio",
          filterOperatorIsAnyOf: "Cualquiera de",
          filterPanelColumns: "Columnas",
          filterPanelOperator: "Operador",
          ...restProps?.localeText,
        }}
        slots={{
          toolbar: BaseTableToolbar,
          loadingOverlay: GridLoadingOverlay,
          ...restProps?.slots
        }}
        slotProps={{
          ...restProps?.slotProps,
          toolbar: {
            onReload: onReload,
            tableTitle,
            ...restProps?.slotProps?.toolbar,
            csvOptions: {
              fileName: downloadFileName,
              ...restProps?.slotProps?.toolbar?.csvOptions,
            },
          },
          filterPanel: {
            filterFormProps:{
              valueInputProps: {
                InputComponentProps: {
                  placeholder:"Valor para filtrar",
                  label: "Valor"
                },
              },
            }
          },
        }}
      />
    </div>
  );
}

export default BaseTable;
export {
  BaseTable
};
