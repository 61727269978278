import { FC } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled, Theme, CSSObject, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiDrawer from "@mui/material/Drawer";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectOpenDrawerSlice,
  setMenuOption,
  setOpenDrawer,
} from "../../store/slices/global";
import { MenuOption } from "../../utils";
import { NavIcon } from "./nav-icon";

interface NavbarProps {}

const openedMixin = (theme: Theme): CSSObject => ({
  width: 240,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar: FC<NavbarProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const open = useAppSelector(selectOpenDrawerSlice);

  const onClickLink = (item: MenuOption) => {
    dispatch(setMenuOption(item));
    history.push(item.path);
  };

  const menu: MenuOption[] = [
    {
      icon: "performance",
      label: "Performance",
      path: "/performance"
    },
    {
      icon: "school",
      label: "Escuelas",
      path: "/schools",
      actions: [
        {
          icon: "add",
          label: "Crear escuela",
          path: "/create-school",
        },
        {
          icon: "upload",
          label: "Cargar escuelas",
          path: "/upload-schools",
        },
      ],
    },
    {
      icon: "user",
      label: "Usuarios",
      path: "/users",
      actions: [
        {
          icon: "add",
          label: "Crear administrador",
          path: "/create-admin",
        },
        {
          icon: "add",
          label: "Invitar docente",
          path: "/create-user",
        },
        {
          icon: "upload",
          label: "Cargar docentes",
          path: "/upload-users",
        },
      ],
    },
    {
      icon: "experiences",
      label: "Experiencias",
      path: "/experiences",
      actions: [
        {
          icon: "add",
          label: "Crear experiencia",
          path: "/create-experience",
        }
      ],
    },
    {
      icon: "links",
      label: "Ligas de interés",
      path: "/links",
      actions: [
        {
          icon: "add",
          label: "Crear Liga de interés",
          path: "/create-link",
        }
      ],
    },
    {
      icon: "results",
      label: "Resultados",
      path: "/results"
    },
    {
      icon: "download",
      label: "Exportar",
      path: "/export",
      actions: [
        {
          icon: "add",
          label: "Crear exportación",
          path: "/new-export",
        },
      ]
    }
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ bgcolor: "primary.main" }}>
          <img src="/images/nut-logo.svg" width={180} alt="nutrento" />
          <IconButton onClick={() => dispatch(setOpenDrawer(false))}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menu.map((item) => (
            <ListItem key={item.label}>
              <Tooltip title={open ? "" : item.label}>
                <ListItemButton
                  onClick={() => onClickLink(item)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <NavIcon name={item.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export { Navbar };
