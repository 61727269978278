import { FC } from "react";
import { Box } from "@mui/material";
import { ActionsBar, Navbar } from "../components";
import { Header } from "../components/header";

interface MainLayoutProps {
  title: string;
  secondary?: boolean;
  showActions?:boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ title, secondary,showActions, children }) => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, height: '100vh'}}>
      <Header title={title} secondary={secondary}/>
      <Navbar />
      <Box sx={{width: '100%', padding: "0 14px", background:"#f9f9f9", overflow:"auto"}}>
        <Box sx={{ marginTop:'80px'}}/>
        {showActions && <ActionsBar/>}
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
export { MainLayout };
