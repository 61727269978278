import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { FC } from "react";

interface DialogModalProps {
  title?: string;
  text: string;
  onAgree: any;
  open: boolean;
  setOpen: any;
  hideCloseButton?: boolean;
}

const DialogModal: FC<DialogModalProps> = ({
  title,
  text,
  onAgree,
  open,
  setOpen,
  hideCloseButton
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      {title && <DialogTitle id={`id_${title}`}>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: text }} />
      </DialogContent>
      <DialogActions>
        {hideCloseButton ? null : <Button onClick={() => setOpen(false)}>Cancelar</Button>}
        <Button onClick={onAgree} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogModal };
