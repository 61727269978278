import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  shedules,
  scopes,
  countries,
  statusTypes,
  archiveTypes,
} from "../../../utils/catalogs";
import { createSchool, updateSchool } from "../../../services/schools.services";
import { Box, TextField, Button, MenuItem } from "@mui/material";
import { School } from "../../../utils/models";

interface FormSchoolProps {
  actionDrawer?: any;
  schoolSelected?: School | undefined;
  setUpdatedSchool?: any;
  setTextModal: any;
  setOpenModal: any;
}

const FormSchool: FC<FormSchoolProps> = ({
  schoolSelected,
  actionDrawer,
  setTextModal,
  setOpenModal,
  setUpdatedSchool,
}) => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [region, setRegion] = useState<any>("");
  const [regions, setRegions] = useState<any>([]);
  const [subregion, setSubregion] = useState<any>("");
  const [subregions, setSubregions] = useState<any>([]);
  const [shedule, setShedule] = useState<any>("");
  const [scope, setScope] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [archived, setArchived] = useState<any>("");

  type formValues = {
    name: string;
    schoolKey: string;
    locality: string;
    zipCode: any;
  };

  const form = useForm<formValues>({
    defaultValues: {
      name: "",
      schoolKey: "",
      locality: "",
      zipCode: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    function initialValues() {
      if (schoolSelected) {
        const {
          name,
          schoolKey,
          locality,
          scope,
          zipCode,
          shedule,
          country,
          region,
          subregion,
          status,
          archived,
        } = schoolSelected;
        form.setValue("name", name);
        form.setValue("schoolKey", schoolKey);
        form.setValue("locality", locality);
        form.setValue("zipCode", zipCode);
        const regionsRes = countries.find(
          (item: any) => item.value === country
        )?.regions;
        setCountry(country);
        setRegions(regionsRes);
        const subregionRes = regionsRes?.find(
          (item: any) => item.value === region
        )?.subregions;
        setRegion(region);
        setSubregions(subregionRes);
        setSubregion(subregion);
        setScope(scope);
        setShedule(shedule);
        if (status) {
          setStatus(status);
        }
        setArchived(archived);
      }
    }
    initialValues();
  }, [schoolSelected, form]);

  function onDropdownChange(fieldName: string, newValue: any) {
    const value = newValue.target.value;
    switch (fieldName) {
      case "country":
        setCountry(value);
        setRegion("");
        setSubregion("");
        const regionsres = countries.find(
          (item: any) => item.value === value
        )?.regions;
        if (regionsres) setRegions(regionsres);
        else setRegions([]);
        setSubregions([]);
        break;
      case "region":
        setRegion(value);
        setSubregion("");
        const subregionsres = regions?.find(
          (item: any) => item.value === value
        )?.subregions;
        if (subregionsres) setSubregions(subregionsres);
        else setSubregions([]);
        break;
      case "subregion":
        setSubregion(value);
        break;
      case "scope":
        setScope(value);
        break;
      case "shedule":
        setShedule(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "archived":
        setArchived(value);
        break;
      default:
        break;
    }
  }

  async function onSubmit(data: formValues): Promise<void> {
    if (data) {
      if (country?.length > 1 && region?.length > 1 && subregion?.length > 1 && scope?.length > 1 && shedule?.length > 1) {
        try {
          let succes;
          let updateData;
          setLoading(true);
          if (schoolSelected) {
            updateData = {
              name: data.name,
              schoolKey: data.schoolKey,
              country,
              region,
              subregion,
              locality: data.locality,
              zipCode: parseInt(data.zipCode),
              shedule,
              scope,
              status,
              archived: typeof archived == "boolean" ? archived : archived === "true",
            };
            const id = schoolSelected._id;
            succes = await updateSchool(id, updateData);
          } else {
            succes = await createSchool({
              name: data.name,
              schoolKey: data.schoolKey,
              country,
              region,
              subregion,
              locality: data.locality,
              zipCode: parseInt(data.zipCode),
              shedule,
              scope,
            });
          }

          if (succes) {
            if (setUpdatedSchool) {
              const updateSchool = Object.assign(
                { ...schoolSelected },
                { ...updateData }
              );
              setUpdatedSchool(updateSchool);
            }
            if (schoolSelected) {
              setTextModal(
                `La escuela ${data.name} se ha actualizado correctamente`
              );
            } else {
              setTextModal(
                `La escuela ${data.name} se ha creado correctamente y ya se puede utlizar dentro de la aplicación nutrento`
              );
            }
          } else {
            setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          }
          if (actionDrawer) {
            actionDrawer(false);
          }
          setLoading(false);
          form.reset();
          setCountry("");
          setRegion("");
          setSubregion("");
          setShedule("");
          setScope("");
          setStatus("");
          setOpenModal(true);
        } catch (err: any) {
          console.log(err?.message)
          if(err?.message.includes('escuela')) setTextModal(err?.message + ', si deseas realizar algun cambio para esta clave puedes utlizar la vista de edición en Escuelas');
          else setTextModal("No se pudo enviar la solicitud, revisa tu conexión");
          setLoading(false);
          setOpenModal(true);
        }
      } else {
        setTextModal("Todos los campos son requeridos para continuar");
        setOpenModal(true);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Nombre de la escuela"
        {...register("name", { required: "El nombre no puede estar vacio" })}
        error={!!errors.name}
        helperText={errors.name?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Clave escolar"
        {...register("schoolKey", {
          required: "La clave no puede estar vacia",
        })}
        error={!!errors.schoolKey}
        helperText={errors.schoolKey?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        select
        label="Pais"
        value={country}
        onChange={(value) => onDropdownChange("country", value)}
        sx={{ width: "100%", marginBottom: "12px" }}
      >
        {countries.map((country) => (
          <MenuItem key={country.value} value={country.value}>
            {country.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Región"
        value={region}
        onChange={(value) => onDropdownChange("region", value)}
        disabled={!(regions.length > 0)}
        sx={{ width: "100%", marginBottom: "12px" }}
      >
        {regions.map((region: any) => (
          <MenuItem key={region.value} value={region.value}>
            {region.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Subregión"
        value={subregion}
        onChange={(value) => onDropdownChange("subregion", value)}
        disabled={!(subregions.length > 0)}
        sx={{ width: "100%", marginBottom: "12px" }}
      >
        {subregions.map((subregion: any) => (
          <MenuItem key={subregion.value} value={subregion.value}>
            {subregion.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Colonia"
        {...register("locality", {
          required: "La colonia no puede estar vacia",
        })}
        error={!!errors.locality}
        helperText={errors.locality?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        label="Codigo Postal"
        type="number"
        {...register("zipCode", {
          required: "El codigo postal no puede estar vacio",
        })}
        error={!!errors.zipCode}
        helperText={errors.zipCode?.message}
        sx={{ width: "100%", marginBottom: "12px" }}
      />
      <TextField
        select
        label="Horario"
        value={shedule}
        onChange={(value) => onDropdownChange("shedule", value)}
        sx={{ width: "100%", marginBottom: "12px" }}
      >
        {shedules.map((shedule) => (
          <MenuItem key={shedule.value} value={shedule.value}>
            {shedule.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Ambito"
        value={scope}
        onChange={(value) => onDropdownChange("scope", value)}
        sx={{ width: "100%", marginBottom: "12px" }}
      >
        {scopes.map((scope) => (
          <MenuItem key={scope.value} value={scope.value}>
            {scope.label}
          </MenuItem>
        ))}
      </TextField>
      {schoolSelected && (
        <TextField
          select
          label="Estado"
          value={status}
          onChange={(value) => onDropdownChange("status", value)}
          sx={{ width: "100%", marginBottom: "12px" }}
        >
          {statusTypes.map((statusType) => (
            <MenuItem key={statusType.value} value={statusType.value}>
              {statusType.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {schoolSelected && (
        <TextField
          select
          label="Archivar"
          value={archived}
          onChange={(value) => onDropdownChange("archived", value)}
          sx={{ width: "100%", marginBottom: "12px" }}
        >
          {archiveTypes.map((archiveType) => (
            <MenuItem key={archiveType.value} value={archiveType.value}>
              {archiveType.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button type="submit" variant="contained" color="primary">
          {loading
            ? "Procesando..."
            : schoolSelected
            ? "Actualizar escuela"
            : "Crear escuela"}
        </Button>
      </Box>
    </form>
  );
};

export default FormSchool;
